import { animate, state, style, transition, trigger } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, isDevMode, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Logger, LoggerType } from './services/logger.service';
import { NavService } from './services/nav.service';
import { UserSettingsComponent } from './shared/components/user-settings/user-settings.component';
import { NavItem } from './shared/models/NavItem';
import packageJson from '../../package.json';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  @ViewChild('appDrawer') appDrawer: ElementRef;
  mobileQuery: MediaQueryList;

  version = packageJson.version;

  navItems: NavItem[] = [
    {
      displayName: 'OEMCC',
      iconName: 'monitor_heart',
      route: 'monitor',
      action: ['preview.view', 'monitor.view', 'history.view'],
      visible: false,
      children: [
        {
          displayName: 'Preview',
          iconName: 'preview',
          route: 'monitor/preview',
          action: 'preview.view',
          visible: false
        },
        {
          displayName: 'Monitor',
          iconName: 'timeline',
          route: 'monitor/monitor',
          action: 'monitor.view',
          visible: false
        },
        {
          displayName: 'Monitor History',
          iconName: 'auto_stories',
          route: 'monitor/monitor-history',
          action: 'history.view',
          visible: false
        }
      ]
    },
    {
      displayName: 'Master Data',
      iconName: 'source',
      route: 'master-data',
      action: ['destination.view', 'rawDestination.view', 'subproductLine.view', 'unit.view'],
      visible: false,
      children: [
        {
          displayName: 'Destinations',
          iconName: 'explore',
          route: 'master-data/destinations',
          action: 'destination.view',
          visible: false
        },
        {
          displayName: 'Raw Destinations',
          iconName: 'map',
          route: 'master-data/raw-destinations',
          action: 'rawDestination.view',
          visible: false
        },
        {
          displayName: 'Subproduct Lines',
          iconName: 'precision_manufacturing',
          route: 'master-data/subproduct-lines',
          action: 'subproductLine.view',
          visible: false
        },
        {
          displayName: 'Units',
          iconName: 'inventory_2',
          route: 'master-data/units',
          action: 'unit.view',
          visible: false
        }
      ]
    },
    {
      displayName: 'Home',
      iconName: 'home',
      route: 'home',
      action: '',
      visible: true,
      hidden: true
    },
    {
      displayName: 'Administration',
      iconName: 'admin_panel_settings',
      route: 'administration',
      action: ['administration.context.view', 'administration.team.view', 'administration.task.view', 'administration.contact_groups.view', 'administration.attachment_category.view'],
      visible: false,
      children: [
        {
          displayName: 'User Context',
          iconName: 'person_pin',
          route: 'administration/context',
          action: 'administration.context.view',
          visible: false
        },
        {
          displayName: 'User Team',
          iconName: 'groups',
          route: 'administration/team',
          action: 'administration.team.view',
          visible: false
        },
        {
          displayName: 'Tasks',
          iconName: 'task_alt',
          route: 'administration/task',
          action: 'administration.task.view',
          visible: false
        },
        {
          displayName: 'Contact Groups',
          iconName: 'groups',
          route: 'administration/contact_groups',
          action: 'administration.contact_groups.view',
          visible: false
        },
        /*{
          displayName: 'Contact Groups Detail',
          iconName: 'group_add',
          route: 'administration/contact_groups_details',
          action: 'administration.contact_group_details.view',
          visible: false
        },*/
        {
          displayName: 'Attachment Category',
          iconName: 'file_present',
          route: 'administration/attachment_category',
          action: 'administration.attachment_category.view',
          visible: false
        },
        {
          displayName: 'Monitor ADM',
          iconName: 'insights',
          route: 'administration/monitor-su',
          action: 'monitor.admin.view',
          visible: false
        },
        {
          displayName: 'Preview ADM',
          iconName: 'insights',
          route: 'administration/preview-su',
          action: 'preview.admin.view',
          visible: false
        }
      ]
    },
    {
      displayName: 'Help',
      iconName: 'help_outline',
      route: 'help',
      action: '',
      visible: true
    }

  ];


  title = 'oemfrontend';

  isIframe = false;
  isLoggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  private _mobileQueryListener: () => void;

  actions: String[] = [];

  isDevMode() {
    return isDevMode();
  }

  constructor(
    private router: Router, public _authService: AuthService, private _logger: Logger, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private navService: NavService, public dialog: MatDialog, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this._authService.loginChanged.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    })
    /*this.matIconRegistry
      .addSvgIconLiteral(
        "filter_reset",
        this.domSanitizer.bypassSecurityTrustHtml(`<svg width="32px" height="32px" viewBox="0 0 24 24" id="icon" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <style>
      .cls-1 {
        fill: none;
      }
    </style>
  </defs>
  <path stroke-width="6" d="M22.5,9A7.4522,7.4522,0,0,0,16,12.792V8H14v8h8V14H17.6167A5.4941,5.4941,0,1,1,22.5,22H22v2h.5a7.5,7.5,0,0,0,0-15Z" />
  <path stroke-width="6" d="M26,6H4V9.171l7.4142,7.4143L12,17.171V26h4V24h2v2a2,2,0,0,1-2,2H12a2,2,0,0,1-2-2V18L2.5858,10.5853A2,2,0,0,1,2,9.171V6A2,2,0,0,1,4,4H26Z" />

</svg>`))*/
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this._authService.completeLogin().then(user => {

      this._authService.getUserAction();
      this._authService._actionObs.subscribe(actions => {
        this.actions = actions;
      })

      this.isLoggedIn = true;
      //this.router.navigate(['/'], { replaceUrl: true });
    }).catch(err => {
      this._logger.log(err, LoggerType.ERROR); this._authService.login().then(() => {
        this._authService.completeLogin();
      })
    })

    //this._authService.checkLoggedIn().then(user => this.router.navigate(['/'], { replaceUrl: true }))

    this._authService._actionObs.subscribe(res => {
      this.setVisibility(this.navItems, res);
      //console.log(this.getFirstVisibility(this.navItems))

      if (!!localStorage.getItem("lastUrl")) {

        if (localStorage.getItem("lastUrl").includes('?')) {
          const newUrl = localStorage.getItem("lastUrl").split('?');
          let trueUrl = this.getLastVisibility(this.navItems, newUrl[0]);
          if (trueUrl == '/') {
            trueUrl = this.getFirstVisibility(this.navItems);
          }
          if (trueUrl == newUrl[0]) {
            this.router.navigate([newUrl[0]], { replaceUrl: false, queryParams: JSON.parse('{"' + newUrl[1].replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) }) });
          } else {
            this.router.navigate([trueUrl], { replaceUrl: false });
          }
        } else {
          this.router.navigate([this.getLastVisibility(this.navItems, localStorage.getItem("lastUrl"))], { replaceUrl: false });
        }


      } else {
        this.router.navigate([this.getFirstVisibility(this.navItems)], { replaceUrl: false });
      }
    })

  }

  userInfo() {
    //this._authService.getGrantor();
    const userSettings = this.dialog.open(UserSettingsComponent);
  }

  login() {
    this._authService.login()
  }

  logout() {
    this._authService.logout()
  }

  check() {

    this._authService.getUser().then(user => console.log(user))
    /*this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken }) => {
    });*/
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  setVisibility(obj: any, res: any) {
    obj.map(el => {
      if (typeof el.action == 'string') {
        if (res.indexOf(el.action) > -1)
          el.visible = true;
      } else {
        el.action.forEach(e => {
          if (res.indexOf(e) > -1)
            el.visible = true;
        })
      }

      if (!!el.children)
        this.setVisibility(el.children, res);
    });
  }

  getFirstVisibility(navItems: any) {
    let firstRoute = '/';
    navItems.map(el => {
      if (el.visible && firstRoute == '/') {
        if (!!el.children) {
          firstRoute = this.getFirstVisibility(el.children);
        }
        else {
          firstRoute = el.route;
        }
      }
    });
    return firstRoute;
  }

  getLastVisibility(navItems: any, lastRoute: string) {
    let firstRoute = '/';
    navItems.map(el => {
      if (el.visible && firstRoute == '/') {
        if (!!el.children) {
          firstRoute = this.getLastVisibility(el.children, lastRoute);
        }
        else {
          if(lastRoute == el.route)
            firstRoute = el.route;
        }
      }
    });
    return firstRoute;
    //return this.getFirstVisibility(navItems);
  }

  onClickLogo() {
    this.router.navigate([this.getFirstVisibility(this.navItems)], { replaceUrl: false });
  }


}
