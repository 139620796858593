import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'stringToDate'
})
export class StringToDatePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): Observable<Date> {

    if (!!value) {
      return of(moment(value, "DD/MM/YYYY").toDate());
    }
    return of(null);
  }

}
