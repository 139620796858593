import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { from, lastValueFrom, Observable, of, tap, throwError } from 'rxjs';
import { AuthService } from './auth.service'
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private _authService: AuthService, private _router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('')) {
      return from(this._authService.getIdToken().then((token) => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`).set('Access-Control-Allow-Origin', '*');
        const reqAuthorized = req.clone({ headers });
        return lastValueFrom(next.handle(reqAuthorized)
          .pipe(catchError((error: HttpErrorResponse) => {
            return this.authErrorHandler(error);
            //return throwError(error);
          }))
        );
      }))
    }
    else {
      return next.handle(req);
    }
  }

  private authErrorHandler(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      // navigate or delete cookies
      // this._router.navigateByUrl(`/login`);
      this._authService.login();
      // you don't want to rethrow the error unless you also want downstream consumers to have to handle it as well.
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }

  /*intercept2(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
        const authReq = req.clone({headers: req.headers.set(Cookie.tokenKey, Cookie.getToken())});
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
        return next.handle(authReq).pipe(catchError(x=> this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
  }*/
}
