import { Pipe, PipeTransform } from '@angular/core';
import { truncate } from 'lodash';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'cellTooltip'
})
export class CellTooltipPipe implements PipeTransform {

  transform(value: any, field: string, name: string, extraToolTip?: string): Observable<string> {

    if (field !== 'actions' && field !== 'destinationCountry' && field !== 'destinationCid' && field !== 'audit') {
      if (!!value[field]) {
        if (value[field].errorType === 'WARNING' && (field == 'portOfLoadingEtd' || field == 'portEta' || field == 'portEtaActual')) {
          for (let error of value[field].errorList) {
            if (!!error && error.includes('before'))
              return of(error + (extraToolTip?extraToolTip:''));
            else
              return of(name + " has been self-calculated" + (extraToolTip?' and '+extraToolTip:''));
          }
          //return of(name + " has been self-calculated");
        }else if (value[field].errorType === 'WARNING' && !(field == 'portOfLoadingEtd' || field == 'portEta' || field == 'portEtaActual')) {
          for (let error of value[field].errorList) {
            if (!!error)
              return of(error+ (extraToolTip?extraToolTip:''));
          }
          //return of(name + " has been self-calculated");

        }
      }
      if (!!value[field]) {
        if (value[field].errorType === 'ERROR' || ((value[field].errorType === 'WARNING' || value[field].errorType === 'INFO') && (field == 'container'))) {
          value[field].errorList = value[field].errorList.map(el => {
            return el.replace(field, name);
          })
          return of(value[field].errorList + (extraToolTip?' and '+extraToolTip:''));
        }
      }
    }
    if (field == 'audit')
      return of('Show audit');

    if (field == 'action')
      return of('Action')

    if (field == 'payload')
      return of(value[field])

    return of((extraToolTip?extraToolTip:null));
  }

}
