import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { MonitorService } from 'src/app/services/monitor.service';

@Component({
  selector: 'app-attachfileto',
  templateUrl: './attachfileto.component.html',
  styleUrls: ['./attachfileto.component.scss']
})
export class AttachfiletoComponent implements OnInit {

  public file: File;
  public toList: Array<{id:number, container: string}>;
  public dialog: { [key: string]: any };
  categories: Array<{value: string, label: string}>;
  selectedOptions: number[] = [];
  categoriesControl = new FormControl();
  selectedOption: string = '';

  form = new FormGroup({
    category: this.categoriesControl
  });

  constructor(public dialogRef: MatDialogRef<AttachfiletoComponent>, private monitorService: MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: { [key: string]: any }) {

      this.file = data['file'];

      this.dialog = data['dialog'];
      this.monitorService.getContainerList(data['list']).subscribe(res => {
        this.toList = res;
      })

      this.monitorService.getAttachmentCategories().subscribe(res => {
        this.categories = res.map(category => {
          return {value:category['categoryId'], label:category['categoryId']};
        })
      })

    }

  ngOnInit(): void {
  }

  saveFileTo(){
    this.dialogRef.close({container: this.toList.filter(el => this.selectedOptions.indexOf(el.id) !== -1), category: this.categoriesControl.value, send: true});
  }

  onNgModelChange(event){
  }

}
