<div class="alert-container" [ngClass]="{'alert-primary': alertInformation.type === alertType.INFO, 'alert-success' : alertInformation.type === alertType.SUCCESS, 'alert-danger' : alertInformation.type === alertType.DANGER, 'alert-warning' : alertInformation.type === alertType.WARNING}" *ngFor="let alertInformation of alertInformations" >
  <div class="alert-content">
    <div class="alert-detail">
      <label class="alert-title">{{alertInformation.title}}</label>
      <label class="alert-description">{{alertInformation.description}}</label>
    </div>
    <span class="spacer"></span>
    <div calss="alert-action">
      <div class="basic-container">
        <mat-progress-spinner
          [diameter]="30"
          [color]="alertInformation.color"
          [mode]="alertInformation.mode"
          [value]="alertInformation.value"
          [class.mat-spinner-color-info]="alertInformation.type === alertType.INFO"
          [class.mat-spinner-color-success]="alertInformation.type === alertType.SUCCESS"
          [class.mat-spinner-color-warning]="alertInformation.type === alertType.WARNING"
          [class.mat-spinner-color-danger]="alertInformation.type === alertType.DANGER"
          >
        </mat-progress-spinner>
        <mat-icon *ngIf="alertInformation.stop"
        (click)="alertInformation.close()"
        class="material-icons"
        [class.mat-icon-color-info]="alertInformation.type === alertType.INFO"
          [class.mat-icon-color-success]="alertInformation.type === alertType.SUCCESS"
          [class.mat-icon-color-warning]="alertInformation.type === alertType.WARNING"
          [class.mat-icon-color-danger]="alertInformation.type === alertType.DANGER">close</mat-icon>
        <mat-icon *ngIf="!alertInformation.stop" (click)="alertInformation.close()"
        class="material-icons"
        [class.mat-icon-color-info]="alertInformation.type === alertType.INFO"
          [class.mat-icon-color-success]="alertInformation.type === alertType.SUCCESS"
          [class.mat-icon-color-warning]="alertInformation.type === alertType.WARNING"
          [class.mat-icon-color-danger]="alertInformation.type === alertType.DANGER">pause</mat-icon>
      </div>

    </div>
  </div>

</div>
