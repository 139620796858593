import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RequestQueryParams } from '../shared/models/RequestQueryParams';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  constructor(private http: HttpClient) { }

  // DESTINATIONS APIS
  getDestinations(params?: HttpParams) {
    return this.http.get(environment.apiUrl + 'oemcc_destinations', { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  if ((content['statusRow']).errorAction === '') {
                    (content['statusRow']).errorAction = remark.action;
                  }

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  getListDestinations() {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_destinations/list', {}).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent) {
          response.map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  if ((content['statusRow']).errorAction === '') {
                    (content['statusRow']).errorAction = remark.action;
                  }

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  editDestination(records: any) {
    Object.entries(records).forEach(entry => {
      if (!!(entry[1]))
        records[entry[0]] = (entry[1])['value'];
      else
        delete records[entry[0]];

    })
    return this.http.put(environment.apiUrl + 'oemcc_destinations', [records]);
  }

  deleteDestination(destinationId: any) {
    return this.http.delete(environment.apiUrl + `oemcc_destinations/${destinationId}`);
  }

  // RAW DESTINATIONS APIS
  getRawDestinations(params?: HttpParams) {
    return this.http.get(environment.apiUrl + 'oemcc_raw_destinations', { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  if ((content['statusRow']).errorAction === '') {
                    (content['statusRow']).errorAction = remark.action;
                  }

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }
            return content;

          })
        }
        return newContent;
      }),
    );
  }

  newRawDestination(records: any) {
    Object.entries(records).forEach(entry => {
      if (!!(entry[1]))
        records[entry[0]] = (entry[1])['value'];
      else
        delete records[entry[0]];

    })
    return this.http.post(environment.apiUrl + 'oemcc_raw_destinations', records, {});
  }

  editRawDestination(records: any) {
    Object.entries(records).forEach(entry => {
      if (!!(entry[1]))
        records[entry[0]] = (entry[1])['value'];
      else
        delete records[entry[0]];

    })
    return this.http.put(environment.apiUrl + 'oemcc_raw_destinations', [records]);
  }

  deleteRawDestination(rawDestinationId: any) {
    return this.http.delete(environment.apiUrl + `oemcc_raw_destinations/${rawDestinationId}`);
  }

  // SUBPRODUCT LINES APIS
  getSubproductLines(params?: HttpParams) {
    return this.http.get(environment.apiUrl + 'oemcc_subproduct_lines', { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  if ((content['statusRow']).errorAction === '') {
                    (content['statusRow']).errorAction = remark.action;
                  }

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  editSubproductLines(records: any) {
    Object.entries(records).forEach(entry => {
      if (!!(entry[1]))
        records[entry[0]] = (entry[1])['value'];
      else
        delete records[entry[0]];

    })
    return this.http.put(environment.apiUrl + 'oemcc_subproduct_lines', [records]);
  }

  deleteSubproductLines(subproductLineId: any) {
    return this.http.delete(environment.apiUrl + `oemcc_subproduct_lines/${subproductLineId}`);
  }

  // UNITS APIS
  getUnits(params?: HttpParams) {
    return this.http.get(environment.apiUrl + 'oemcc_units', { params }).pipe(
      map((response) => {
        let newContent = response;
        if (!!newContent['content']) {
          response['content'].map((content) => {
            if (!!content) {
              content['statusRow'] = {
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if (entry[0] !== 'meta' && entry[0] !== 'statusRow') {
                  content[entry[0]] = {
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if (!!content.meta && !!content.meta.remarks) {
                content.meta.remarks.forEach((remark) => {
                  if (remark.status === 'ERROR') {
                    (content['statusRow']).value = 'ERROR'
                  } else if (remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR') {
                    (content['statusRow']).value = 'WARNING'
                  }

                  if ((content['statusRow']).errorAction === '') {
                    (content['statusRow']).errorAction = remark.action;
                  }

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({ notice: remark.notice, column: remark.column, action: remark.action });
                });
              }
              //Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  editUnits(records: any) {
    Object.entries(records).forEach(entry => {
      if (!!(entry[1]))
        records[entry[0]] = (entry[1])['value'];
      else
        delete records[entry[0]];

    })
    return this.http.put(environment.apiUrl + 'oemcc_units', [records]);
  }

  deleteUnit(unitId: any) {
    return this.http.delete(environment.apiUrl + `oemcc_units/${unitId}`);
  }

  getTerminalList() {
    const params = new HttpParams();
    return this.http.get<any>(environment.apiUrl + 'oemcc_monitor/list/terminal', { params }).pipe(
      map((response) => {
        let newContent = response;
        if(!!newContent){
          response.map((content) => {
            if(!!content){
              content['statusRow']={
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if(entry[0] !== 'meta' && entry[0] !== 'statusRow'){
                  content[entry[0]]={
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if(!!content.meta && !!content.meta.remarks){
                content.meta.remarks.forEach((remark)=>{
                  if(remark.status === 'ERROR'){
                    (content['statusRow']).value = 'ERROR'
                  }else if(remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR'){
                    (content['statusRow']).value = 'WARNING'
                  }

                  if((content['statusRow']).errorAction === ''){
                    (content['statusRow']).errorAction = remark.action;
                  }

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({notice: remark.notice, column: remark.column, action: remark.action});
                });
              }
              Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  getDeclarantList() {
    const params = new HttpParams();
    return this.http.get<any>(environment.apiUrl + 'oemcc_monitor/list/declarant', { params }).pipe(
      map((response) => {
        let newContent = response;
        if(!!newContent){
          response.map((content) => {
            if(!!content){
              content['statusRow']={
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if(entry[0] !== 'meta' && entry[0] !== 'statusRow'){
                  content[entry[0]]={
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if(!!content.meta && !!content.meta.remarks){
                content.meta.remarks.forEach((remark)=>{
                  if(remark.status === 'ERROR'){
                    (content['statusRow']).value = 'ERROR'
                  }else if(remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR'){
                    (content['statusRow']).value = 'WARNING'
                  }

                  if((content['statusRow']).errorAction === ''){
                    (content['statusRow']).errorAction = remark.action;
                  }

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({notice: remark.notice, column: remark.column, action: remark.action});
                });
              }
              Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }

  getContainerContentTypeList() {
    const params = new HttpParams();
    return this.http.get<any>(environment.apiUrl + 'oemcc_monitor/list/container_content_type', { params }).pipe(
      map((response) => {
        let newContent = response;
        if(!!newContent){
          response.map((content) => {
            if(!!content){
              content['statusRow']={
                value: 'READY',
                errorType: '',
                errorList: [],
                errorAction: ''
              }
              Object.entries(content).forEach(entry => {
                if(entry[0] !== 'meta' && entry[0] !== 'statusRow'){
                  content[entry[0]]={
                    value: entry[1],
                    errorType: '',
                    errorList: [],
                    errorAction: ''
                  }
                }
              })
              if(!!content.meta && !!content.meta.remarks){
                content.meta.remarks.forEach((remark)=>{
                  if(remark.status === 'ERROR'){
                    (content['statusRow']).value = 'ERROR'
                  }else if(remark.status === 'WARNING' && (content['statusRow']).value !== 'ERROR'){
                    (content['statusRow']).value = 'WARNING'
                  }

                  if((content['statusRow']).errorAction === ''){
                    (content['statusRow']).errorAction = remark.action;
                  }

                  (content[remark.column]).errorType = remark.status;
                  (content[remark.column]).errorAction = remark.action;
                  (content[remark.column]).errorList.push(remark.notice);
                  (content['statusRow']).errorList.push({notice: remark.notice, column: remark.column, action: remark.action});
                });
              }
              Object.entries(content);
            }

            return content;

          })
        }
        return newContent;
      }),
    );
  }



}
