import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, map } from 'rxjs';

@Pipe({
  name: 'statusTooltip'
})
export class StatusTooltipPipe implements PipeTransform {

  transform(value: unknown[], headers: unknown[]): Observable<string | null> {
    const remarksList = value.map(remark => {
      if(!!remark['column']['label']) {
        const nameColumn = headers.find(column => column['field'] == remark['column'])['label'];
        if (remark['action'] == 'AUTOCALC') {
          return nameColumn + " has been self-calculated";
        } else {
          if (remark['column'] === 'rdcName') {
            return 'Final Destination, CID are not mapped to Master Data'
          } else if(remark['column'] === 'container'){
            if(remark['action'] === 'VALIDATE'){
              return remark['notice']
            }else if(remark['action'] === 'COMPARE'){
              return 'Container '+remark['notice']
            }
          }else {
            if (remark['column'] !== 'destinationCountry' && remark['column'] !== 'destinationCid') {
              if (remark['notice'].includes(remark['column']))
                return remark['notice'].replace(remark['column'], nameColumn);
              else
                return nameColumn + " " + remark['notice'];
            }

          }

        }
      }
    });
    return of(remarksList.join(' \n '));
  }

}
