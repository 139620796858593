import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MonitorService } from 'src/app/services/monitor.service';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-audit-dialog',
  templateUrl: './audit-dialog.component.html',
  styleUrls: ['./audit-dialog.component.scss']
})
export class AuditDialogComponent {

  arr: any[];

  displayedColumns: string[] = ['type', 'fieldName', 'oldValue', 'newValue', 'updateDate', 'userName'];

  noAudit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AuditDialogComponent>, private monitorService: MonitorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    //dialogRef.disableClose = true;
  }

  ngAfterViewInit(): void {
    this.monitorService.getAudit(this.data.info).subscribe((res: any) => {
      if (!!res && !!res.content) {
        this.arr = res.content;
        this.noAudit = false;
      }
      if (!(!!res) || res.content.length == 0)
        this.noAudit = true;
      //this.arr = res.content.map(el => el.info).filter(el => el != null);
    });
  }

  close(id: string) {
    this.dialogRef.close();
    (<HTMLElement>document.getElementById(id)).innerHTML = '';
  }
}
