<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">OEMCC - </label> Monitor History </label>
  <button class="action-button" mat-raised-button color="primary" matTooltip="Export History to CSV file"
    action-type="button" (click)="onExportClick()"
    [disabled]="isChanged || !isActionPresent('history.export.csv')">Export CSV</button>
</app-action-bar>
<div class="alert-container">
  <alert-component #alertMessageMonitor></alert-component>
</div>
<div class="datatable-container ph-16">
  <div class="datatable-content">
    <app-filter [filterObj]="filterObjMonitor" (applyFilterEv)="onApplyFilter($event)">
    </app-filter>

    <div class="datatable-container" #dataTableContainer>
      <app-static-data-table *ngIf="(monitorHeaders | async)?.length > 0 && (showTableObs | async)"
        class="datatable-content" [headers]="monitorHeaders" [length]="monitorLength" [loadingTable]="loading"
        [pageEvent]="pageEvent" [dataSource]="monitorDataSourceObservable"
        [numElementsLoaded]="(monitorDataSourceObservable | async)?.length"
        [dataSourceObservable]="monitorDataSourceObservable" (onPaginationChange)="onPageChange($event)"
        (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumnsMonitor" [showMerge]="false"
        (onReopenRecordsClick)="onReopenRecordsClick($event)" [screen]="screen" #childDataTableMonitor>
      </app-static-data-table>

    </div>

  </div>

  <div *ngIf="!(showTableObs | async) && !isChanged" class="alert2-container alert2-success mt-16">
    <div class="alert2-content">
      <div class="alert2-detail">
        <label class="alert2-description">Please close filter panel, or riduce page zoom to view the table.</label>
      </div>
    </div>
  </div>
</div>

<ngx-spinner [name]="'block'" bdColor="rgba(0, 0, 0, 0.0)" size="large" color="rgba(0, 0, 0, 0.0)" type="timer"
  [fullScreen]="true"></ngx-spinner>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white; font-size: 1.5rem"> Loading... </p>
</ngx-spinner>