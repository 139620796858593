<!--<div class="paginator-container">
  <app-paginator [paginatorLoaderTitle]="'Loading batch: '" [page]="pageEvent.pageIndex" [paginatorLoader]="50"
      [paginatorLoaderList]="[50, 100, 250]" [numElementsLoaded]="numElementsLoaded" [numElementsSize]="length"
      (onPaginatorChange)="onPageChange($event)"></app-paginator>
</div>-->
<div class="fn-table-container" sticky>
  <div class="paginator-container" *ngIf="length > 0">
    <app-paginator [paginatorLoaderTitle]="'Loading batch: '" [page]="pageEvent.pageIndex" [paginatorLoader]="50"
      [paginatorLoaderList]="[50, 100, 250]" [numElementsLoaded]="numElementsLoaded" [numElementsSize]="length"
      (onPaginatorChange)="onPageChange($event)"></app-paginator>
  </div>

  <!--<div class="paginator-container">


    <mat-paginator [length]="length" [pageSize]="pageEvent.pageSize" [disabled]="loadingTable"
      [pageIndex]="pageEvent.pageIndex" [pageSizeOptions]="logspaceFunction()" (page)="onPageChange($event)"
      aria-label="Select page">
    </mat-paginator>
  </div>-->
</div>

<cdk-virtual-scroll-viewport [rowHeight]="48">
  <table mat-table [appVirtualDataSource]="trueDataSource" #table multiTemplateDataRows>

    <ng-container *ngFor="let col of displayedHeaders;" [matColumnDef]="col.field" class="table-column">

      <ng-template [ngIf]="col.field === 'select'">
        <th mat-header-cell *matHeaderCellDef>
          <span>
            <mat-checkbox (change)="masterToggle($event)" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </span>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)"
            [disabled]="isCheckboxDisabled(row)">
          </mat-checkbox>
        </td>
      </ng-template>

      <ng-template [ngIf]="col.field === 'status'">
        <th mat-header-cell *matHeaderCellDef>
          Status
        </th>
        <td mat-cell *matCellDef="let row" class="notEditableCell">
          {{row[col.field]}}
        </td>
      </ng-template>

      <ng-template [ngIf]="col.field !== 'select' && col.field !== 'status'">
        <th mat-header-cell *matHeaderCellDef [ngClass]="!!col.headerClass ? col.headerClass : ''">
          <span>
            {{col.label}}<mat-icon *ngIf="!!sortedColumns.get(col.field) && sortedColumns.get(col.field).type == 'asc'">
              arrow_upward</mat-icon>
            <mat-icon *ngIf="!!sortedColumns.get(col.field) && sortedColumns.get(col.field).type == 'desc'"
              class="sorter-icon">arrow_downward</mat-icon><label class="sort-order"
              *ngIf="!!sortedColumns.get(col.field) && (sortedColumns.get(col.field).type == 'asc' || sortedColumns.get(col.field).type == 'desc')">{{sortedColumns.get(col.field).order}}</label>
          </span>
        </th>

        <td mat-cell *matCellDef="let element" class="relative" (click)="onClickCell(element, col)"
          [class.editableCell]="col.editable && !isAdding()"
          [class.errorRow]="element.errors && element.errors.length > 0"
          [ngClass]="element | cellClass:col.field:(!!col.cellClass ? col.cellClass : ''):col.editable | async"
          [matTooltip]="element | cellTooltip:col.field:col.label | async" matTooltipClass="line-broken-tooltip">

          <!-- [matTooltip]="getCellToolTip(element, col.field)" matTooltipClass="line-broken-tooltip" -->

          <!-- STANDARD VIEW -->
          <div *ngIf="(!col.editable || (col.editable && element.editing !== col.field))"
            [ngClass]="!!col.cellTextClass ? col.cellTextClass : ''">
            <!-- NORMAL CELL -->
            <span
              *ngIf="col.field !== 'actions' && col.field !== 'payload' && col.editType !== 'datePicker' && !element.newRow">
              {{element[col.field]}}
            </span>

            <span
              *ngIf="!element.newRow && col.field !== 'actions' && col.field !== 'payload' && col.editType && col.editType === 'datePicker'">
              {{element[col.field]}}
            </span>

            <span *ngIf="!element.newRow && col.field == 'payload'" class="text-ellipsis">
              {{element[col.field]}}
            </span>

            <!-- ACTIONS CELL (EDITING) -->
            <span *ngIf=" col.field==='actions' && !element.newRow">
              <ng-template *ngFor="let action of col.actions" [ngIf]="(action==='delete')">
                <button mat-icon-button [disabled]="!isActionPresent(screen+'.delete')" color="primary"
                  class="table-button" (click)="onAction(element, action)">
                  <mat-icon>{{action}}</mat-icon>
                </button>
              </ng-template>
            </span>

            <span *ngIf=" col.field==='actions' && !element.newRow">
              <ng-template *ngFor="let action of col.actions" [ngIf]="(action == 'restart_alt')">
                <button mat-icon-button color="primary" class="table-button" (click)="onAction(element, action)">
                  <mat-icon>{{action}}</mat-icon>
                </button>
              </ng-template>
            </span>

            <!-- ACTIONS CELL (NEW ROW) -->
            <div *ngIf="col.field === 'actions' && element.newRow" [ngStyle]="{ display: 'flex' }">
              <button mat-icon-button color="primary" class="table-button" (click)="onAction(element, 'create')">
                <mat-icon>check</mat-icon>
              </button>
              <button mat-icon-button color="primary" class="table-button" (click)="onAction(element, 'cancel')">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>

          <!-- EDITING VIEW -->
          <div *ngIf="col.editable && element.editing === col.field && !element.newRow">
            <!-- STANDARD INPUT -->
            <mat-form-field
              *ngIf="(!col.editType || col.editType === 'input') && col.field !== 'container' && col.field !== 'actions'"
              (click)="$event.stopPropagation()" class="full-width">
              <input [id]="element.id+'-'+col.id" matInput [(ngModel)]="element[col.field]"
                (ngModelChange)="validateCell($event, element, col)" (focusout)="onCellExit(element, col)">
            </mat-form-field>

            <!-- SELECT INPUT TO MONITOR AND PREVIEW -->
            <mat-form-field *ngIf="col.editType && col.editType === 'select'" (click)="$event.stopPropagation()">
              <mat-select [id]="element.id+'-'+col.id" [(ngModel)]="element[col.field]"
                (selectionChange)="onCellExit(element, col)">
                <mat-option *ngFor="let option of col.selectOptions ?? []" [value]="option">{{option}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- DATEPICKER INPUT -->
            <mat-form-field *ngIf="col.editType && col.editType === 'datePicker'" class="picker"
              (mouseenter)="dateInit(element[col.field], element.id+'-'+col.id)" (click)="$event.stopPropagation()">
              <mat-label>Choose a date</mat-label>
              <input matInput type="date" min='2010-01-01' max='9999-12-31' [id]="element.id+'-'+col.id"
                [autofocus]="true" [(ngModel)]="element[col.field]"
                (focus)="dateInit(element[col.field], element.id+'-'+col.id)" (focusout)="onCellExit(element, col)">
            </mat-form-field>

            <!-- TIMEPICKER INPUT -->
            <mat-form-field *ngIf="col.editType && col.editType === 'time'" class="picker"
              (click)="$event.stopPropagation()">
              <mat-label>Choose a date</mat-label>
              <input matInput type="time" [id]="element.id+'-'+col.id" [(ngModel)]="element[col.field]">
            </mat-form-field>
          </div>

          <!-- NEW ROW VIEW -->
          <div *ngIf="element.newRow && col.field !== 'id' && col.field !== 'actions'">
            <!-- STANDARD INPUT -->
            <mat-form-field *ngIf="!col.editType || col.editType === 'input'" (click)="$event.stopPropagation()"
              class="full-width">
              <input [id]="element.id+'-'+col.id" matInput [(ngModel)]="element[col.field]"
                (ngModelChange)="validateCell($event, element, col)" [autofocus]="true" (focus)="onCellFocus($event)"
                (focusout)="onCellExit(element, col)">
            </mat-form-field>

            <!-- SELECT INPUT -->
            <mat-form-field *ngIf="col.editType && col.editType === 'select'" (click)="$event.stopPropagation()">
              <mat-select [id]="element.id+'-'+col.id" [(ngModel)]="element[col.field]">
                <mat-option *ngFor="let option of col.selectOptions ?? []" [value]="option.value">{{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- SELECT MP INPUT -->
            <mat-form-field *ngIf="col.editType && col.editType === 'selectMP'">
              <mat-select [id]="element.id+'-'+col.id" [(ngModel)]="element[col.field]">
                <mat-option *ngFor="let option of col.selectOptions ?? []" [value]="option.value">{{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="hasError(element.id, col.id)" class="message-error">
            {{getErrorMessage(element.id, col.id)}}
          </div>
        </td>
      </ng-template>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" [style.height.px]="48"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</cdk-virtual-scroll-viewport>