import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private baseUrl = '//' + window.location.host + environment.ctserviceEndpoint;

  constructor(private http: HttpClient, private router: Router,) {
    this.baseUrl = '//' + window.location.host + environment.ctserviceEndpoint;
  }


  getNotificationHistory(threadId: number) {
    return this.http.get<Notification[]>(this.baseUrl + 'threads/' + threadId, {});
  }

  getThreads(monitorId: number) {
    const params = new HttpParams().set('appOwner', 'OEMCC');
    return this.http.get<ObjectThread[]>(this.baseUrl + 'objects/' + monitorId, { params });
  }

  getObjects(threadId:number){
    const params = new HttpParams().set('appOwner', 'OEMCC');
    return this.http.get<ObjectThread[]>(this.baseUrl + 'thread_objects/' + threadId, { params });
  }

  getTableHeader() {

  }

}

export interface ObjectThread {
  id: number,
  objectPk: number | null,
  objectAppOwner: string | null,
  notificationThreadId: number | null,
  notificationThreadDesc: string | null,
  notificationAppOwner: string | null,
  notificationTypology: string | null,
  appOwnerNotificationId: string | null,
}

export interface Notification {
  notificationThreadDetailsId: number,
  notificationThreadId: number | null,
  notificationFrom: string | null,
  notificationTo: string | null,
  notificationCc: string | null,
  notificationSubject: string | null,
  notificationBody: SafeHtml | string | null,
  notificationAttachments: string | null,
  insertUser: string | null,
  insertDate: string | null,
  updateUser: string | null,
  updateDate: string | null,
  username: string | null,
  messageId: string | null,
  comunicationId: string | null,
  receivedDateTime: string | null
}


export interface NotificationList {
  threadId: number;
  notifys: Notification[];
}
