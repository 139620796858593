import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImportedComponent } from './pages/imported/imported.component';
import { HelpComponent } from './pages/help/help.component';
import { AdminRouteGuard } from './services/admin-route-guard';
import { MonitorComponent } from './pages/monitor/monitor/monitor.component';
import { PreviewComponent } from './pages/monitor/preview/preview.component';
import { DestinationsComponent } from './pages/master-data/destinations/destinations.component';
import { RawDestinationsComponent } from './pages/master-data/raw-destinations/raw-destinations.component';
import { SubproductLinesComponent } from './pages/master-data/subproduct-lines/subproduct-lines.component';
import { UnitsComponent } from './pages/master-data/units/units.component';
import { MonitorHistoryComponent } from './pages/monitor/monitor-history/monitor-history.component';
import { HomepageComponent } from './shared/components/homepage/homepage.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { ContainerDetailComponent } from './shared/components/container-detail/container-detail.component';
import { ComunicationTrackerComponent } from './shared/components/comunication-tracker/comunication-tracker.component';
import {MonitorSUComponent} from "./pages/monitor/monitor-SU/monitor-su.component";
import {PreviewSUComponent} from "./pages/monitor/preview-SU/preview-su.component";
//import { HelpComponent } from './pages/help/help.component';

const routes: Routes = [
  // {
  //   path: 'imported',
  //   data: {
  //     pagename: 'PAGE_IMPORTED'
  //   },
  //   component: ImportedComponent,
  // },
  {
    path: 'monitor',
    canActivate: [AdminRouteGuard],
    children: [
      {
        path: 'monitor',
        data: {
          pagename: 'PAGE_MONITOR'
        },
        component: MonitorComponent
      },
      {
        path: 'preview',
        data: {
          pagename: 'PAGE_PREVIEW'
        },
        component: PreviewComponent
      },
      {
        path: 'monitor-history',
        data: {
          pagename: 'PAGE_MONITOR_HISTORY'
        },
        component: MonitorHistoryComponent
      },
    ]
  },

  {
    path: 'master-data',
    canActivate: [AdminRouteGuard],
    children: [
      {
        path: 'destinations',
        data: {
          pagename: 'PAGE_MASTER_DATA_DESTINATIONS'
        },
        component: DestinationsComponent
      },
      {
        path: 'raw-destinations',
        data: {
          pagename: 'PAGE_MASTER_DATA_RAW_DESTINATIONS'
        },
        component: RawDestinationsComponent
      },
      {
        path: 'subproduct-lines',
        data: {
          pagename: 'PAGE_MASTER_DATA_SUBPRODUCT_LINE'
        },
        component: SubproductLinesComponent
      },
      {
        path: 'units',
        data: {
          pagename: 'PAGE_MASTER_DATA_UNITS'
        },
        component: UnitsComponent
      },
    ]
  },
  {
    path: 'help',
    data: {
      pagename: 'PAGE_HELP'
    },
    component: HelpComponent, canActivate: [AdminRouteGuard]
  },
  {
    path: 'home',
    data: {
      pagename: 'PAGE_HOME'
    },
    component: HomepageComponent
  },
  {
    path: 'administration',
    canActivate: [AdminRouteGuard],
    children: [
      {
        path: 'context',
        data: {
          pagename: 'CONTEXT_PAGE'
        },
        component: AdministrationComponent
      },
      {
        path: 'team',
        data: {
          pagename: 'TEAM_PAGE'
        },
        component: AdministrationComponent
      },
      {
        path: 'task',
        data: {
          pagename: 'TASK_PAGE'
        },
        component: AdministrationComponent
      },
      {
        path: 'contact_groups',
        data: {
          pagename: 'CONTACT_GROUP_PAGE'
        },
        component: AdministrationComponent
      },
      {
        path: 'contact_groups_details',
        data: {
          pagename: 'CONTACT_GROUP_DETAIL_PAGE'
        },
        component: AdministrationComponent
      },
      {
        path: 'attachment_category',
        data: {
          pagename: 'ATTACHMENT_CATEGORY_PAGE'
        },
        component: AdministrationComponent
      },
      {
        path: 'monitor-su',
        data: {
          pagename: 'PAGE_MONITOR_SU'
        },
        component: MonitorSUComponent
      },
      {
        path: 'preview-su',
        data: {
          pagename: 'PAGE_MONITOR_SU'
        },
        component: PreviewSUComponent
      }
    ]
  },
  {
    path: 'monitor/container',
    data: {
      pagename: 'CONTAINER_DETAIL_PAGE'
    },
    component: ContainerDetailComponent
  },
  {
    path: 'notification/history',
    data: {
      pagename: 'CONTAINER_NOTIFICATION_HISTORY_PAGE'
    },
    component: ComunicationTrackerComponent
  },
  {
    path: '',
    redirectTo: '/monitor/preview',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
