<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">OEMCC - Monitor - </label>Container {{id}}
    {{container}}</label>
  <button mat-raised-button color="primary" matTooltip="Go back to monitor" action-type="button" class="action-button"
    (click)="goBack()">
    Back
  </button>
</app-action-bar>

<div class="alert-container">
  <alert-component #alertMessage></alert-component>
</div>

<mat-expansion-panel *ngIf="isActionPresent('monitor.edit')" [expanded]=" isDetailPresent">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Container Header
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="row">
    <div class="column">
      <div class="row grid-container">
        <div class="grid-item" *ngFor="let header of monitorHeaders | async">
          <div class="grid-subitem" [ngSwitch]="header.editType" *ngIf="!!header && header.editable && !!monitor">

            <mat-form-field *ngSwitchCase="'select'">
              <mat-label>{{!!header && !!header.label ? header.label : ''}}</mat-label>
              <mat-select [id]="monitor.id+'-'+header.id" [(ngModel)]="monitor[header.field]">
                <cdk-virtual-scroll-viewport
                  itemSize="25"
                  [ngStyle]="{height: '200px'}">
                  <mat-option *cdkVirtualFor="let option of (this.selectableColumns.includes(header.field) ? this.destinationsOptions : (header.field.toLowerCase().includes('terminal') ? this.terminalOptions : (header.field.toLowerCase().includes('declarant') ? this.declarantOptions : (header.field.toLowerCase().includes('container') ? this.containerContentTypeOptions : []))))" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </cdk-virtual-scroll-viewport>
              </mat-select>
            </mat-form-field>



            <mat-form-field *ngSwitchCase="'selectMP'">
              <mat-label>{{!!header && !!header.label ? header.label : ''}}</mat-label>
              <mat-select [id]="monitor.id+'-'+header.id" [(value)]="monitor[header.field]">
                <mat-option *ngFor="let option of (this.selectableColumns.includes(header.field) ? this.destinationsOptions : (header.field.toLowerCase().includes('terminal') ? this.terminalOptions : (header.field.toLowerCase().includes('declarant') ? this.declarantOptions : (header.field.toLowerCase().includes('container') ? this.containerContentTypeOptions : [])))); trackBy: identify" [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <mat-form-field *ngSwitchCase="'datePicker'">
              <mat-label>{{!!header && !!header.label ? header.label : ''}}</mat-label>
              <input matInput (dateChange)="setDate($event, header.field)" [matDatepicker]="datepicker"
                [value]="getDate(monitor[header.field])">
              <mat-hint>DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker></mat-datepicker>
            </mat-form-field>


            <mat-form-field *ngSwitchDefault>
              <mat-label>{{!!header && !!header.label ? header.label : ''}}</mat-label>
              <input matInput [(ngModel)]="monitor[header.field]" name={{header.field}} />
            </mat-form-field>
          </div>
        </div>
      </div>

    </div>
    <div class="action-column">
      <div class="row">
        <button class="action-button elx-blue" mat-icon-button color="primary" matTooltip="Save header"
          action-type="button" (click)="saveHeader($event, monitor)">
          <mat-icon class="fs-2r">save_as</mat-icon>
        </button>
      </div>
    </div>
  </div>


  <span *ngIf="!(!!tempElement.id.value)"> Unable to load container ID </span>
</mat-expansion-panel>

<mat-expansion-panel *ngIf="isActionPresent('monitor.detail.view')" [expanded]=" isDetailPresent">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Container Detail
    </mat-panel-title>
  </mat-expansion-panel-header>

  <app-detail-table *ngIf="!!tempElement.id.value" [rowElement]="tempElement"
    (onDetailLoaded)="isPresentDetail($event)">
  </app-detail-table>

  <span *ngIf="!(!!tempElement.id.value)"> Unable to load container ID </span>
</mat-expansion-panel>

<mat-accordion class="expansion-headers-align" multi>
  <mat-expansion-panel *ngIf="isActionPresent('monitor.detail.attachment.view')" [expanded]=" !noFiles">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Attachments
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field appearance="fill" class="category-select" [style.fontSize.px]=12>
      <mat-label>Category</mat-label>
      <mat-select [(value)]="selectedCategory">
        <mat-option *ngFor="let category of categories" [value]="category">
          {{category}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button *ngIf="isActionPresent('monitor.detail.attachment.import')"
      [disabled]="!(!!selectedCategory)" class="import-btn" (click)="onImportFile()">Import
    </button>

    <table *ngIf="!noFiles" mat-table [dataSource]="attachmentDs" class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.attachmentFileName}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type</th>
        <td mat-cell *matCellDef="let element"> {{element.attachmentType}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category</th>
        <td mat-cell *matCellDef="let element"> {{element.attachmentCategory}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef> Size</th>
        <td mat-cell *matCellDef="let element"> {{formatBytes(element.attachmentSize)}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions</th>
        <td mat-cell *matCellDef="let element">
          <div class="action-btn">
            <button *ngIf="isActionPresent('monitor.detail.attachment.download')" mat-icon-button color="primary"
              (click)="download(element.attachmentId, element.attachmentFileName)">
              <mat-icon>download</mat-icon>
            </button>
            <button *ngIf="isActionPresent('monitor.detail.attachment.delete')" mat-icon-button color="primary"
              (click)="delete(element.attachmentId)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="attachmentCols"></tr>
      <tr mat-row *matRowDef="let row; columns: attachmentCols;"></tr>
    </table>

    <span *ngIf="noFiles"> No attachments available. </span>
  </mat-expansion-panel>

  <administration-panel *ngIf="isActionPresent('monitor.inv.view')" [title]="'Involved Party'"
    [headers]="invPartyHeader" [screen]="'monitor.inv'" [filter]="filterInvParty" [endpoint]="'oemcc_involved_party'"
    [newRecord]="newInvParty" [expanded]="false" [monitorId]="id">
  </administration-panel>


  <mat-expansion-panel *ngIf="isActionPresent('monitor.view')" [expanded]="events.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Events
      </mat-panel-title>
    </mat-expansion-panel-header>

    <table *ngIf="!(events.length == 0)" mat-table [dataSource]="events" class="mat-elevation-z8">

      <ng-container matColumnDef="eventName">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.eventName}} </td>
      </ng-container>

      <ng-container matColumnDef="eventDate">
        <th mat-header-cell *matHeaderCellDef> Date</th>
        <td mat-cell *matCellDef="let element"> {{element.eventDate}} </td>
      </ng-container>

      <ng-container matColumnDef="insertDate">
        <th mat-header-cell *matHeaderCellDef> Insert Date</th>
        <td mat-cell *matCellDef="let element"> {{element.insertDate}} </td>
      </ng-container>

      <ng-container matColumnDef="eventRemarks">
        <th mat-header-cell *matHeaderCellDef> Remarks</th>
        <td mat-cell *matCellDef="let element"> {{element.eventRemarks}} </td>
      </ng-container>

      <ng-container matColumnDef="legSeq">
        <th mat-header-cell *matHeaderCellDef> Leg Seq</th>
        <td mat-cell *matCellDef="let element"> {{element.legSeq}} </td>
      </ng-container>

      <ng-container matColumnDef="legStop">
        <th mat-header-cell *matHeaderCellDef> Leg Stop</th>
        <td mat-cell *matCellDef="let element"> {{element.legStop}} </td>
      </ng-container>

      <ng-container matColumnDef="stopNum">
        <th mat-header-cell *matHeaderCellDef> Stop Num</th>
        <td mat-cell *matCellDef="let element"> {{element.stopNum}} </td>
      </ng-container>

      <ng-container matColumnDef="locationName">
        <th mat-header-cell *matHeaderCellDef> Location Name</th>
        <td mat-cell *matCellDef="let element"> {{element.locationName}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="eventsCols"></tr>
      <tr mat-row *matRowDef="let row; columns: eventsCols;"></tr>
    </table>

    <span *ngIf="events.length == 0"> No events available. </span>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="isActionPresent('monitor.view')" [expanded]="costs.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Costs
      </mat-panel-title>
    </mat-expansion-panel-header>

    <table *ngIf="!(costs.length == 0)" mat-table [dataSource]="costs" class="mat-elevation-z8">

      <ng-container matColumnDef="costType">
        <th mat-header-cell *matHeaderCellDef> Type</th>
        <td mat-cell *matCellDef="let element"> {{element.costType}} </td>
      </ng-container>

      <ng-container matColumnDef="costName">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.costName}} </td>
      </ng-container>

      <ng-container matColumnDef="costValue">
        <th mat-header-cell *matHeaderCellDef> Value</th>
        <td mat-cell *matCellDef="let element"> {{element.costValue}} </td>
      </ng-container>

      <ng-container matColumnDef="costCode">
        <th mat-header-cell *matHeaderCellDef> Code</th>
        <td mat-cell *matCellDef="let element"> {{element.costCode}} </td>
      </ng-container>

      <ng-container matColumnDef="rootCause">
        <th mat-header-cell *matHeaderCellDef> Cause</th>
        <td mat-cell *matCellDef="let element"> {{element.rootCause}} </td>
      </ng-container>
      <ng-container matColumnDef="reasonCode">
        <th mat-header-cell *matHeaderCellDef> Reason</th>
        <td mat-cell *matCellDef="let element"> {{element.reasonCode}} </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category</th>
        <td mat-cell *matCellDef="let element"> {{element.category}} </td>
      </ng-container>
      <ng-container matColumnDef="insertDate">
        <th mat-header-cell *matHeaderCellDef> Insert Date</th>
        <td mat-cell *matCellDef="let element"> {{element.insertDate}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="costsCols"></tr>
      <tr mat-row *matRowDef="let row; columns: costsCols;"></tr>
    </table>

    <span *ngIf="costs.length == 0"> No costs available. </span>
  </mat-expansion-panel>


</mat-accordion>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="square-jelly-box" [fullScreen]="true"
  [name]="'container_detail'">
  <p style="color: white; font-size: 1.5rem"> Loading... </p>
</ngx-spinner>
