import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  constructor(private http: HttpClient) { }

  getAdminTableDataOfEndpoint(endpoint: string, params?: HttpParams) {
    return this.http.get<any[]>(environment.apiUrl + "administration/" + endpoint, { params });
  }

  adminEdit(endpoint: string, row: any) {
    return this.http.post(environment.apiUrl + "administration/" + endpoint, row, {});
  }

  adminDelete(endpoint: string, id: number) {
    return this.http.delete(environment.apiUrl + "administration/" + endpoint + '/' + id, {});
  }

  restartTask(taskId: number) {
    return this.http.get<any>(environment.apiUrl + 'oemcc_task/' + taskId, {});
  }

  getInvPartyTypologyOptions() {
    return this.http.get<any>(environment.apiUrl + 'oemcc_inv_party_typology', {});
  }

  getLoadingDelay() {
    return this.http.get<any>(environment.apiUrl + 'administration/dloading', {});
  }
}
