import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-new-delegation',
  templateUrl: './new-delegation.component.html',
  styleUrls: ['./new-delegation.component.scss']
})
export class NewDelegationComponent implements OnInit {

  grantors: string[];
  grantees: string[];

  selectedGrantor: string;
  selectedGrantee: string;

  expDate: string;

  constructor(
    public dialogRef: MatDialogRef<NewDelegationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string, private _authService: AuthService
  ) {
    dialogRef.disableClose = true;
    this.grantors = [];
    this.grantees = [];
  }

  ngOnInit(): void {
    this._authService.getUsersToDelegate().subscribe(res => {
      this.grantees = res.map(el => el.userId);
      if(!!this.data && this.data == 'admin')
        this.grantors = res.map(el => el.userId);
    })
  }

  confirmDelegate() {
    if(!!this.selectedGrantee && !!this.expDate) {
      const expirationDate = moment(this.expDate, "DD/MM/YYYY").format('DD/MM/YYYY');
      //console.log(expirationDate)
      const delegation = {
        granteeUserId: this.selectedGrantee,
        expirationDate: expirationDate
      }

      this._authService.newDelegate(delegation).subscribe(res => {
        this.dialogRef.close(true);
      });
    }
  }

  confirmAdminDelegate() {
    if(!!this.selectedGrantor && !!this.selectedGrantee && !!this.expDate) {
      const expirationDate = moment(this.expDate, "DD/MM/YYYY").format('DD/MM/YYYY');

      const delegation = {
        grantorUserId: this.selectedGrantor,
        granteeUserId: this.selectedGrantee,
        expirationDate: expirationDate
      }

      this._authService.newAdminDelegate(delegation).subscribe(res => {
        this.dialogRef.close(true);
      })
    }
  }
}



