<div [ngClass]="{'full-height':!isChanged, 'full-height-import mt-16':isChanged}">
  <app-action-bar>
    <label class="page-title" page-title><label class="page-subtitle">OEMCC - </label> Monitor <span
      *ngIf="isChanged">Import</span></label>
    <button (click)="onSaveClick()" *ngIf="isActionPresent('monitor.import.save') && isActionPresent('monitor.import')"
            [disabled]="!isChanged || importError" action-type="button" class="action-button" color="primary"
            mat-raised-button
            matTooltip="Save imported records">Save All
    </button>
    <button (click)="onCancelClick()" *ngIf="isActionPresent('monitor.import')" [disabled]="!isChanged" action-type="button"
            class="action-button" color="primary" mat-raised-button
            matTooltip="Cancel imported records">Cancel
    </button>
    <!--
            <button *ngIf="isActionPresent('monitor.import')" class="action-button" mat-raised-button color="primary" matTooltip="Import records" action-type="button"
            (click)="onImportClick()">Import</button>
            -->
    <button (click)="onExportCsvClick()" *ngIf="isActionPresent('monitor.export.csv')" [disabled]="isChanged" action-type="button"
            class="action-button" color="primary" mat-raised-button
            matTooltip="Export monitor in CSV file">Export CSV
    </button>

  </app-action-bar>

  <div class="alert-container">
    <alert-component #alertMessageMonitor></alert-component>
  </div>

  <div class="datatable-container ph-16">

    <div class="datatable-content">
      <app-filter (applyFilterEv)="onApplyFilter($event)" (resetFilterEv)="onResetFilter($event)" *ngIf="!isChanged"
                  [filterObj]="filterObjMonitor">
      </app-filter>

      <div #dataTableContainer class="datatable-container">
        <app-data-table #childDataTableMonitor (onCloseRecordsClick)="onCloseRecordsClick($event)" (onDetailChangeEmit)="onDetailChange($event)"
                        (onEditCell)="onEditCell($event)" (onEditStart)="onDestinationEditStart($event)" (onExportClick)="onExportClick($event)"
                        (onImportClick)="onImportClick()"
                        (onNotifyRecordsClick)="onNotifyRecordsClick($event)"
                        (onOpenRecordsClick)="onOpenRecordsClick($event)" (onPaginationChange)="onPageChange($event)"
                        (onSortChange)="onSortChange($event)" *ngIf="(showTableObs | async) || isChanged" [dataSourceObservable]="monitorDataSourceObservable"
                        [dataSource]="monitorDataSourceObservable" [destinationsOptions]="destinationsOptions"
                        [containerContentTypeOptions]="containerContentTypeOptions"
                        [declarantOptions]="declarantOptions"
                        [terminalOptions]="terminalOptions"
                        [editingRowColumn]="editingRowColumn"
                        [headers]="monitorHeaders"
                        [isChanged]="isChanged"
                        [length]="monitorLength" [loadingTable]="loading"
                        [numElementsLoaded]="(monitorDataSourceObservable | async)?.length"
                        [pageEvent]="pageEvent"
                        [screen]="screen"
                        [extraActions]="availableManualActions"
                        (onManualActionClick)="manualAction($event)"
                        [showMerge]="false" [sortedColumns]="sortedColumnsMonitor" class="datatable-content">
        </app-data-table>
      </div>

    </div>

    <div *ngIf="!(showTableObs | async) && !isChanged" class="alert2-container alert2-success mt-16">
      <div class="alert2-content">
        <div class="alert2-detail">
          <label class="alert2-description">Please close filter panel, or reduce page zoom to view the table.</label>
        </div>
      </div>
    </div>

  </div>

  <ngx-spinner [fullScreen]="true" [name]="'block'" bdColor="rgba(0, 0, 0, 0.0)" color="rgba(0, 0, 0, 0.0)" size="large"
               type="timer"></ngx-spinner>

  <ngx-spinner [fullScreen]="true" [name]="'default'" bdColor="rgba(0, 0, 0, 0.8)" color="#fff" size="large"
               type="square-jelly-box">
    <p style="color: white; font-size: 1.5rem"> Loading... </p>
  </ngx-spinner>


  <!--<div class="mt-16 ph-16" *ngIf="isChanged">

          <app-data-table [isFilter]="!isChanged" [headers]="monitorHeadersImport"
            [length]="(monitorImportDataSourceObservable | async)?.length" [loadingTable]="loading" [pageEvent]="pageEvent"
            [dataSource]="monitorImportDataSourceObservable"
            [numElementsLoaded]="(monitorImportDataSourceObservable | async)?.length"
            [dataSourceObservable]="monitorImportDataSourceObservable" (onPaginationChange)="onPageChange($event)"
            (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumnsMonitor" [showMerge]="false"
            (onActionClick)="onActionMonitor($event)" (onCloseRecordsClick)="onCloseRecordsClick($event)" [screen]="'import'"
            #childDataTableMonitorImport>
          </app-data-table>
        </div>-->
</div>
