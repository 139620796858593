<input type="text" matInput [matAutocomplete]="auto" [id]="identifier" [formControl]="optionsCtrl">
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)" [panelWidth]="'16rem'" [displayWith]="displayFn.bind(this)">
  <cdk-virtual-scroll-viewport
    itemSize="25"
    [ngStyle]="{height: '200px'}">
    <mat-option *cdkVirtualFor="let option of filteredOptions | async" [value]="option.value">
      {{option.label}}
    </mat-option>
  </cdk-virtual-scroll-viewport>

</mat-autocomplete>
