
<section class="container">
  <h1 mat-dialog-title>User Settings</h1>
  <div mat-dialog-content>
    <h2>Info</h2>
    <div class="info-container">
      <div *ngIf="!!username">
        <label>Name and Surname:</label>
        <p>{{username}}</p>
      </div>
      <div *ngIf="!!username" >
        <label>Email:</label>
        <p>{{email}}</p>
      </div>
      <div *ngIf="!!role" >
        <label>User Role:</label>
        <p>{{role}}</p>
      </div>
    </div>
    <!--<div mat-dialog-content class="context-container">
      <h2 mat-dialog-title>User Context</h2>
      <p>user context</p>
    </div>-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          User Context
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="contextDataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="contextId">
          <th mat-header-cell *matHeaderCellDef> Context </th>
          <td mat-cell *matCellDef="let element"> {{element.contextId}} </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>

        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef> Grantor </th>
          <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="contextCols"></tr>
        <tr mat-row *matRowDef="let row; columns: contextCols;"></tr>
      </table>
    </mat-expansion-panel>
    <br/>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Visibility Grant
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Grant To
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="delegate-button-container">
          <button mat-stroked-button (click)="newDelegate()" *ngIf="isActionPresent('user.settings.delegate')">Delegate</button>
        </div>

    <table mat-table [dataSource]="grantToDataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="delegateGranteeId">
        <th mat-header-cell *matHeaderCellDef> Grantee </th>
        <td mat-cell *matCellDef="let element"> {{element.delegateGranteeId}} </td>
      </ng-container>

      <ng-container matColumnDef="delegateExpirationDate">
        <th mat-header-cell *matHeaderCellDef> Expiration Date </th>
        <td mat-cell *matCellDef="let element"> {{element.delegateExpirationDate}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" (click)="makeExpire(element.delegateId)">
            <mat-icon>auto_delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="grantToCols"></tr>
      <tr mat-row *matRowDef="let row; columns: grantToCols;"></tr>
    </table>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Grant From
          </mat-panel-title>
        </mat-expansion-panel-header>

        <table mat-table [dataSource]="grantFromDataSource" class="mb-32 mat-elevation-z8">

          <ng-container matColumnDef="delegateGrantorId">
            <th mat-header-cell *matHeaderCellDef> Grantor </th>
            <td mat-cell *matCellDef="let element"> {{element.delegateGrantorId}} </td>
          </ng-container>

          <ng-container matColumnDef="delegateExpirationDate">
            <th mat-header-cell *matHeaderCellDef> Expiration Date </th>
            <td mat-cell *matCellDef="let element"> {{element.delegateExpirationDate}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="grantFromCols"></tr>
          <tr mat-row *matRowDef="let row; columns: grantFromCols;"></tr>
        </table>
      </mat-expansion-panel>

    </mat-expansion-panel>
    <br/>
    <mat-expansion-panel *ngIf="isActionPresent('administration.delegate')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Administrator Visibility Grant
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="delegate-button-container">
        <button mat-stroked-button (click)="newAdminDelegate()">Delegate</button>
      </div>
      <table mat-table [dataSource]="adminGrantDataSource" class="mb-32 mat-elevation-z8">
        <ng-container matColumnDef="delegateGrantorId">
          <th mat-header-cell *matHeaderCellDef> Grantor </th>
          <td mat-cell *matCellDef="let element"> {{element.delegateGrantorId}} </td>
        </ng-container>

        <ng-container matColumnDef="delegateGranteeId">
          <th mat-header-cell *matHeaderCellDef> Grantee </th>
          <td mat-cell *matCellDef="let element"> {{element.delegateGranteeId}} </td>
        </ng-container>

        <ng-container matColumnDef="delegateExpirationDate">
          <th mat-header-cell *matHeaderCellDef> Expiration Date </th>
          <td mat-cell *matCellDef="let element"> {{element.delegateExpirationDate}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="primary" (click)="adminMakeExpire(element.delegateId)">
              <mat-icon>auto_delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="adminGrantCols"></tr>
        <tr mat-row *matRowDef="let row; columns: adminGrantCols;"></tr>
      </table>
    </mat-expansion-panel>
    <br />
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          User Signature
        </mat-panel-title>
      </mat-expansion-panel-header>

      <angular-editor [(ngModel)]="htmlContent" [config]="htmlConfig"></angular-editor>
      <br />
      <div align="end">
        <button mat-stroked-button color="primary" (click)="saveUserSignature()" class="pt-16" [disabled]="htmlContent == oldHtmlContent">
          <span>Save </span> <mat-icon>save</mat-icon>
        </button>
      </div>

    </mat-expansion-panel>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-stroked-button [mat-dialog-close]="false">Close</button>
  </div>
</section>

