import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as saveAs from 'file-saver';
import { BehaviorSubject, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { MasterDataService } from 'src/app/services/master-data.service';
import { MonitorService } from 'src/app/services/monitor.service';
import { AlertType } from 'src/app/shared/components/alert/alert.component';
import { Header } from 'src/app/shared/components/data-table/data-table.component';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { PageEventType } from 'src/app/shared/components/paginator/paginator.component';
import * as _ from 'lodash';
import { AuthService } from 'src/app/services/auth.service';
import { FILTER_OBJ } from 'src/app/shared/components/filter/filter.component';
import { groupBy, replacer, reviver, splitFilter } from 'src/app/shared/utils/generic';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy {

  @ViewChild('alertMessagePreview') alertMessagePreview;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;
  @ViewChild('childDataTablePreview') childDataTablePreview;
  @ViewChild('childDataTableMonitor') childDataTableMonitor;

  displayedColumns: string[] = ['unit', 'label', 'shippingLine', 'vesselName', 'portOfLoading', 'portOfLoadingEtd', 'portOfDischarge', 'portEta', 'portEtaActual', 'container', 'destinationCid', 'destinationCountry', 'finalDestination', 'productType', 'size', 'supplierCode', 'supplierName', 'swb'];
  headersSubject = new BehaviorSubject<Header[]>([]);
  headers = this.headersSubject.asObservable();

  dataSourceSubject = new BehaviorSubject<any[]>([]);
  dataSourceObservable = this.dataSourceSubject.asObservable();

  dataSource = [];
  dataLength = 0;

  isImported: boolean;
  isChanged: boolean;

  loading: boolean = false;
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };

  oldRecord: any = null;

  filterObjPreview: FILTER_OBJ[] = []

  sortedColumnsPreview: Map<string, { order: number, type: 'asc' | 'desc' }>;
  filterValuesPreview: Array<string>;
  filterValuesMonitor: Array<string>;

  destinationsOptions: any[] = [];

  rdcDestinationsOptions: any[] = [];

  terminalOptions: any[] = [];

  declarantOptions: any[] = [];

  containerContentTypeOptions: any[] = [];

  checkboxesStatusFilter: { info: boolean, warning: boolean, error: boolean };

  permittedActions: any = { userEmail: '', actions: [] };

  screen: string = 'preview';

  subscriptionArray: Subscription[] = [];

  editingRowColumn: { column: string; row: any };

  constructor(private monitorService: MonitorService, private masterDataService: MasterDataService, public dialog: MatDialog, public authService: AuthService, private spinner: NgxSpinnerService) {
    this.checkboxesStatusFilter = { info: true, warning: true, error: true };
    this.sortedColumnsPreview = new Map<string, { order: number, type: 'asc' | 'desc' }>([['portEtaActual', { order: 1, type: 'desc' }], ['container', { order: 2, type: 'asc' }], ['swb', { order: 3, type: 'asc' }]]);
    this.filterValuesPreview = new Array<string>();
    this.filterValuesMonitor = new Array<string>();
    this.pageEvent = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
    this.isImported = false;
    this.isChanged = false;
  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => sub.unsubscribe());
  }

  ngOnInit(): void {
    lastValueFrom(this.authService.getHeader("oemcc_preview_view")).then(headers => {
      let previewHeaders = [];
      headers["detailModels"].forEach(header => {
        previewHeaders.push({
          id: header['order'],
          field: header['column'],
          label: header['label'],
          editable: header['editable'] == 'Y' ? true : false,
          editType: header['type'],
          selectOptions: header['options'],
          headerClass: header['headerClass'],
          actions: header['actions']
        });

        if (!!header['isFilterable']) {
          this.filterObjPreview.push(
            {
              name: header['label'],
              columnProp: header['column'],
              filterType: header['filterType'],
              value: !!header['filterSelectOptionValues'] ? header['filterSelectOptionValues'].split(';').map(el => { return { value: el, label: el } }) : header['filterSelectOptionValues'],
              modelValue: ''
            })
        }


      })
      this.headersSubject.next(previewHeaders);
    }).finally(() => {


      this.subscriptionArray.push(
        this.authService.getAction().subscribe(actions => {
          this.permittedActions = actions['actions'];
          this.subscriptionArray.forEach(sub => sub.unsubscribe());
        }, err => {
          //this.router.navigate(['/'],);
        }))
      this.sortedColumnsPreview = new Map<string, { order: number, type: 'asc' | 'desc' }>([['portEtaActual', { order: 1, type: 'desc' }], ['container', { order: 2, type: 'asc' }], ['swb', { order: 3, type: 'asc' }]]);

      this.filterValuesPreview = new Array<string>();
      this.filterValuesMonitor = new Array<string>();
      this.pageEvent = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
      this.isImported = false;
      this.isChanged = false;
      this.getAllDestinationsOptions();
      this.getAllTerminalOptions();
      this.getAllDeclarantOptions();
      this.getAllContainerContentTypeOptions().finally(() => {

        this.loading = false;
        const backupFilter = JSON.parse(sessionStorage.getItem('backup.filter.preview'))

        if (!!backupFilter) {
          this.filterValuesPreview = backupFilter;
          let backupArray = backupFilter.map(item => {
            return item.split("&");
          }).flat().map(fItem => {
            return splitFilter(fItem)
          })
          backupArray = groupBy(backupArray, 'key', 'value');

          this.filterObjPreview.forEach((filter) => {
            if (!!filter.filterType && filter.filterType == 'multipleSelect') {
              if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
                filter.modelValue = filter.value.filter(e => !(backupArray['' + filter.columnProp].some(el => el['value'] == e.value))).map(e => e.value)
              }
            } else {
              if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
                filter.value = (backupArray['' + filter.columnProp])[0].value;
                filter.modelValue = (backupArray['' + filter.columnProp])[0].value;
              }
            }
          })
        }
        const backupSorting = JSON.parse(sessionStorage.getItem('backup.sorting.preview'), reviver);
        if (!!backupSorting) {
          this.sortedColumnsPreview = backupSorting;
        }
        this.getPreview(0, this.pageEvent.loader, this.sortedColumnsPreview, this.filterValuesPreview, this.checkboxesStatusFilter);
      });
    })


  }

  getAllDestinationsOptions() {
    this.loading = true;
    this.destinationsOptions = JSON.parse(localStorage.getItem('dests'))
    this.masterDataService.getListDestinations()
      .subscribe(
        (res: any) => {
          if (!!res) {
            this.destinationsOptions = res.map((destination: any) => ({
              value: destination.id.value,
              label: `${destination.rdcName.value}`
            })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

            localStorage.setItem('dests', JSON.stringify(this.destinationsOptions));
            //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
          }

        },
        (_err: any) => {
          this.loading = false;
        },
        () => this.loading = false)
  }

  getAllTerminalOptions() {
    this.loading = true;
    this.masterDataService.getTerminalList()
      .subscribe(
        (res: any) => {
          if (!!res) {
            this.terminalOptions = res.map((terminal: any) => ({ value: terminal.terminalId.value, label: `${terminal.terminalName.value}` })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
          }

        },
        (_err: any) => {
          this.loading = false;
        },
        () => this.loading = false)
  }

  getAllDeclarantOptions() {
    this.loading = true;
    this.masterDataService.getDeclarantList()
      .subscribe(
        (res: any) => {
          if (!!res) {
            this.declarantOptions = res.map((declarant: any) => ({ value: declarant.declarantId.value, label: `${declarant.declarantName.value}` })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
          }

        },
        (_err: any) => {
          this.loading = false;
        },
        () => this.loading = false)
  }

  getAllContainerContentTypeOptions() {
    this.loading = true;
    return lastValueFrom(this.masterDataService.getContainerContentTypeList()).then(res => {
      if (!!res) {
        this.containerContentTypeOptions = res.map((container_content_type: any) => ({ value: container_content_type.containerContentTypeId.value, label: `${container_content_type.containerContentTypeName.value}` })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
        this.filterObjPreview = this.filterObjPreview.map(filter => {
          if (!!filter['columnProp'] && filter['columnProp'] == 'containerContentType' && filter['filterType'] == 'multipleSelect') {
            filter['value'] = this.containerContentTypeOptions.map(el => { return { value: el.value, label: el.label } });
          }
          return filter;
        })
      }
    })
  }

  onPageChange(pageEvent: any): void {
    this.pageEvent = pageEvent;
    this.getPreview(pageEvent.pageIndex, pageEvent.loader, this.sortedColumnsPreview, this.filterValuesPreview, this.checkboxesStatusFilter);
  }

  onSortChange(pageSorting: { sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }): void {
    this.sortedColumnsPreview = pageSorting.sorting;
    sessionStorage.setItem('backup.sorting.preview', JSON.stringify(pageSorting.sorting, replacer));
    this.refreshPreview(0, this.dataSource.length, pageSorting.sorting, this.filterValuesPreview, this.checkboxesStatusFilter);
  }

  onApplyFilter(filters: Array<string>, tab: number): void {
    this.filterValuesPreview = filters;
    sessionStorage.setItem('backup.filter.preview', JSON.stringify(filters));
    this.refreshPreview(0, this.pageEvent.loader, this.sortedColumnsPreview, filters, this.checkboxesStatusFilter);

  }

  onDestinationEditStart(obj: { column: Header, element: any }) {
    if (obj.column.field === "finalDestination") {
      obj.column.selectOptions = this.destinationsOptions;
    }
    if (obj.column.field === "destinationId") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.destinationsOptions;
    }
    if (obj.column.field === "portOfLoadingId") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.destinationsOptions;
    }
    if (obj.column.field === "portOfDischargeId") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.destinationsOptions;
    }
    if (obj.column.field === "destRailLocationId") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.destinationsOptions;
    }
    if (obj.column.field === "containerContentType") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.containerContentTypeOptions;
    }
    if (obj.column.field === "declarant") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.declarantOptions;
    }
    if (obj.column.field === "terminal") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.terminalOptions;
    }
  }

  onEditCell(data: { record: any, oldRecord: any, cel: string, editType: string, isTabKeyPressed: boolean }): void {
    if (!!data.record && !!data.oldRecord && data.record[data.cel].value == data.oldRecord[data.cel].value) return;
    if (data.record[data.cel].value == '')
      delete data.record[data.cel];


    //const loadingEvent = this.dialog.open(LoadingComponent);

    if (data.cel == 'rdcName') {
      /*const dialogRef = this.dialog.open(DialogComponent, {
        // width: '250px',
        data: {
          title: "Confirm mapping",
          content: `Are you sure you want to link Original Final Destination [ ${data.oldRecord.finalDestination.value} ] and Original Destination CID [ ${(!(!!data.oldRecord.originalDestinationCid.value) || data.oldRecord.originalDestinationCid.value == 'null' || data.oldRecord.originalDestinationCid.value === null ? '' : data.oldRecord.originalDestinationCid.value)} ] to RDC Name [ ${data.record.rdcName.value} ]?`,
          //content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
          cancelButtonLabel: "No",
          cancelButtonColor: "basic",
          confirmButtonLabel: "Yes",
          confirmButtonColor: "warn"
        }
      });*/

      //dialogRef.afterClosed().subscribe((res) => {
      //if (!!res) {
      this.spinner.show();
      let newRecordWithMeta = _.cloneDeep(data.record);
      const { meta, ...newRecord } = newRecordWithMeta;

      let idDestination = -1;

      this.terminalOptions.forEach(elem => {
        if (!!newRecord.terminal && elem.label === newRecord.terminal.value) {
          //newRecord.rdcName.value = elem.value
          newRecord['terminal'] = {
            value: elem.value,
            errorType: '',
            errorList: [],
            errorAction: ''
          }
        }
      })
      this.declarantOptions.forEach(elem => {
        if (!!newRecord.declarant && elem.label === newRecord.declarant.value) {
          //newRecord.rdcName.value = elem.value
          newRecord['declarant'] = {
            value: elem.value,
            errorType: '',
            errorList: [],
            errorAction: ''
          }
        }
      })
      this.containerContentTypeOptions.forEach(elem => {
        if (!!newRecord.containerContentType && elem.label === newRecord.containerContentType.value) {
          //newRecord.rdcName.value = elem.value
          newRecord['containerContentType'] = {
            value: elem.value,
            errorType: '',
            errorList: [],
            errorAction: ''
          }
        }
      })

      delete newRecord.statusRow;
      delete newRecord.rdcName;
      this.monitorService.renderRowPreview(newRecord).subscribe((res) => {
        this.isChanged = true;
        const similarRow = this.dataSource.filter(row => row.destinationCid.value === data.oldRecord.destinationCid.value && row.finalDestination.value === data.oldRecord.finalDestination.value && row.id.value !== res.id.value);
        this.dataSource = this.dataSource.map(row => {
          if (row.id.value === res.id.value) {
            /*this.terminalOptions.forEach(option => {
              if (!!res.terminal && option.value === res.terminal.value) {
                res.terminal.value = option.label
              }
            })
            this.declarantOptions.forEach(option => {
              if (!!res.declarant && option.value === res.declarant.value) {
                res.declarant.value = option.label
              }
            })
            this.containerContentTypeOptions.forEach(option => {
              if (!!res.containerContentType && option.value === res.containerContentType.value) {
                console.log(option.label, res.containerContentType.value)
                res.containerContentType.value = option.label
              }
            })*/
            this.editingRowColumn = {
              row: res,
              column: res.editing
            }
            return res;
          } else {
            return row;
          }
        })
        this.dataSourceSubject.next(this.dataSource);
        //loadingEvent.close();
        this.spinner.hide();
        this.refreshPreview(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumnsPreview, this.filterValuesPreview, this.checkboxesStatusFilter);
      }, err => {
        //loadingEvent.close();
        this.spinner.hide();
      })
      //}
      /*else {
        this.dataSource = this.dataSource.map(row => {
          if (row.id.value === this.oldRecord.id.value) {
            return this.oldRecord;
          }
          return row;
        });
        this.dataSourceSubject.next(this.dataSource);
        this.spinner.hide();
        ////loadingEvent.close();
        this.spinner.hide();
      }*/
      //}
      //);
    } else {

      this.spinner.show();
      const { meta, ...newRecord } = data.record;

      this.containerContentTypeOptions.forEach(elem => {
        if (elem.label === newRecord.containerContentType.value) {
          //newRecord.rdcName.value = elem.value
          newRecord['containerContentType'] = {
            value: elem.value,
            errorType: '',
            errorList: [],
            errorAction: ''
          }
        }
      })

      delete newRecord.statusRow;
      delete newRecord.rdcName;

      this.monitorService.renderRowPreview(newRecord).subscribe((res) => {
        this.isChanged = true;
        this.dataSource = this.dataSource.map(row => {
          if (row.id.value === res.id.value) {
            this.terminalOptions.forEach(option => {
              if (!!res.terminal && option.value === res.terminal.value) {
                res.terminal.value = option.label;
              }
            })
            this.declarantOptions.forEach(option => {
              if (!!res.declarant && option.value === res.declarant.value) {
                res.declarant.value = option.label;
              }
            })
            this.containerContentTypeOptions.forEach(option => {
              if (!!res.containerContentType && option.value === res.containerContentType.value) {
                res.containerContentType.value = option.label;
              }
            })
            this.editingRowColumn = {
              row: res,
              column: res.editing
            }
            return res;
          } else {
            return row;
          }
        })
        this.dataSourceSubject.next(this.dataSource);
        //loadingEvent.close();
        this.spinner.hide();
      }, err => {
        if (err.status == 403)
          this.alertMessagePreview.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10)
        //loadingEvent.close();
        this.spinner.hide();
      })
    }
  }

  getPreview(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>, checkStatus: { info: boolean, warning: boolean, error: boolean }) {

    this.loading = true;
    this.isChanged = false;
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    filters.forEach(filter => {
      if (!filter.includes('&')) {
        if (!(!!params.get('search')))
          params = params.set('search', filter.replace(',', '§'));
        else
          params = params.append('search', filter.replace(',', '§'));
      } else {
        filter.split('&').forEach(value => {
          if (!(!!params.get('search')))
            params = params.set('search', value.replace(',', '§'));
          else
            params = params.append('search', value.replace(',', '§'));
        })
      }

    });

    let observableArraySize = -1;
    if (checkStatus.info && !checkStatus.warning && !checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status:INFO');
    }
    if (!checkStatus.info && checkStatus.warning && !checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status:WARNING');
    }
    if (!checkStatus.info && !checkStatus.warning && checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status:ERROR');
    }
    if (checkStatus.info && checkStatus.warning && !checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status!ERROR');
    }
    if (checkStatus.info && !checkStatus.warning && checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status!WARNING');
    }
    if (!checkStatus.info && checkStatus.warning && checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status!INFO');
    }

    this.spinner.show();

    //const loadingEvent = this.dialog.open(LoadingComponent);
    const previewSubscrive = this.monitorService.getMonitorPreview(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.dataSource = this.hasSubArray(this.dataSource, res.content).map(e => {
              /*this.terminalOptions.forEach(option => {
                if (option.value === e.terminal.value) {
                  e.terminal.value = option.label
                }
              })
              this.declarantOptions.forEach(option => {
                if (option.value === e.declarant.value) {
                  e.declarant.value = option.label
                }
              })*/
              this.containerContentTypeOptions.forEach(option => {
                if (option.value === e.containerContentType.value) {
                  e.containerContentType.value = option.label
                }
              })
              return e;
            })
            this.dataSourceSubject.next(this.dataSource)
          }

          this.dataLength = res.totalElements;
          //loadingEvent.close();
          this.spinner.hide();
          previewSubscrive.unsubscribe();
        },
        (_err: any) => {
          this.loading = false;
          this.alertMessagePreview.viewMessage(AlertType.DANGER, "Preview loading error", "An error occurred during preview loading. Please contact the administrator if the error persists.", 10)
          //loadingEvent.close();
          this.spinner.hide();
          previewSubscrive.unsubscribe();
        },
        () => {
          this.loading = false
          //loadingEvent.close();
          this.spinner.hide();
          previewSubscrive.unsubscribe();
        })
  }

  refreshPreview(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>, checkStatus: { info: boolean, warning: boolean, error: boolean }) {
    this.loading = true;
    this.isChanged = false;
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    filters.forEach(filter => {
      if (!filter.includes('&')) {
        if (!(!!params.get('search')))
          params = params.set('search', filter.replace(',', '§'));
        else
          params = params.append('search', filter.replace(',', '§'));
      } else {
        filter.split('&').forEach(value => {
          if (!(!!params.get('search')))
            params = params.set('search', value.replace(',', '§'));
          else
            params = params.append('search', value.replace(',', '§'));
        })
      }

    });

    let observableArraySize = -1;
    if (checkStatus.info && !checkStatus.warning && !checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status:INFO');
    }
    if (!checkStatus.info && checkStatus.warning && !checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status:WARNING');
    }
    if (!checkStatus.info && !checkStatus.warning && checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status:ERROR');
    }
    if (checkStatus.info && checkStatus.warning && !checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status!ERROR');
    }
    if (checkStatus.info && !checkStatus.warning && checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status!WARNING');
    }
    if (!checkStatus.info && checkStatus.warning && checkStatus.error) {
      observableArraySize = (this.pageEvent.pageIndex + 1) * this.pageEvent.loader;
      params = params.append('search', 'status!INFO');
    }
    this.dataSource = [];
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    const previewSubscrive = this.monitorService.getMonitorPreview(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.dataSource = this.hasSubArray(this.dataSource, res.content).map(e => {
              this.containerContentTypeOptions.forEach(option => {
                /*this.terminalOptions.forEach(option => {
                  if (option.value === e.terminal.value) {
                    e.terminal.value = option.label
                  }
                })
                this.declarantOptions.forEach(option => {
                  if (option.value === e.declarant.value) {
                    e.declarant.value = option.label
                  }
                })*/
                if (option.value === e.containerContentType.value) {
                  e.containerContentType.value = option.label
                }
              })
              return e;
            })
            this.dataSourceSubject.next(this.dataSource)
          }
          this.dataLength = res.totalElements;
          //this.pageEvent.length = res.totalElements;
          //loadingEvent.close();
          this.spinner.hide();
          previewSubscrive.unsubscribe();
        },
        (_err: any) => {
          this.loading = false;
          this.alertMessagePreview.viewMessage(AlertType.DANGER, "Preview loading error", "An error occurred during preview loading. Please contact the administrator if the error persists.", 10)
          //loadingEvent.close();
          this.spinner.hide();
          previewSubscrive.unsubscribe();
        },
        () => {
          this.loading = false
          //loadingEvent.close();
          this.spinner.hide();
          previewSubscrive.unsubscribe();
        })
  }


  hasSubArray(master: any[], sub: any[]) {
    /*if (sub.every((i => v => i = master.indexOf(v, i) + 1)(0))) {
      return master;
    }
    return master.concat(sub);*/
    sub.forEach(elem => {
      const index = master.findIndex(e => e.id.value === elem.id.value);
      if (index !== -1) {
        master[index] = elem;
      } else {
        master.push(elem);
      }
    })
    return master;

  }


  onMergeClick(records: any[], tab: number) {
    switch (tab) {
      case 0:
        if (records.length < 1) {
          this.alertMessagePreview.viewMessage(AlertType.INFO, "No record selected", "To approve records you must first select them.", 10)
        } else {
          const nr = records.filter(record => (!!record && record.status !== 'ERROR'))
          const nre = records.filter(record => (!!record && record.status === 'ERROR'))

          if (nre.length > 0) {
            this.alertMessagePreview.viewMessage(AlertType.WARNING, "Unable to approve all selected records", "Only records with no blank values were approved.Please double check the table.", 20)
          }
          if (nr.length > 0) {
            this.spinner.show();
            let selected = new Array();
            nr.forEach((el) => {
              selected.push(_.cloneDeep(el.value));
            })
            this.monitorService.mergePreviewToMonitor(selected).subscribe(_res => {
              this.spinner.hide();
              this.refreshPreview(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumnsPreview, this.filterValuesPreview, this.checkboxesStatusFilter);
              this.alertMessagePreview.viewMessage(AlertType.SUCCESS, "Some records approve", "" + (!!_res ? selected.length - _res.length : selected.length) + " of " + records.length + " records approved", 10);
            }, err => {
              this.spinner.hide();
              if (err.status == 403)
                this.alertMessagePreview.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
              else
                this.alertMessagePreview.viewMessage(AlertType.DANGER, "Approve error", "An error occurred during approve. Please contact the administrator if the error persists.", 10)
            });
          }

        }
        break;
      case 1:
        //this.getMonitor(pageSorting.pageEvent.pageIndex, pageSorting.pageEvent.pageSize, pageSorting.sorting);
        break;
    }
  }

  onExportClick(tab: number) {

    switch (tab) {
      case 0:
        const selectedRowPreview: SelectionModel<any> = this.childDataTablePreview.selection;
        break;
      case 1:
        this.loading = true;
        const selectedRowMonitor: SelectionModel<any> = this.childDataTableMonitor.selection;
        const fileName = "Excel_" + Date.now() + ".xlsx";
        this.monitorService.exportExcel(selectedRowMonitor.selected).subscribe(blob => {
          this.alertMessagePreview.viewMessage(AlertType.SUCCESS, "Excel exported", "An excel file for the selected records has been exported.", 5)
          saveAs(blob, fileName);
          this.loading = false;
        })
        break;
    }
  }


  onCheckboxClick(checkboxes: { info: boolean, warning: boolean, error: boolean }) {
    this.checkboxesStatusFilter = checkboxes;
    if (!checkboxes.info && !checkboxes.warning && !checkboxes.error) {
      this.dataSource = [];
      this.dataSourceSubject.next(this.dataSource);
    } else {
      if (checkboxes.info && checkboxes.warning && checkboxes.error) {
        this.refreshPreview(0, this.pageEvent.loader, this.sortedColumnsPreview, this.filterValuesPreview, checkboxes);
      } else {
        this.refreshPreview(0, this.pageEvent.loader, this.sortedColumnsPreview, this.filterValuesPreview, checkboxes);
      }

    }
    /*this.dataSourceSubject.next(this.dataSource.filter(record =>
      (checkboxes.info ? (!(!!record.meta) || !(!!(record.meta).remarks) || (!!record.meta && !!(record.meta).remarks && (record.meta).remarks.every(elem => elem.status === 'INFO'))) : false) ||
      (checkboxes.warning ? ((!!record.meta && !!(record.meta).remarks && (record.meta).remarks.some(elem => elem.status === 'WARNING'))) : false) ||
      (checkboxes.error ? ((!!record.meta && !!(record.meta).remarks && (record.meta).remarks.some(elem => elem.status === 'ERROR'))) : false)
    ))*/
  }

  onActionPreview(evt: any) {
    if (!!evt) {
      if (!!evt.action) {
        if (evt.action === 'delete' && !!evt.element) {

          const dialogRef = this.dialog.open(DialogComponent, {
            // width: '250px',
            data: {
              title: "Confirm deleting",
              content: `Are you sure you want to delete the entire row?`,
              //content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
              cancelButtonLabel: "No",
              cancelButtonColor: "basic",
              confirmButtonLabel: "Yes",
              confirmButtonColor: "warn"
            }
          });

          dialogRef.afterClosed().subscribe((res) => {
            if (!!res) {
              this.spinner.show();
              //const loadingEvent = this.dialog.open(LoadingComponent);
              this.monitorService.deleteRecordPreview(evt.element.id.value).subscribe(res => {
                this.dataSource = this.dataSource.filter(record => record.id !== evt.element.id);
                //loadingEvent.close();
                this.spinner.hide();
                this.dataSourceSubject.next(this.dataSource);
                this.alertMessagePreview.viewMessage(AlertType.SUCCESS, "Delete record", "Record successfully deleted", 5)

              }, err => {
                this.alertMessagePreview.viewMessage(AlertType.WARNING, "Delete record", "There were some errors while deleting the record", 10)
                //loadingEvent.close();
                this.spinner.hide();
              })
            }
          });
        }
      }
    }
  }

  isActionPresent(action: string) {
    if (this.permittedActions.indexOf(action) > -1)
      return true;
    else
      return false
  }
}

