import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { AlertType } from 'src/app/shared/components/alert/alert.component';
import { Header } from 'src/app/shared/components/data-table/data-table.component';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { FILTER_OBJ } from 'src/app/shared/components/filter/filter.component';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { PageEventType } from 'src/app/shared/components/paginator/paginator.component';
import { groupBy, replacer, reviver, splitFilter } from 'src/app/shared/utils/generic';

@Component({
  selector: 'app-subproduct-lines',
  templateUrl: './subproduct-lines.component.html',
  styleUrls: ['./subproduct-lines.component.scss']
})
export class SubproductLinesComponent implements OnInit {

  @ViewChild('childDataTable') childDataTable;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  subproductLinesHeaders: Observable<Header[]> = of([
    { id: 0, field: 'id', label: 'ID', editable: false, headerClass: "w-1-20", cellTextClass: "bold" },
    { id: 1, field: 'line', label: 'Line', editable: true },
    { id: 2, field: 'unitId', label: 'Unit ID', headerClass: "w-8-20", editable: true, editType: "select", selectOptions: [] },
    { id: 3, field: 'actions', label: 'Actions', editable: false, headerClass: "w-1-20", actions: ["delete_forever"] },
  ]);


  subproductLinesDataSourceSubject = new BehaviorSubject<any[]>([]);
  subproductLinesDataSourceObservable = this.subproductLinesDataSourceSubject.asObservable();
  subproductLinesDataSource: any[] = [];
  subproductLinesLength = 0;

  unitsOptionsSubject = new BehaviorSubject<any[]>([]);
  unitsOptionsObservable = this.unitsOptionsSubject.asObservable();
  unitsOptions: any[] = [];

  loading: boolean = false;
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
  sortedColumns: Map<string, { order: number, type: 'asc' | 'desc' }>;
  filterValues: Array<string>;
  filterObjs: FILTER_OBJ[] = [
    {
      name: 'Line',
      columnProp: 'line',
      filterType: 'string',
      value: '',
      modelValue: ''
    }
  ]

  subscriptionArray: Subscription[] = [];

  actions: string[] = [];

  screen: string = 'subproductLine'

  constructor(
    private masterDataService: MasterDataService,
    public dialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([['line', { order: 1, type: 'asc' }]]);
    this.filterValues = new Array<string>();
  }

  ngOnInit(): void {

    this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([['line', { order: 1, type: 'asc' }]]);
    this.filterValues = new Array<string>();
    this.getAllUnitsOptions().finally(() => {
      const backupFilter = JSON.parse(sessionStorage.getItem('backup.filter.subproductline'))

      if (!!backupFilter) {
        this.filterValues = backupFilter;
        let backupArray = backupFilter.map(item => {
          return item.split("&");
        }).flat().map(fItem => {
          return splitFilter(fItem)
        })
        backupArray = groupBy(backupArray, 'key', 'value');

        this.filterObjs.forEach((filter) => {
          if (!!filter.filterType && filter.filterType == 'multipleSelect') {
            if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
              filter.modelValue = filter.value.filter(e => !(backupArray['' + filter.columnProp].some(el => el['value'] == e.value))).map(e => e.value)
            }
          } else {
            if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
              filter.value = (backupArray['' + filter.columnProp])[0].value;
              filter.modelValue = (backupArray['' + filter.columnProp])[0].value;
            }
          }
        })
      }
      const backupSorting = JSON.parse(sessionStorage.getItem('backup.sorting.subproductline'), reviver);
      if (!!backupSorting) {
        this.sortedColumns = backupSorting;
      }
      this.getSubproductLines(0, this.pageEvent.loader, this.sortedColumns, this.filterValues);
    })

    this.subscriptionArray.push(
      this.authService.getAction().subscribe(actions => {
        this.actions = actions['actions'];
        this.subscriptionArray.forEach(sub => sub.unsubscribe());
      }));
  }

  onPageChange(pageEvent: PageEventType, tab: number): void {
    this.pageEvent = pageEvent;

    this.getSubproductLines(pageEvent.pageIndex, pageEvent.loader, this.sortedColumns, this.filterValues);
  }

  onSortChange(pageSorting: { sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }): void {
    this.sortedColumns = pageSorting.sorting;
    sessionStorage.setItem('backup.sorting.subproductline', JSON.stringify(pageSorting.sorting, replacer));
    this.refreshSubproductLines(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, pageSorting.sorting, this.filterValues);
  }

  onApplyFilter(filters: Array<string>, tab: number): void {
    this.filterValues = filters
    sessionStorage.setItem('backup.filter.subproductline', JSON.stringify(filters));
    this.refreshSubproductLines(0, this.pageEvent.loader, this.sortedColumns, filters);
  }

  getSubproductLines(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    this.loading = true;
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    this.masterDataService.getSubproductLines(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.subproductLinesLength = res.totalElements;
            this.subproductLinesDataSource = this.hasSubArray(this.subproductLinesDataSource, res.content).map(row => {
              this.unitsOptions.forEach(elem => {
                if (elem.value === row.unitId.value) {
                  row.unitId.value = elem.label
                }
              })
              return row;
            })
            this.subproductLinesDataSourceSubject.next(this.subproductLinesDataSource)
          }


        },
        (err: any) => {
          this.loading = false;
        },
        () => this.loading = false)
  }


  refreshSubproductLines(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    this.loading = true;
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    filters.forEach(filter => {
      if (!(!!params.get('search')))
        params = params.set('search', filter);
      else
        params = params.append('search', filter);
    });
    this.masterDataService.getSubproductLines(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.subproductLinesDataSource = [];
            this.subproductLinesLength = res.totalElements;
            this.subproductLinesDataSource = this.hasSubArray(this.subproductLinesDataSource, res.content).map(row => {
              this.unitsOptions.forEach(elem => {
                if (elem.value === row.unitId.value) {
                  row.unitId.value = elem.label
                }
              })
              return row;
            })
            this.subproductLinesDataSourceSubject.next(this.subproductLinesDataSource)
            this.unitsOptionsObservable.subscribe(unitArr => {
              this.subproductLinesDataSource = this.subproductLinesDataSource.map(row => {
                unitArr.forEach(unit => {
                  if (unit.id === row.unitId.value) {
                    row.unitId.value = unit.name
                  }
                })
                return row;
              })
              this.subproductLinesDataSourceSubject.next(this.subproductLinesDataSource)
            })
          }
          //loadingEvent.close();
this.spinner.hide();
        },
        (err: any) => {
          //loadingEvent.close();
this.spinner.hide();
          this.loading = false;
        },
        () => this.loading = false)
  }

  onEditSubproductLines(data: any, reload = false) {
    if (!!data.record && !!data.oldRecord && data.record[data.cel].value === data.oldRecord[data.cel].value) return;

    let element;
    if (!!data.record) {
      delete data.record.editing;
      element = data.record;
    } else
      element = data;
    this.loading = reload;
    this.unitsOptions.forEach(elem => {
      if (elem.label === element.unitId.value) {
        element.unitId.value = elem.value
      }
    })
    this.masterDataService.editSubproductLines(element)
      .subscribe(
        (res: any) => {
          if (reload) {
            this.refreshSubproductLines(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
          }
          this.subproductLinesDataSourceSubject.next(this.subproductLinesDataSource)
        },
        (err: any) => {
          this.refreshSubproductLines(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
          if (err.status == 403)
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
          else
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Edit error", "An error occurred during editing.", 10);
          this.loading = false;
        },
        () => {
          this.loading = false;
        })
  }

  onSubproductLinesEditStart(obj: { column: Header, element: any }) {
    if (obj.column.field === "unitId") {
      obj.column.selectOptions = this.unitsOptions;
    }
  }

  onActionSubproductLine(row: any) {
    switch (row.action) {
      case "delete_forever":
        const dialogRef = this.dialog.open(DialogComponent, {
          // width: '250px',
          data: {
            title: "Confirm deletion",
            content: `This action will delete the subproduct line "${row.element.line.value}". Proceed?`,
            cancelButtonLabel: "Cancel",
            cancelButtonColor: "basic",
            confirmButtonLabel: "Delete",
            confirmButtonColor: "warn"
          }
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (!!res) {
            this.masterDataService.deleteSubproductLines(row.element.id.value)
              .subscribe(
                (res: any) => {
                  this.refreshSubproductLines(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);

                },
                (err: any) => {
                  this.loading = false;
                },
                () => this.loading = false)
          }
        });
        break
      case "cancelNew":
        this.subproductLinesDataSource = this.subproductLinesDataSource.slice(1);
        this.subproductLinesDataSourceSubject.next(this.subproductLinesDataSource);
        break;
      case "create":
        delete row.element.newRow;
        let element = row.element;
        this.unitsOptions.forEach(elem => {
          if (elem.label === element.unitId) {
            element.unitId = elem.value;
          }
        })
        this.onEditSubproductLines(element, true);
        break;
    }
  }

  hasSubArray(master: any[], sub: any[]) {
    /*if (sub.every((i => v => i = master.indexOf(v, i) + 1)(0))) {
      return master;
    }
    return master.concat(sub);*/
    sub.forEach(elem => {
      const index = master.findIndex(e => e.id === elem.id);
      if (index !== -1) {
        master[index] = elem;
      } else {
        master.push(elem);
      }
    })
    return master;
  }

  newSubproductLine() {
    this.subproductLinesDataSource = [{
      id: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      line: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      unitId: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      newRow: true
    }, ...this.subproductLinesDataSource];

    this.subproductLinesDataSourceSubject.next(this.subproductLinesDataSource);

    this.subproductLinesHeaders.subscribe(subproductLinesHeaders => {
      subproductLinesHeaders.some((header) => {
        if (header.field === "unitId") {
          header.selectOptions = this.unitsOptions;
          return true;
        }
        return false;
      })
    })

  }

  getAllUnitsOptions() {
    this.loading = true;
    let params = new HttpParams().set('page', 0).set('size', 10000);
    return lastValueFrom(this.masterDataService.getUnits(params)).then((res) => {
      if (!!res && !!res['content'])
        this.unitsOptions = res['content'].map((unit: any) => ({ value: unit.id.value, label: `${unit.name.value}` }));
      this.unitsOptionsSubject.next(this.unitsOptions);
    })
  }


  newSubproductLineAlreadyExists() {
    return this.subproductLinesDataSource.some((subproductLine) => subproductLine.newRow)
  }

  isActionPresent(action: string) {
    if (this.actions.indexOf(action) > -1)
      return true;
    else
      return false
  }
}
