import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* App components */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImportedComponent } from './pages/imported/imported.component';
import { TopbarComponent } from './shared/components/topbar/topbar.component';
import { DataTableComponent, /*IT_DATE_FORMAT*/ } from './shared/components/data-table/data-table.component';
import { AutoFocusDirective } from './shared/directives/auto-focus.directive';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { ResizableDirective } from './shared/directives/resizable.directive';

/* Material modules */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';


import { CustomMatPaginatorIntl } from './shared/components/customs/custom-paginator.component';
import { FilterComponent } from './shared/components/filter/filter.component';
import { AlertComponent } from './shared/components/alert/alert.component';
import { Logger } from './services/logger.service';
import { ActionBarComponent } from './shared/components/action-bar/action-bar.component';
import { DnDComponent } from './shared/components/dnd/dnd.component';
import { DndDirective } from './shared/components/dnd/dnd.directive';
import { HelpComponent } from './pages/help/help.component';
import { AuthService } from './services/auth.service';
import { AuthInterceptorService } from './services/auth.interceptor.service';
import { AdminRouteGuard } from './services/admin-route-guard';
import { MenuListItemComponent } from './shared/components/menu-list-item/menu-list-item.component';
import { NavService } from './services/nav.service';
import { PreviewComponent } from './pages/monitor/preview/preview.component';
import { MonitorComponent } from './pages/monitor/monitor/monitor.component';
import { DestinationsComponent } from './pages/master-data/destinations/destinations.component';
import { RawDestinationsComponent } from './pages/master-data/raw-destinations/raw-destinations.component';
import { SubproductLinesComponent } from './pages/master-data/subproduct-lines/subproduct-lines.component';
import { UnitsComponent } from './pages/master-data/units/units.component';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaginatorComponent } from './shared/components/paginator/paginator.component';
import { FinalDestinationComponent } from './shared/components/final-destination/final-destination.component';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { StaticDataTableComponent } from './shared/components/static-data-table/static-data-table.component';
import { MonitorHistoryComponent } from './pages/monitor/monitor-history/monitor-history.component';
import { CellClassPipe } from './shared/pipe/cell-class.pipe';
import { CellTooltipPipe } from './shared/pipe/cell-tooltip.pipe';
import { StatusTooltipPipe } from './shared/pipe/status-tooltip.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TranslateYPipe } from './shared/pipe/translate-y.pipe';
import { FixedRowSizeTableVirtualScrollStrategyDirective } from './shared/directives/fixed-row-size-table-virtual-scroll-strategy.directive';
import { VirtualTableDirective } from './shared/directives/virtual-table.directive';
import { TableVirtualScrollViewportComponent } from './shared/components/table-virtual-scroll-viewport/table-virtual-scroll-viewport.component';
import { CdkDetailRowDirective } from './shared/directives/cdk-detail-row.directive';
import { DetailTableComponent } from './shared/components/detail-table/detail-table.component';
import { NbDatepickerModule, NbThemeModule, NbLayoutModule, NbIconComponent } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { StringToDatePipe } from './shared/pipe/string-to-date.pipe';
import { AuditDialogComponent } from './shared/components/audit-dialog/audit-dialog.component';
import { GetElementFromDatasourcePipe } from './shared/pipe/get-element-from-datasource.pipe';
import { UserSettingsComponent } from './shared/components/user-settings/user-settings.component';
import { NewDelegationComponent } from './shared/components/new-delegation/new-delegation.component';
import { HomepageComponent } from './shared/components/homepage/homepage.component';
import { StaticLoadingComponent } from './shared/components/static-loading/static-loading.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { AdministrationPanelComponent } from './shared/components/administration-panel/administration-panel.component';
import { AdminDataTableComponent } from './shared/components/monitor-data-table/admin-data-table.component';
import { ContainerDetailComponent } from './shared/components/container-detail/container-detail.component';
import { FileTypeTransfomPipe } from './shared/pipe/file-type-transfom.pipe';
import { ContactGroupDialogComponent } from './shared/components/contact-group-dialog/contact-group-dialog.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ComunicationTrackerComponent } from './shared/components/comunication-tracker/comunication-tracker.component';
import { AutocompleteComponent } from './shared/components/autocomplete/autocomplete.component';
import { NotifyHttpInterceptor } from './services/interceptors/NotifyHttpInterceptor';
import { AttachfiletoComponent } from './shared/components/attachfileto/attachfileto.component';
import { ReplyMailComponent } from './shared/components/reply-mail/reply-mail.component';
import { NgxSpinnerModule } from "ngx-spinner";
import {MonitorSUComponent} from "./pages/monitor/monitor-SU/monitor-su.component";
import {StringToDateNoObsPipe} from "./shared/pipe/string-to-date-no-obs.pipe";
import { ContainStatusPipe } from './shared/pipe/contain-status.pipe';
import {PreviewSUComponent} from "./pages/monitor/preview-SU/preview-su.component";
import {ValidateRegexPipe} from "./shared/pipe/validate-regex.pipe";
import {GetElementFromArrayPipe} from "./shared/pipe/get-value-from-array.pipe";
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
  declarations: [
    AppComponent,
    MonitorComponent,
    ImportedComponent,
    TopbarComponent,
    DataTableComponent,
    AutoFocusDirective,
    DialogComponent,
    FilterComponent,
    AlertComponent,
    ActionBarComponent,
    DnDComponent,
    DndDirective,
    HelpComponent,
    MenuListItemComponent,
    PreviewComponent,
    DestinationsComponent,
    RawDestinationsComponent,
    SubproductLinesComponent,
    UnitsComponent,
    LoadingComponent,
    PaginatorComponent,
    FinalDestinationComponent,
    StaticDataTableComponent,
    MonitorHistoryComponent,
    ResizableDirective,
    CellClassPipe,
    CellTooltipPipe,
    StatusTooltipPipe,
    TranslateYPipe,
    VirtualTableDirective,
    FixedRowSizeTableVirtualScrollStrategyDirective,
    TableVirtualScrollViewportComponent,
    CdkDetailRowDirective,
    DetailTableComponent,
    StringToDatePipe,
    StringToDateNoObsPipe,
    AuditDialogComponent,
    GetElementFromDatasourcePipe,
    UserSettingsComponent,
    NewDelegationComponent,
    HomepageComponent,
    StaticLoadingComponent,
    AdministrationComponent,
    AdministrationPanelComponent,
    AdminDataTableComponent,
    ContainerDetailComponent,
    FileTypeTransfomPipe,
    ContactGroupDialogComponent,
    ComunicationTrackerComponent,
    AutocompleteComponent,
    AttachfiletoComponent,
    ReplyMailComponent,
    MonitorSUComponent,
    PreviewSUComponent,
    ContainStatusPipe,
    ValidateRegexPipe,
    GetElementFromArrayPipe
  ],
    imports: [
        BrowserModule,
        FormsModule, ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatButtonModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatProgressBarModule,
        MatSelectModule,
        MatIconModule,
        MatCheckboxModule,
        MatDialogModule,
        MatMenuModule,
        MatSidenavModule,
        MatDividerModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatRadioModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatListModule,
        MatExpansionModule,
        MatMomentDateModule,
        MatGridListModule,
        ScrollingModule,
        NbDatepickerModule.forRoot(),
        NbDatepickerModule,
        NbThemeModule.forRoot({name: 'default'}),
        NbLayoutModule,
        NbEvaIconsModule,
        NbDateFnsDateModule,
        MatSlideToggleModule,
        AngularEditorModule,
        MatAutocompleteModule,
        NgxSpinnerModule, NgSelectModule
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl
  }, Logger, AuthService,
    AdminRouteGuard, MatDatepickerModule,
  { provide: DateAdapter, useClass: MomentDateAdapter },
  {
    provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'DD/MM/YYYY',
      },
      display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }
  },
  //{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  },
    NavService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
