import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueFromArrayOrDefault',
  pure: false
})
export class GetElementFromArrayPipe implements PipeTransform {

  transform(value: string | null | undefined, values: any[], propertySearch: string, propertyView: string): unknown {
    const localValues = values?.filter(val => val[propertySearch] == value)
    return ( localValues ?? []).length > 0 ? (localValues[0])[propertyView] : value;
  }

}
