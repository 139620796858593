import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { AlertType } from 'src/app/shared/components/alert/alert.component';
import { Header } from 'src/app/shared/components/data-table/data-table.component';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { FILTER_OBJ } from 'src/app/shared/components/filter/filter.component';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { PageEventType } from 'src/app/shared/components/paginator/paginator.component';
import { groupBy, replacer, reviver, splitFilter } from 'src/app/shared/utils/generic';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss']
})
export class DestinationsComponent implements OnInit {

  @ViewChild('childDataTable') childDataTable;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  destinationsHeaders: Observable<Header[]> = of([
    { id: 0, field: 'id', label: 'ID', editable: false, headerClass: "w-1-20", cellTextClass: "bold" },
    { id: 1, field: 'rdcName', label: 'RDC Name', editable: true, headerClass: "w-6-20" },
    { id: 2, field: 'plantId', label: 'Plant ID', editable: true, headerClass: "w-3-20" },
    { id: 3, field: 'country', label: 'Country', editable: true, headerClass: "w-3-20" },
    { id: 4, field: 'countryIso', label: 'Country ISO', editable: true, headerClass: "w-3-20" },
    { id: 5, field: 'cluster', label: 'Cluster', editable: true, headerClass: "w-3-20" },
    { id: 6, field: 'operatorName', label: 'Operator Name', editable: true, headerClass: "w-3-20" },
    { id: 7, field: 'rpDd', label: 'RP DD', editable: true, headerClass: "w-3-20" },
    { id: 8, field: 'actions', label: 'Actions', editable: false, headerClass: "w-1-20", actions: ["delete_forever"] },
  ]);

  destinationsDataSourceSubject = new BehaviorSubject<any[]>([]);
  destinationsDataSourceObservable = this.destinationsDataSourceSubject.asObservable();
  destinationsDataSource: any[] = [];
  destinationsLength = 0;

  destinationsOptions: any[] = [];

  loading: boolean = false;
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };

  sortedColumns: Map<string, { order: number, type: 'asc' | 'desc' }>;
  filterValues: Array<string>;
  filterObjs: FILTER_OBJ[] = [
    {
      name: 'RDC Name',
      columnProp: 'rdcName',
      filterType: 'string',
      value: '',
      modelValue: ''
    }
  ]

  subscriptionArray: Subscription[] = [];

  actions: string[] = [];

  screen: string = 'destination'

  constructor(
    private masterDataService: MasterDataService,
    public dialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([['country', { order: 1, type: 'asc' }], ['rdcName', { order: 2, type: 'asc' }]]);
    this.filterValues = new Array<string>();
  }

  ngOnInit(): void {
    this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([['country', { order: 1, type: 'asc' }], ['rdcName', { order: 2, type: 'asc' }]]);
    this.filterValues = new Array<string>();

    const backupFilter = JSON.parse(sessionStorage.getItem('backup.filter.destinations'))

    if (!!backupFilter) {
      this.filterValues = backupFilter;
      let backupArray = backupFilter.map(item => {
        return item.split("&");
      }).flat().map(fItem => {
        return splitFilter(fItem)
      })
      backupArray = groupBy(backupArray, 'key', 'value');

      this.filterObjs.forEach((filter) => {
        if (!!filter.filterType && filter.filterType == 'multipleSelect') {
          if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
            filter.modelValue = filter.value.filter(e => !(backupArray['' + filter.columnProp].some(el => el['value'] == e.value))).map(e => e.value)
          }
        } else {
          if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
            filter.value = (backupArray['' + filter.columnProp])[0].value;
            filter.modelValue = (backupArray['' + filter.columnProp])[0].value;
          }
        }
      })
    }
    const backupSorting = JSON.parse(sessionStorage.getItem('backup.sorting.destinations'), reviver);
    if (!!backupSorting) {
      this.sortedColumns = backupSorting;
    }

    this.getDestinations(0, 50, this.sortedColumns, this.filterValues);

    this.subscriptionArray.push(
      this.authService.getAction().subscribe(actions => {
        this.actions = actions['actions'];
        this.subscriptionArray.forEach(sub => sub.unsubscribe());
      }));
  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => sub.unsubscribe());
  }

  onPageChange(pageEvent: PageEventType, tab: number): void {
    this.pageEvent = pageEvent;
    this.getDestinations(pageEvent.pageIndex, pageEvent.loader, this.sortedColumns, this.filterValues);
  }

  onSortChange(pageSorting: { sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }): void {
    this.sortedColumns = pageSorting.sorting;
    sessionStorage.setItem('backup.sorting.destinations', JSON.stringify(pageSorting.sorting, replacer));
    this.refreshDestinations(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, pageSorting.sorting, this.filterValues);
  }

  onApplyFilter(filters: Array<string>, tab: number): void {
    this.filterValues = filters;
    sessionStorage.setItem('backup.filter.destinations', JSON.stringify(filters));
    this.refreshDestinations(0, this.pageEvent.loader, this.sortedColumns, filters);
  }

  getDestinations(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    this.loading = true;
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    filters.forEach(filter => {
      if (!(!!params.get('search')))
        params = params.set('search', filter.replace(',', '§'));
      else
        params = params.append('search', filter.replace(',', '§'));
    });
    this.masterDataService.getDestinations(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.destinationsDataSource = this.hasSubArray(this.destinationsDataSource, res.content);
            this.destinationsDataSourceSubject.next(this.destinationsDataSource);
            this.destinationsLength = res.totalElements;
          }


        },
        (err: any) => {
          this.loading = false;
        },
        () => this.loading = false)
  }

  refreshDestinations(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    this.loading = true;
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    filters.forEach(filter => {
      if (!(!!params.get('search')))
        params = params.set('search', filter.replace(',', '§'));
      else
        params = params.append('search', filter.replace(',', '§'));
    });
    this.masterDataService.getDestinations(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.destinationsDataSource = [];
            this.destinationsDataSource = this.hasSubArray(this.destinationsDataSource, res.content);
            this.destinationsDataSourceSubject.next(this.destinationsDataSource);
            this.destinationsLength = res.totalElements;
          }
          //loadingEvent.close();
this.spinner.hide();
        },
        (err: any) => {
          this.loading = false;
        },
        () => this.loading = false)
  }

  hasSubArray(master: any[], sub: any[]) {
    /*if (sub.every((i => v => i = master.indexOf(v, i) + 1)(0))) {
      return master;
    }
    return master.concat(sub);*/
    sub.forEach(elem => {
      const index = master.findIndex(e => e.id === elem.id);
      if (index !== -1) {
        master[index] = elem;
      } else {
        master.push(elem);
      }
    })
    return master;
  }

  getAllDestinationsOptions() {
    this.loading = true;
    let params = new HttpParams().set('page', 0).set('size', 10000);
    this.masterDataService.getDestinations(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.destinationsOptions = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
            //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
          }

        },
        (err: any) => {
          this.loading = false;
        },
        () => this.loading = false)
  }

  onEditDestination(data: any, reload = false) {
    if (!!data.record && !!data.oldRecord && data.record[data.cel].value === data.oldRecord[data.cel].value)
      return;

    let element;
    if (!!data.record) {
      delete data.record.editing;
      element = data.record;
    } else
      element = data;

    this.loading = reload;
    this.masterDataService.editDestination(element)
      .subscribe(
        (res: any) => {
          if (reload) {
            this.refreshDestinations(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
          }

        },
        (err: any) => {
          this.refreshDestinations(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
          if (err.status == 403)
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
          else
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Edit error", "An error occurred during editing.", 10);
          this.loading = false;
        },
        () => {
          this.loading = false;
        })
  }

  onActionDestination(row: any) {
    switch (row.action) {
      case "delete_forever":
        const dialogRef = this.dialog.open(DialogComponent, {
          // width: '250px',
          data: {
            title: "Confirm deletion",
            content: `This action will delete the destination "${row.element.rdcName.value}". Proceed?`,
            cancelButtonLabel: "Cancel",
            cancelButtonColor: "basic",
            confirmButtonLabel: "Delete",
            confirmButtonColor: "warn"
          }
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (!!res) {
            this.masterDataService.deleteDestination(row.element.id.value)
              .subscribe(
                (res: any) => {
                  this.refreshDestinations(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
                },
                (err: any) => {
                  this.loading = false;
                },
                () => this.loading = false)
          }
        });

        break;
      case "cancelNew":
        this.destinationsDataSource = this.destinationsDataSource.slice(1);
        this.destinationsDataSourceSubject.next(this.destinationsDataSource);
        break;
      case "create":
        delete row.element.newRow;
        this.onEditDestination(row.element, true);

        break;
    }
  }

  newDestination() {
    this.destinationsDataSource = [{
      id: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      rdcName: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      plantId: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      country: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      countryIso: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      cluster: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      operatorName: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      rpDd: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      newRow: true
    }, ...this.destinationsDataSource];

    this.destinationsDataSourceSubject.next(this.destinationsDataSource);
  }

  newDestinationAlreadyExists() {
    return this.destinationsDataSource.some((destination) => destination.newRow)
  }

  isActionPresent(action: string) {
    if (this.actions.indexOf(action) > -1)
      return true;
    else
      return false
  }
}

