<div class="fn-table-container" sticky>

  <div *ngIf="(screen === 'monitorHistory' || screen==='monitor-su' || screen==='preview-su')" class="btn-container">
    <span class="bold">Select and...</span>
    <button
      [disabled]="this.selection.selected.length == 0 || (this.selection.selected | containStatus:'status':'in':'DELETE' | async)"
      class="action-button" mat-raised-button color="primary" matTooltip="Change status to OPEN" action-type="button"
      (click)="reopenRecords()"
      *ngIf="screen=='monitorHistory' && isActionPresent('history.edit.reopen') ">Open</button>

    <button
      [disabled]="this.selection.selected.length == 0 || !(this.selection.selected | containStatus:'status':'in':'DELETE' | async)"
      class="action-button" mat-raised-button color="primary" matTooltip="Change status to OPEN" action-type="button"
      (click)="reopenRecords()" *ngIf="screen==='monitor-su' && isActionPresent('monitor.admin.open')">Open</button>

    <button
      [disabled]="this.selection.selected.length == 0 || !(this.selection.selected | containStatus:'status':'in':'DELETE' | async)"
      class="action-button" mat-raised-button color="primary" matTooltip="Change status to OPEN" action-type="button"
      (click)="reopenRecords()" *ngIf="screen==='preview-su' && isActionPresent('preview.admin.open')">Open</button>

    <button
      [disabled]="this.selection.selected.length == 0 || (this.selection.selected | containStatus:'status':'not in':'DELETE' | async)"
      class="action-button" mat-raised-button color="primary" matTooltip="Delete record/s" action-type="button"
      (click)="deleteSelection()"
      *ngIf="screen==='monitor-su' && isActionPresent('monitor.admin.delete')">Delete</button>

    <button
      [disabled]="this.selection.selected.length == 0 || (this.selection.selected | containStatus:'status':'not in':'DELETE' | async)"
      class="action-button" mat-raised-button color="primary" matTooltip="Delete record/s" action-type="button"
      (click)="deleteSelection()"
      *ngIf="screen==='preview-su' && isActionPresent('preview.admin.delete')">Delete</button>
  </div>

  <div *ngIf="screen==='monitor-su' || screen==='preview-su'" class="cb-container">
    <mat-slide-toggle [color]="'primary'" [checked]="showOnlyDuplicate" (change)="onToggleChangeDuplicate($event)">
      <span class="pr-025r">Duplicate Containers</span>
    </mat-slide-toggle>
  </div>

  <div *ngIf="screen==='monitor-su' || screen==='preview-su'" class="cb-container">
    <mat-slide-toggle [color]="'primary'" [checked]="showDisabled" (change)="onToggleChange($event)">
      <span class='fa-stack fs-1r'>
        <i class='fas fa-ghost fa-stack-2x status-icon-ghost'></i>
      </span>
    </mat-slide-toggle>
  </div>

  <!--<mat-slide-toggle (change)="showOpen($event)">Only Closed</mat-slide-toggle>-->

  <div class="actions-container">
    <span></span>
    <button class="action-button" mat-raised-button color="primary" matTooltip="Clear sorting" action-type="button"
      (click)="resetSorting($event)">Clear Sort</button>
  </div>
  <app-paginator class="right" [paginatorLoaderTitle]="'Loading batch: '" [page]="pageEvent.pageIndex"
    [paginatorLoader]="50" [paginatorLoaderList]="[50, 100, 250]" [numElementsLoaded]="numElementsLoaded"
    [numElementsSize]="length" (onPaginatorChange)="onPageChange($event)"></app-paginator>
</div>

<div class="plServiceItemsList-listContainer">
  <cdk-virtual-scroll-viewport [rowHeight]="48">
    <table mat-table [appVirtualDataSource]="trueDataSource" #table multiTemplateDataRows>

      <ng-container matColumnDef="deleteAction" class="table-column"
        *ngIf="screen==='monitor-su' || screen==='preview-su'">

        <ng-template>
          <th mat-header-cell *matHeaderCellDef>
            Action
          </th>
          <td mat-cell *matCellDef="let row">
            <span (click)="deleteRecord(row['monitorId'].value)">
              <mat-icon class="blue">delete</mat-icon>
            </span>
          </td>
        </ng-template>
      </ng-container>

      <ng-container *ngFor="let col of displayedHeaders;" [matColumnDef]="col.field" class="table-column">

        <ng-template [ngIf]="col.field === 'select'">
          <th mat-header-cell *matHeaderCellDef>
            <span>
              <mat-checkbox (change)="masterToggle($event)" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                <span *ngIf="!!selection.selected.length">{{selection.selected.length}} / {{numElementsLoaded}}</span>
              </mat-checkbox>
            </span>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)" [disabled]="isCheckboxDisabled(row)">
            </mat-checkbox>
          </td>
        </ng-template>

        <ng-template [ngIf]="col.field === 'status' && screen != 'preview-su'">
          <th mat-header-cell *matHeaderCellDef style="padding-left: -5px;">
            Status
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="!!row[col.field] && row[col.field].value === 'DELETE'; else new">
              <span [matTooltip]="row[col.field].value">
                <i class='fas fa-ghost fa-fw fs-1r status-icon-ghost'></i>

              </span>
            </ng-container>
            <ng-template #new>
              <ng-container *ngIf="!!row[col.field] && row[col.field].value === 'NEW'; else open">
                <span [matTooltip]="row[col.field].value">
                  <mat-icon class="blue">note_add</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #open>
              <ng-container *ngIf="!!row[col.field] && row[col.field].value === 'OPEN'; else closed">
                <span [matTooltip]="row[col.field].value">
                  <mat-icon class="status-icon-info">lock_open</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #closed>
              <ng-container
                *ngIf="!!row[col.field] && (row[col.field].value == 'CLOSED' || row[col.field].value == null); else reopened">
                <span matTooltip="CLOSED">
                  <mat-icon class="status-icon-error">lock</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #reopened>
              <ng-container *ngIf="!!row[col.field] && row[col.field].value == 'REOPENED'; else none">
                <span [matTooltip]="!!row[col.field] ? row[col.field].value : ''">
                  <mat-icon class="status-icon-info">refresh</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #none>
              <span matTooltip="CLOSED">
                <mat-icon class="status-icon-error">lock</mat-icon>
              </span>
            </ng-template>
          </td>
        </ng-template>

        <ng-template [ngIf]="col.field === 'status' && screen == 'preview-su'">
          <th mat-header-cell *matHeaderCellDef>
            Status
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="!!row[col.field] && row[col.field].value === 'DELETE'; else ready">
              <span [matTooltip]="row[col.field].value">
                <i class='fas fa-ghost fa-fw fs-1r status-icon-ghost'></i>

              </span>
            </ng-container>
            <ng-template #ready>
              <ng-container *ngIf="(row.statusRow).value === 'READY';else second">
                <span [matTooltip]="row.statusRow.errorList | statusTooltip:(displayedHeaders) | async"
                  matTooltipClass="line-broken-tooltip" tabindex="-1">
                  <mat-icon class="status-icon-info">check_circle_outline</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #second>
              <ng-container *ngIf="(row.statusRow).value === 'WARNING';else third">
                <span [matTooltip]="row.statusRow.errorList | statusTooltip:(displayedHeaders) | async"
                  matTooltipClass="line-broken-tooltip" tabindex="-1">
                  <mat-icon class="status-icon-warning">error_outline</mat-icon>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #third>
              <span [matTooltip]="row.statusRow.errorList | statusTooltip:(displayedHeaders) | async"
                matTooltipClass="line-broken-tooltip" tabindex="-1">
                <mat-icon class="status-icon-error">report</mat-icon>
              </span>
            </ng-template>
          </td>
        </ng-template>

        <ng-template [ngIf]="col.field === 'containerDetail'">
          <th mat-header-cell *matHeaderCellDef class="center">{{col.label}}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-button [matMenuTriggerFor]="containerDetailsActions" tabindex="-1">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #containerDetailsActions="matMenu">
              <button [disabled]="!isActionPresent('monitor.detail.view')" mat-menu-item
                (click)="showDetail(row.id.value, row.container.value)" tabindex="-1">
                <mat-icon>manage_search</mat-icon>
                <span>Inspect container</span>
              </button>
              <button [disabled]="!isActionPresent('monitor.comunication.view')" mat-menu-item
                (click)="openNotificationHistory(row.id.value, row.container.value)" tabindex="-1">
                <mat-icon>mark_email_unread</mat-icon>
                <span>Comunication tracker</span>
              </button>
            </mat-menu>

          </td>
        </ng-template>

        <ng-template [ngIf]="col.field === 'detail'">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-button (click)="onExpandClick(row)" tabindex="-1">
              <mat-icon *ngIf="!(row === expandedElement)">keyboard_arrow_right</mat-icon>
              <mat-icon *ngIf="(row === expandedElement)">keyboard_arrow_down</mat-icon>
            </button>
          </td>
        </ng-template>

        <ng-template [ngIf]="col.field === 'audit'">
          <th mat-header-cell *matHeaderCellDef class="center">{{col.label}}</th>
          <td mat-cell *matCellDef="let row" [matTooltip]="row | cellTooltip:col.field:col.label | async"
            matTooltipClass="line-broken-tooltip">
            <button mat-icon-button (click)="showAudit(row.id.value)">
              <mat-icon>history</mat-icon>
            </button>
          </td>
        </ng-template>

        <ng-template
          [ngIf]="col.field !== 'select' && col.field !== 'status' && col.field !== 'audit' && col.field !== 'containerDetail' && col.field !== 'detail'">
          <th mat-header-cell *matHeaderCellDef class="pointer" [ngClass]="!!col.headerClass ? col.headerClass : ''"
            (click)="sortColumn(col.field)">
            <span>
              {{col.label}}<mat-icon
                *ngIf="!!sortedColumns.get(col.field) && sortedColumns.get(col.field).type == 'asc'">
                arrow_upward</mat-icon>
              <mat-icon *ngIf="!!sortedColumns.get(col.field) && sortedColumns.get(col.field).type == 'desc'"
                class="sorter-icon">arrow_downward</mat-icon><label class="sort-order"
                *ngIf="!!sortedColumns.get(col.field) && (sortedColumns.get(col.field).type == 'asc' || sortedColumns.get(col.field).type == 'desc')">{{sortedColumns.get(col.field).order}}</label>
            </span>
          </th>

          <td mat-cell *matCellDef="let element" class="relative"
            [ngClass]="element | cellClass:col.field:(!!col.cellClass ? col.cellClass : ''):col.editable | async"
            [matTooltip]="element | cellTooltip:col.field:col.label | async" matTooltipClass="line-broken-tooltip">

            <!-- [matTooltip]="getCellToolTip(element, col.field)" matTooltipClass="line-broken-tooltip" -->

            <!-- STANDARD VIEW -->
            <div *ngIf="(!col.editable || (col.editable && element.editing !== col.field))"
              [ngClass]="!!col.cellTextClass ? col.cellTextClass : ''">
              <!-- NORMAL CELL -->
              <span *ngIf="!!element && !!element[col.field] && !!element[col.field].value"
                [ngClass]="col.field == 'portEtaActual' || col.field == 'container' ? 'bold' : ''">
                {{element[col.field].value}}
              </span>
            </div>
          </td>
        </ng-template>

      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="detailTable-container" [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'">

            <app-detail-table *ngIf="row === expandedElement" [rowElement]="expandedElement"
              (onDetailChange)="onDetailChange($event)" [screen]="screen">
            </app-detail-table>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" [style.height.px]="48"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === row" [class.selectedRow]="selection.isSelected(row)"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
