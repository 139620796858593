import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { MonitorService } from 'src/app/services/monitor.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TABLE_STYLE } from '../../utils/generic';
import { AlertType } from '../alert/alert.component';
import { Header } from '../data-table/data-table.component';
import { DnDComponent } from '../dnd/dnd.component';

@Component({
  selector: 'app-reply-mail',
  templateUrl: './reply-mail.component.html',
  styleUrls: ['./reply-mail.component.scss']
})
export class ReplyMailComponent implements OnInit {

  dataSourceArr;
  contactGroupCols: any[] = [
    { field: 'shippingLine', label: 'Shipping Line' },
    { field: 'container', label: "Container" }
  ];

  htmlConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '20rem',
    minHeight: '15rem',
    placeholder: "Write your custom body...",
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'insertImage',
        'insertVideo',
        'toggleEditorMode'
      ]
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  htmlContent: string = '';

  htmlSubjectContent: string = '';

  columns = [];

  contactGroups;

  headersSubject = new BehaviorSubject<Header[]>([]);
  headers = this.headersSubject.asObservable();

  displayedHeaders: Header[] = [];
  displayedColumns: string[] = [];

  sendingMailList: Array<any>;

  sendingMailCcList: Array<any>;

  sendAttachment: Array<any>;

  isNewMail: boolean = false;

  newMail: string = '';

  isNewMailCc: boolean = false;

  newMailCc: string = '';

  notificationSubject = '';
  notificationTypology = '';
  messageId = '';
  conversationId = '';
  receivedDateTime = '';
  notificationThreadId;
  appOwnerNotificationId = '';
  notificationThreadDesc = '';

  typeId: number;

  fullBtn = false;

  @ViewChildren('table') tables;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  acceptFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  htmlArr = '';

  constructor(
    public dialogRef: MatDialogRef<ReplyMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private monitorService: MonitorService, public dialog: MatDialog, private notificationService: NotificationService
  ) {

    dialogRef.disableClose = true;

    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    }

    const unwantedMails = (mail) => {
      return !(mail.split('@')[0].toLowerCase().startsWith("oemcc") && mail.split('@')[1].toLowerCase().startsWith("electrolux"));
    }

    if (!!data['sendTo']) {
      this.sendingMailList = data['sendTo'].split(';');
      this.sendingMailList = this.sendingMailList.filter(unique);
      this.sendingMailList = this.sendingMailList.filter(unwantedMails);
    }
    if (!!data['sendCc']) {
      this.sendingMailCcList = data['sendCc'].split(';');

    }
    if (!!data['notificationThreadId'] && !!data['notificationTypology'] && !!data['appOwnerNotificationId']) {
      this.notificationThreadId = data['notificationThreadId'];
      this.notificationTypology = data['notificationTypology'];
      this.appOwnerNotificationId = data['appOwnerNotificationId'];
      this.notificationService.getObjects(this.notificationThreadId).subscribe(response => {

        this.monitorService.getGroupedRecords(response.map(data => data.objectPk), this.notificationTypology, this.appOwnerNotificationId).subscribe(res => {

          if (!!res && !!res.contactGroups) {
            Object.entries(res.contactGroups).forEach(([contactGroup, value], index) => {
              this.htmlContent = !!value["htmlBody"] ? value["htmlBody"] : '';
              this.htmlSubjectContent = !!value["mailSubject"] ? 'RE: ' + value["mailSubject"] : '';
            })
          }
        })
      })
    }
    if (!!data['notificationThreadDesc']) {
      this.notificationThreadDesc = data['notificationThreadDesc'];
    }
    if (!!data['messageId']) {
      this.messageId = data['messageId'];
    }
    if (!!data['conversationId']) {
      this.conversationId = data['conversationId'];
    }
    if (!!data['receivedDateTime']) {
      this.receivedDateTime = data['receivedDateTime'];
    }
    if (!!data['type'] && data['type'] == 'any') {
      this.acceptFileType = '*/*'
    }
    if (!!data['appOwnerNotificationId']) {
      this.typeId = data['appOwnerNotificationId'];
    }

    this.sendAttachment = new Array<any>();
  }

  ngOnInit(): void {

    //this.getContactGroups();
  }

  ngAfterViewInit(): void {

    this.htmlArr = '';
  }

  sendReply() {

    if ((this.sendingMailList.length) == 0) {
      this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Missing recipients", "There are missing recipients, please add an recipient.");
      return;
    }

    this.monitorService.sendReply({
      notificationTo: this.sendingMailList.join(';'),
      notificationCc: !!this.sendingMailCcList && this.sendingMailCcList.length > 0 ? this.sendingMailCcList.join(';') : '',
      notificationBody: TABLE_STYLE + '' + this.htmlContent,
      notificationAttachments: this.sendAttachment.map(attachment => { return { name: attachment.name, contentBytes: attachment.blob, contentType: attachment.type, size: attachment.size } }),
      notificationSubject: this.htmlSubjectContent,
      notificationTypology: this.notificationTypology,
      messageId: this.messageId,
      conversationId: this.conversationId,
      receivedDateTime: this.receivedDateTime,
      notificationThreadId: this.notificationThreadId,
      notificationThreadDesc: this.notificationThreadDesc
    },
      this.typeId
    ).subscribe(res => {
      this.dialog.closeAll();
      this.dialogRef.close(true);
    }, err => {
      if (err.status == 403)
        this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Error 403 Forbidden", "You don't have permission for this action.");
      else
        this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Notification error", "An error occurred while sending the notification.");
    });
  }

  addMail(isCc?: boolean) {
    if (!!isCc) {
      this.isNewMailCc = true;
    } else {
      this.isNewMail = true;
    }

  }

  done(isCc?: boolean) {
    if (!!isCc) {
      if (!!this.newMailCc && this.checkEmail(this.newMailCc)) {
        if (this.sendingMailCcList.indexOf(this.newMailCc) < 0)
          this.sendingMailCcList.push(this.newMailCc);
        this.newMailCc = '';
      }
    } else {
      if (!!this.newMail && this.checkEmail(this.newMail)) {
        if (this.sendingMailList.indexOf(this.newMail) < 0)
          this.sendingMailList.push(this.newMail);
        this.newMail = '';
      }

      if ((this.sendingMailList.length > 0))
        this.alertMessageMonitor.closeAllAlerts();
    }

  }

  cancel() {
    this.newMail = '';
  }

  deleteEmail(id: string, array: string) {
    if (array == 'sendTo') {
      const i = this.sendingMailList.indexOf(id);
      if (i > -1)
        this.sendingMailList.splice(i, 1);
    } else if (array == 'sendCc') {
      const i = this.sendingMailCcList.indexOf(id);
      if (i > -1)
        this.sendingMailCcList.splice(i, 1);
    } else if (array == 'sendAttachment') {
      const i = this.sendAttachment.map(attachment => attachment.name).indexOf(id);
      if (i > -1)
        this.sendAttachment.splice(i, 1);
    }
  }

  checkEmail(email: string) {
    const regex = new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$');
    if (regex.test(email))
      return true;
    else
      return false;
  }

  typeof(x: any) { return typeof x; }

  attachFile() {

    const dialogEvent = this.dialog.open(DnDComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload',
        type: 'any',
      },
    });

    dialogEvent.afterClosed().subscribe((result) => {
      if (!!result && !!result.files && result.files.length > 0) {

        result.files.forEach((file) => {
          this.readBase64(file).then((result) => {
            let r = result;
            if (typeof result === "string") {
              r = result.split(',')[1]
            }
            this.sendAttachment.push({
              name: file.name,
              size: file.size,
              blob: r,
              type: file.type
            })
          })

        });

      }
    });

  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private readBase64(file) {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }

  openFull() {
    this.dialogRef.updateSize("100%", "100%");
    this.fullBtn = true;
  }

  exitFull() {
    this.dialogRef.updateSize("65vw", "");
    this.fullBtn = false;
  }

}

export interface Attachments {
  name: string;
  blob: File;
}



