import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-static-loading',
  templateUrl: './static-loading.component.html',
  styleUrls: ['./static-loading.component.scss']
})
export class StaticLoadingComponent implements OnInit {

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;

  constructor() {
  }

  ngOnInit(): void {
  }

}

