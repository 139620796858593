
<app-action-bar>
  <label class="page-title" page-title>Help</label>
</app-action-bar>
<mat-stepper linear #stepper>
  <mat-step>
    <ng-template matStepLabel>OEMCC - Menu</ng-template>
    <div class="tutorial-section">
      <div class="gridList">
        <div class="gridElem"><img class="image" src="/oem/assets/menu.gif" alt="Menu"><p class="description">You can navigate throught different section with this minimal menu.</p></div>
      </div>
    </div>

    <div>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>OEMCC - PREVIEW</ng-template>
    <div class="tutorial-section">
      <div class="gridList">
        <div class="gridElem"><img class="image" src="/oem/assets/edit_preview.gif" alt="Edit errors"> <p class="description">You can edit record fields or correct errors.</p></div>
      </div>
      <span class="gridSpacer"></span>
      <div class="gridList">
        <div class="gridElem"><img class="image" src="/oem/assets/filter_preview.gif" alt="Filters"> <p class="description">You can filter the table records to help you search.</p></div>
      </div>
      <span class="gridSpacer"></span>
      <div class="gridList">
        <div class="gridElem"><img class="image" src="/oem/assets/filter_status.gif" alt="Filter status"> <p class="description">You can filter the table records by the status of them.</p></div>
      </div>
    </div>

    <div>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>OEMCC - MONITOR</ng-template>
    <div class="tutorial-section">
      <img class="image" src="/oem/assets/preview1.png" alt="First step preview">
    </div>
    <div>
      <button mat-button matStepperPrevious>Back</button>
    </div>
  </mat-step>
</mat-stepper>
