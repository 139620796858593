import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'cellClass'
})
export class CellClassPipe implements PipeTransform {

  transform(value: unknown, field: string, classBase: string, editable: boolean): Observable<string> {
    let classes = classBase;

    if (field !== 'actions' && !!value && !!value[field] && !!value[field].errorType) {
      if (value[field].errorType == 'WARNING') {
        classes += ' warningCell';
      }
      if (value[field].errorType == 'INFO') {
        classes += ' infoCell';
      }
      if (value[field].errorAction == 'FIND' && value[field].errorType != 'INFO') {
        classes += ' newRow';
      }
      if (value[field].errorAction == 'COMPARE') {
        classes += ' compareCell';
      }
      if (value[field].errorType == 'ERROR' && field !== 'destinationCountry' && field !== 'destinationCid') {
        classes += ' errorCell';
      }
    } else if (!editable)
      classes += ' notEditableCell';

    if((field == 'portStorageTotalExtraCost' || field == 'demTotalExtraCost' || field == 'whatifDemTotalExtraCost') && (value[field] != null && value[field].value != null && (value[field].value) > 0)){
      classes += ' totalExtraCostError';
    }

    return of(classes);
  }

}
