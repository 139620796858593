// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // apiUrl: 'http://localhost:8080/oem/api/',
  /*apiUrl: '/oem/api/',
  production: false,
  stsAuthority: 'https://login.microsoftonline.com/d2007bef-127d-4591-97ac-10d72fe28031/v2.0',
  clientId: 'f4a70ecc-79cb-427f-86c2-b09fc584ee82',
  clientRoot: window.location.hostname,
  clientScope: 'User.Read openid profile email offline_access',
  apiRoot: 'api://f4a70ecc-79cb-427f-86c2-b09fc584ee82',*/

  production: false,

  stsAuthority: window["env"].stsAuthority || " ",
  clientId: window["env"].clientId || " ",
  clientRoot: window.location.hostname,
  clientScope:  window["env"].clientScope || " ",
  apiRoot:  window["env"].apiRoot || " ",
  apiUrl:  window["env"].apiUrl || " ",
  authUrl:  window["env"].authUrl || " ",
  ctserviceEndpoint:  window["env"].ctserviceEndpoint || " ",
  ctserviceUrl: window["env"].ctserviceUrl || " "
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
