<mat-tab-group animationDuration="0ms" (selectedTabChange)="selectTab($event)">
  <mat-tab label="Containers">
    <div class="flex mt-32 ph-16">
      <div class="align-right">
        <button mat-flat-button (click)="getContainers()" color="primary">Refresh</button>
      </div>
    </div>
    <div class="mt-16 ph-16">
      <app-data-table [headers]="containersHeaders" [loadingTable]="loadingContainers"
        [dataSource]="containersDataSourceObservable" (onEditCell)="onContainerEdit($event)">
      </app-data-table>
    </div>
  </mat-tab>
  <mat-tab label="Destinations">
    <div class="flex mt-32 ph-16">
      <div class="align-right">
        <button mat-flat-button (click)="getDestinations()" color="primary">Refresh</button>
      </div>
    </div>
    <div class="mt-16 ph-16">
      <app-data-table [headers]="destinationHeaders" [loadingTable]="loadingDestinations"
        [dataSource]="destinationDataSourceObservable"> </app-data-table>
    </div>
  </mat-tab>
</mat-tab-group>