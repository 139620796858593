<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">OEMCC - Monitor - Comunication tracker -
    </label>Container {{id}}
    {{container}}</label>

  <button class="action-button" mat-raised-button color="primary" matTooltip="Refresh" action-type="button"
    (click)="refresh()" [disabled]="objectThreads.length == 0 && !noObjectThreads">
    Refresh
  </button>
  <button class="action-button" mat-raised-button color="primary" matTooltip="Go back to monitor" action-type="button"
    (click)="goBack()">
    Back
  </button>
</app-action-bar>

<div class="alert-container">
  <alert-component #alertMessageMonitor></alert-component>
</div>

<mat-accordion *ngIf="objectThreads.length > 0">
  <ng-container *ngFor="let objectThread of objectThreads; let o = index">
    <mat-expansion-panel
      *ngIf="!!objectThread.notifications && !!objectThread.notifications.notifys && objectThread.notifications.notifys.length > 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Thread number: #{{ objectThread.object.notificationThreadId }}
        </mat-panel-title>
        <mat-panel-description>
          {{ objectThread.object.notificationThreadDesc }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="!!objectThread.notifications && !!objectThread.notifications.notifys">
        <div class="thread-content"
          [ngClass]="{'border-user': (notification.notificationFrom == userEmail || notification.insertUser == userEmail), 'border-system': (notification.notificationFrom == 'system')}"
          *ngFor="let notification of objectThread.notifications.notifys; let n = index">
          <div class="thread-header">
            <span></span>
            <div class="thread-info">
              <div class="thread-actions">
                <button *ngIf="isActionPresent('monitor.notify') && !(!!notification.username)" class="action-button"
                  mat-raised-button color="primary" action-type="button"
                  [matMenuTriggerFor]="actionsMenu">Reply</button>
                <mat-menu #actionsMenu="matMenu" xPosition="after">
                  <div *ngFor="let btn of objectThread.btnsActions; let i= index">
                    <button *ngIf="isActionPresent('monitor.notify.' + btn.notificationId)" mat-menu-item
                      (click)="reply(notification, btn.notificationId, objectThread.object.appOwnerNotificationId, objectThread.object.notificationThreadDesc)">{{btn.notificationLabel}}</button>
                  </div>
                </mat-menu>
              </div>

            </div>
          </div>
          <div class="thread-header mt-16">
            <div class="thread-title bold" *ngIf="!!notification.username">
              {{ notification.username }}{{' \<' + notification.insertUser + '\> ' }} </div>
                <div class="thread-title bold" *ngIf="!(!!notification.username)">
                  {{notification.notificationFrom}}
                </div>
                <div class="thread-info">
                  <div class="thread-date">
                    {{notification.insertDate}}
                  </div>
                </div>
            </div>
            <div class="thread-header-mail">
              <div class="thread-header-mail mt-16"
                [ngClass]="{'thread-subcontainer': notification.notificationFrom === userEmail}">
                <div class="thread-title-mail">
                  <b>Subject:</b> {{notification.notificationSubject}}
                </div>
                <div class="thread-title-mail">
                  <b>From:</b> {{notification.notificationFrom}}
                </div>
                <div class="thread-title-mail">
                  <b>To:</b> {{notification.notificationTo}}
                </div>
                <div class="thread-title-mail">
                  <b>Cc:</b> {{notification.notificationCc}}
                </div>
                <div class="thread-body">
                  <div class="thread-title-mail"
                    *ngIf="!!notification.notificationAttachments && notification.notificationAttachments.length > 0">
                    <b>Attachments:</b>
                  </div>
                  <div class="thread-body-attachment-container"
                    *ngFor="let attachment of notification.notificationAttachments; let a = index">
                    <label class="attachment-title">{{attachment['name']}}</label>
                    <button mat-button (click)="download(attachment)">
                      <mat-icon>file_download</mat-icon>
                    </button>
                    <button mat-button (click)="attachFile(attachment, objectThread.object.notificationThreadId)">
                      <mat-icon>file_present</mat-icon>
                    </button>
                  </div>
                </div>

                <div class="thread-body">
                  <div class="thread-body-html" [innerHtml]="notification.notificationBody">

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
    </mat-expansion-panel>
  </ng-container>

</mat-accordion>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>


<div *ngIf="objectThreads.length == 0 && showSpeakerNotesOff" class="center-pg">
  <div *ngIf="noObjectThreads" class="wrap">
    <mat-icon>speaker_notes_off</mat-icon>
    <br />
    <p>No comunication history available.</p>
  </div>
</div>