<section id="audit">
  <h2 mat-dialog-title>{{data.title}}</h2>

  <div mat-dialog-content>
    <table mat-table [dataSource]="arr" class="mat-elevation-z8" *ngIf="!!arr && arr.length > 0">

      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> {{element.type == 'U' ? 'Update' : element.type == 'I' ? 'Insert' : 'Not
          specified'}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="fieldName">
        <th mat-header-cell *matHeaderCellDef> Column </th>
        <td mat-cell *matCellDef="let element"> {{element.fieldName}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="oldValue">
        <th mat-header-cell *matHeaderCellDef> Old value </th>
        <td mat-cell *matCellDef="let element"> {{element.oldValue}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="newValue">
        <th mat-header-cell *matHeaderCellDef> New value </th>
        <td mat-cell *matCellDef="let element"> {{element.newValue}} </td>
      </ng-container>

      <ng-container matColumnDef="updateDate">
        <th mat-header-cell *matHeaderCellDef matTooltip="DD/MM/YYY HH:mm:ss"> Timestamp </th>
        <td mat-cell *matCellDef="let element"> {{element.updateDate}} </td>
      </ng-container>

      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef> By </th>
        <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <span *ngIf="noAudit">There are no available details.</span>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="close('audit')">{{data.closeButtonLabel}}</button>
  </div>
</section>