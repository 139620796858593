import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit {

  @Input() public disable: boolean;
  @Output() saveOfferEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  isEditRoute() {
    return this.router.url.includes('/edit');
  }

  saveOffer(isRedirect: boolean) {
    this.saveOfferEvent.emit(isRedirect);
  }
}
