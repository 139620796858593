import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { MonitorService, NotificationSL, NotificationSLBody } from 'src/app/services/monitor.service';
import { TABLE_STYLE } from '../../utils/generic';
import { AlertType } from '../alert/alert.component';
import { Header } from '../data-table/data-table.component';
import { DnDComponent } from '../dnd/dnd.component';

@Component({
  selector: 'app-contact-group-dialog',
  templateUrl: './contact-group-dialog.component.html',
  styleUrls: ['./contact-group-dialog.component.scss']
})
export class ContactGroupDialogComponent implements OnInit {

  dataSourceArr;
  contactGroupCols: any[] = [
    { field: 'shippingLine', label: 'Shipping Line' },
    { field: 'container', label: "Container" }
  ];
  contactGroupDataSource;

  htmlConfig = new Map<number, AngularEditorConfig>();
  htmlSubjectConfig = new Map<number, AngularEditorConfig>();
  htmlContent: string[] = [];
  htmlSubjectContent: string[] = [];

  columns = [];

  contactGroups;

  headersSubject = new BehaviorSubject<Header[]>([]);
  headers = this.headersSubject.asObservable();

  displayedHeaders: Header[] = [];
  displayedColumns: string[] = [];

  selectedFields: string[] = [];
  expansionPanelTitles: string[] = [];

  sendTo: Array<any>;

  manualSendTo: Array<any>;

  sendCc: Array<any>;

  manualSendCc: Array<any>;

  sendingMailList: Array<any>;

  sendingMailCcList: Array<any>;

  sendAttachment: Array<any>;

  isNewMail: boolean = false;

  newMail: string = '';

  isNewMailCc: boolean = false;

  newMailCc: string = '';

  fullBtn = false;

  @ViewChildren('table') tables;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  htmlArr: Array<string>;

  acceptFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';


  constructor(
    public dialogRef: MatDialogRef<ContactGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private monitorService: MonitorService, public dialog: MatDialog,
  ) {
    dialogRef.disableClose = true;
    if (!!data['type'] && data['type'] == 'any') {
      this.acceptFileType = '*/*'
    }
  }

  ngOnInit(): void {
    this.sendTo = new Array<any>();
    this.manualSendTo = new Array<any>();
    this.sendingMailList = new Array<any>();
    this.sendingMailCcList = new Array<any>();
    this.sendAttachment = new Array<any>();
    this.getGroupedRecords();
    //this.getContactGroups();
  }

  ngAfterViewInit(): void {

    this.htmlArr = new Array<string>();

    this.tables.changes.subscribe(el => {
      el.toArray().forEach(item => {
        this.htmlArr.push(TABLE_STYLE + item._elementRef.nativeElement.outerHTML);
      });
    });

  }

  getGroupedRecords() {
    this.monitorService.getGroupedRecords(this.data['ids'], this.data['typology'], this.data['notificationId']).subscribe(res => {

      if (!!res && !!res['contactGroups']) {
        this.contactGroupDataSource = res['contactGroups'];
        this.columns = new Array<String>();
        this.dataSourceArr = new Array<any>();
        this.sendTo = new Array<any>();
        this.manualSendTo = new Array<any>();
        this.sendCc = new Array<any>();
        this.manualSendCc = new Array<any>();
        this.sendAttachment = new Array<any>();
        Object.entries(this.contactGroupDataSource).forEach(([contactGroup, value], index) => {
          this.selectedFields[index] = contactGroup;
          this.dataSourceArr.push(value['records'])
          this.htmlContent[index] = value['htmlBody'];
          this.htmlSubjectContent[index] = value['mailSubject'];
          this.expansionPanelTitles.push(contactGroup);
          this.htmlConfig.set(index, {
            editable: true,
            spellcheck: true,
            height: '20rem',
            minHeight: '15rem',
            placeholder: "Write your custom body...",
            translate: 'no',
            defaultParagraphSeparator: 'p',
            defaultFontName: 'Arial',
            toolbarHiddenButtons: [
              [
                'insertVideo',
                'toggleEditorMode'
              ]
            ]
          })
          this.htmlSubjectConfig.set(index, {
            editable: true,
            spellcheck: true,
            height: '3rem',
            minHeight: '2rem',
            placeholder: "Write your custom body...",
            translate: 'no',
            defaultParagraphSeparator: 'p',
            defaultFontName: 'Arial',
            toolbarHiddenButtons: [
              [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'indent',
                'outdent',
                'insertUnorderedList',
                'insertOrderedList',
                'heading',
                'fontName'
              ],
              [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',
                'link',
                'unlink',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode'
              ]
            ]
          })

          this.sendTo.push(value['sendTo']);
          this.manualSendTo.push([]);
          this.sendCc.push(value['sendCc']);
          this.manualSendCc.push([]);
          this.sendingMailList.push(value['sendTo']);
          this.sendingMailCcList.push(value['sendCc']);
          this.sendAttachment.push([])
        });

        for (let col of this.contactGroupCols) {
          this.columns.push(col.field);
        }

        if (!!res['headers']) {
          let resHeaders = [];
          !!res['headers']["detailModels"].forEach(header => {

            resHeaders.push({
              id: header.order,
              field: header.column,
              label: header.label,
              editable: header.editable == 'Y' ? true : false,
              editType: header.type,
              selectOptions: header.options,
              headerClass: header.headerClass,
              actions: header.actions
            });

          })
          this.headersSubject.next(resHeaders);

          this.headers.subscribe(headers => {
            this.displayedColumns = headers.map((header: Header) => header.field);
            this.displayedHeaders = headers;
          });

        }
      }
      if (!!res && !!res['groups']) {
        this.contactGroups = new Array<string>();
        res['groups'].forEach(el => {
          this.contactGroups.push(el.contactGroupName);
        });
      }
    });
  }

  /*getContactGroups() {
    this.contactGroups = new Array<string>();
    this.monitorService.getContactGroups(this.data.typology).subscribe(res => {
      if (!!res) {
        res.forEach(el => {
          this.contactGroups.push(el.contactGroupName);
        });
      }
    })
  }*/

  checkSelects(event: any, index: number) {

    if (!!event && !!event['value']) {
      this.monitorService.getEmailsForContactGroup(event.value).subscribe(res => {
        this.sendTo[index] = res;

        this.sendingMailList[index] = res.concat(this.manualSendTo[index]);

        if ((this.sendingMailList.filter(el => !!el && el.length > 0).length == this.expansionPanelTitles.length))
          this.alertMessageMonitor.closeAllAlerts();
      });
    }

  }

  sendNotify(index: number) {

    if (!(!!this.sendingMailList[index]) || this.sendingMailList[index].length < 1) {
      this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Missing recipients", "There are missing recipients, please add an recipient.", 10);
      return;
    }

    let payload: NotificationSL;

    payload = {
      [this.selectedFields[index]]: {
        sendCc: this.sendingMailCcList[index],
        sendTo: this.sendingMailList[index],
        records: this.dataSourceArr[index],
        htmlBody: TABLE_STYLE + this.htmlContent[index],
        mailSubject: this.htmlSubjectContent[index],
        files: this.sendAttachment[index].map(attachment => { return { name: attachment.name, contentBytes: attachment.blob, contentType: attachment.type, size: attachment.size } })
      }
    }

    this.monitorService.sendNotify({ contactGroups: payload }, this.data.typology, this.data.notificationId).subscribe(res => {

      this.dataSourceArr.splice(index, 1);
      if (!(!!this.dataSourceArr)) this.dataSourceArr = [];

      this.sendingMailCcList.splice(index, 1);
      if (!(!!this.sendingMailCcList)) this.sendingMailCcList = [];
      this.sendingMailList.splice(index, 1);
      if (!(!!this.sendingMailList)) this.sendingMailList = [];
      this.htmlContent.splice(index, 1);
      if (!(!!this.htmlContent)) this.htmlContent = [];
      this.htmlSubjectContent.splice(index, 1);
      if (!(!!this.htmlSubjectContent)) this.htmlSubjectContent = [];
      this.sendAttachment.splice(index, 1);
      if (!(!!this.sendAttachment)) this.sendAttachment = [];

      this.sendTo.splice(index, 1);
      if (!(!!this.sendTo)) this.sendTo = [];
      this.manualSendTo.splice(index, 1);
      if (!(!!this.manualSendTo)) this.manualSendTo = [];
      this.sendCc.splice(index, 1);
      if (!(!!this.sendCc)) this.sendCc = [];
      this.manualSendCc.splice(index, 1);
      if (!(!!this.manualSendCc)) this.manualSendCc = [];

      console.log(this.selectedFields)

      this.selectedFields.splice(index, 1)
      if (!(!!this.selectedFields)) this.selectedFields = [];

      if (this.dataSourceArr.length <= 0) {
        this.dialog.closeAll();
        this.dialogRef.close(true);
      } else {
        this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Notification sent", "You have successfully sent the notification.", 5);
      }

    }, err => {
      if (err.status == 403)
        this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Error 403 Forbidden", "You don't have permission for this action.");
      else
        this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Notification error", "An error occurred while sending the notification.");
    });
  }

  addMail(isCc?: boolean) {
    if (!!isCc) {
      this.isNewMailCc = true;
    } else {
      this.isNewMail = true;
    }

  }

  done(i: number, isCc?: boolean) {

    if (!!isCc) {
      if (!!this.newMailCc && this.checkEmail(this.newMailCc)) {
        if (this.sendingMailCcList[i].indexOf(this.newMailCc) < 0) {
          this.manualSendCc[i].push(this.newMailCc);
          this.sendingMailCcList[i] = this.sendCc[i].concat(this.manualSendCc[i]);
        }
        this.newMailCc = '';
      }
    } else {
      if (!!this.newMail && this.checkEmail(this.newMail)) {
        if (this.sendingMailList[i].indexOf(this.newMail) < 0) {
          this.manualSendTo[i].push(this.newMail);
          this.sendingMailList[i] = this.sendTo[i].concat(this.manualSendTo[i]);
        }
        this.newMail = '';
      }

      if ((this.sendingMailList.filter(el => !!el && el.length > 0).length == this.expansionPanelTitles.length))
        this.alertMessageMonitor.closeAllAlerts();
    }

  }

  cancel() {
    this.newMail = '';
  }

  deleteEmail(id: string, index: number, array: string) {
    if (array == 'sendTo') {
      const i = this.sendTo[index].indexOf(id);
      if (i > -1)
        this.sendTo[index].splice(i, 1);
    } else if (array == 'manualSendTo') {
      const i = this.manualSendTo[index].indexOf(id);
      if (i > -1)
        this.manualSendTo[index].splice(i, 1);
    } else if (array == 'sendCc') {
      const i = this.sendCc[index].indexOf(id);
      if (i > -1)
        this.sendCc[index].splice(i, 1);
    } else if (array == 'manualSendCc') {
      const i = this.manualSendCc[index].indexOf(id);
      if (i > -1)
        this.manualSendCc[index].splice(i, 1);
    } else if (array == 'sendAttachment') {
      const i = this.sendAttachment[index].map(attachment => attachment.name).indexOf(id);
      if (i > -1)
        this.sendAttachment[index].splice(i, 1);
    }

    this.sendingMailList[index] = this.sendTo[index].concat(this.manualSendTo[index]);
  }

  checkEmail(email: string) {
    const regex = new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$');
    if (regex.test(email))
      return true;
    else
      return false;
  }

  typeof(x: any) { return typeof x; }

  attachFile(i: number) {

    const dialogEvent = this.dialog.open(DnDComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload',
        type: 'any',
      },
    });

    dialogEvent.afterClosed().subscribe((result) => {
      if (!!result && !!result.files && result.files.length > 0) {

        result.files.forEach((file) => {
          this.readBase64(file).then((result) => {
            let r = result;
            if (typeof result === "string") {
              r = result.split(',')[1]
            }
            this.sendAttachment[i].push({
              name: file.name,
              size: file.size,
              blob: r,
              type: file.type
            })
          })

        });

      }
    });

  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private readBase64(file) {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);

      reader.readAsDataURL(file);
    });
    return future;
  }

  openFull() {
    this.dialogRef.updateSize("100%", "100%");
    this.fullBtn = true;
  }

  exitFull() {
    this.dialogRef.updateSize("65vw", "");
    this.fullBtn = false;
  }


}

export interface Attachments {
  name: string;
  blob: File;
}
