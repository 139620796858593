import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AdministrationService } from 'src/app/services/administration.service';
import { AuthService } from 'src/app/services/auth.service';
import { MonitorService } from 'src/app/services/monitor.service';
import { AlertType } from '../alert/alert.component';
import { Header } from '../data-table/data-table.component';
import { DialogComponent } from '../dialog/dialog.component';
import { LoadingComponent } from '../loading/loading.component';
import { PageEventType } from '../paginator/paginator.component';

@Component({
  selector: 'administration-panel',
  templateUrl: './administration-panel.component.html',
  styleUrls: ['./administration-panel.component.scss']
})
export class AdministrationPanelComponent implements OnInit {

  @Input() public title: string;
  @Input() public headers: Observable<Header[]>;
  @Input() public screen: string;
  @Input() public endpoint: string;
  @Input() public filter: any;
  @Input() public newRecord: any;
  @Input() public idName: string;
  @Input() public expanded: boolean;
  @Input() public monitorId?: number | string;
  @Input() public selectColumns: Map<string, string>;

  @Output() applyFilter: EventEmitter<any> = new EventEmitter();
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  @Output() dataEdit: EventEmitter<any> = new EventEmitter();
  @Output() clickAction: EventEmitter<any> = new EventEmitter();

  filterValues: Array<string>;

  subscriptionArray: Subscription[] = [];

  actions: string[] = [];

  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 }

  dataSourceSubject = new BehaviorSubject<any[]>([]);
  dataSourceObservable = this.dataSourceSubject.asObservable();
  dataSource: any[] = [];
  length = 0;

  selectOptions: Map<string, string[]> = new Map();

  @ViewChild('childDataTable') childDataTable;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  sortedColumns: Map<string, { order: number, type: 'asc' | 'desc' }>;

  constructor(
    private adminService: AdministrationService,
    private authService: AuthService,
    private monitorService: MonitorService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService) {
    this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([]);
    if (this.endpoint == 'oemcc_tasks')
      this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([['id', { order: 1, type: 'desc' }]]);
  }

  ngOnInit(): void {
    this.filterValues = new Array<string>();

    this.refreshTable(this.pageEvent.pageIndex, this.pageEvent.loader, this.sortedColumns, this.filterValues);

    this.subscriptionArray.push(
      this.authService.getAction().subscribe(actions => {
        this.actions = actions['actions'];
        //this.subscriptionArray.forEach(sub => sub.unsubscribe());
      })
    );

    if (!!this.selectColumns) {
      for (const [colName, endpoint] of this.selectColumns) {
        this.getSelectOptions(colName, endpoint);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => sub.unsubscribe);
  }

  onPageChange(pageEvent: PageEventType): void {
    this.pageEvent = pageEvent;
    this.getAdminTable(pageEvent.pageIndex, pageEvent.loader, this.sortedColumns, this.filterValues);
  }

  onApplyFilter(filters: Array<string>): void {
    this.filterValues = filters;
    this.refreshTable(0, this.pageEvent.loader, this.sortedColumns, filters);
  }

  getAdminTable(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>, reload?: boolean) {

    let params = new HttpParams().set('page', page).set('size', size);

    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();

    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })

    filters.forEach(filter => {
      if (!(!!params.get('search')))
        params = params.set('search', filter);
      else
        params = params.append('search', filter);
    });

    this.adminService.getAdminTableDataOfEndpoint(this.endpoint, params).subscribe(
      res => {
        if (!!res) {
          if (!!res['content']) {
            this.length = res['totalElements'];
            this.dataSource = this.hasSubArray(this.dataSource, res['content']);
            //this.dataSource = res['content'];
          } else
            this.dataSource = res;
          this.dataSourceSubject.next(this.dataSource);

        }
        //loadingEvent.close();
this.spinner.hide();
      }
    ), error => {
      //loadingEvent.close();
this.spinner.hide();
    }
  }

  refreshTable(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();

    let params = new HttpParams().set('page', page).set('size', size);


    if (!!this.monitorId) {
      params = params.set('monitorId', this.monitorId);
    }


    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''));
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''));
    })

    filters.forEach(filter => {
      if (!(!!params.get('search')))
        params = params.set('search', filter);
      else
        params = params.append('search', filter);
    });

    this.adminService.getAdminTableDataOfEndpoint(this.endpoint, params).subscribe(
      res => {
        if (!!res) {
          if (!!res['content']) {
            this.length = res['totalElements'];
            this.dataSource = res['content'];
          } else
            this.dataSource = res;
          this.dataSourceSubject.next(this.dataSource);
          //this.userContextLength = res.totalElements;
        }
        //loadingEvent.close();
this.spinner.hide();
      }, err => {
        //loadingEvent.close();
this.spinner.hide();
      }
    )
  }

  hasSubArray(master: any[], sub: any[]) {
    sub.forEach(elem => {
      const index = master.findIndex(e => e.id === elem.id);
      if (index !== -1) {
        master[index] = elem;
      } else {
        master.push(elem);
      }
    });
    return master;

  }

  onEdit(data: any, reload = false) {

    let noData = true;

    for (let key in data) {
      if (data[key] != '')
        noData = false;
    }

    if (!!data.record && !!data.oldRecord && data.record[data.cel] === data.oldRecord[data.cel]) {
      return;
    }

    if (noData) {
      this.dataSource.shift();
      this.dataSourceSubject.next(this.dataSource);
      return;
    }

    let element;

    if (!!data.record) {
      delete data.record.editing;
      element = data.record;
    }
    else
      element = data;

    this.adminService.adminEdit(this.endpoint, element).subscribe(
      res => {
        if (reload)
          this.refreshTable(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
      }, err => {
        this.refreshTable(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
        if (err.status == 403)
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
        else
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Edit/Insert error", "An error occurred during edit/insert.", 10);
      }
    );
  }

  onAction(row: any) {
    switch (row.action) {
      case "delete":
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: "Confirm Deletion",
            content: `This action will delete the entire row. Proceed?`,
            cancelButtonLabel: "Cancel",
            cancelButtonColor: "basic",
            confirmButtonLabel: "Delete",
            confirmButtonColor: "warn"
          }
        });

        dialogRef.afterClosed().subscribe(res => {
          if (!!res) {

            if (row.element.id == undefined)
              row.element.id = row.element[this.idName]

            this.adminService.adminDelete(this.endpoint, row.element.id).subscribe(
              res => {
                this.refreshTable(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
              },
              err => {
                if (err.status == 403)
                  this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Error 403 Forbidden", "You don't have permission for this action.");
                else
                  this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Delete error", "An error occurred during delete");
              }
            );
          }
        });
        break;

      case "cancel":
        this.dataSource = this.dataSource.slice(1);
        this.dataSourceSubject.next(this.dataSource);
        break;

      case "create":
        delete row.element.newRow;
        this.onEdit(row.element, true);
        break;

      case "restart_alt":
        this.adminService.restartTask(row.element.id).subscribe(
          res => {
            this.refreshTable(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns, this.filterValues);
            this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Success", "Task successfully restarted.", 3);
          },
          err => {
            if (err.status == 403)
              this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Error 403 Forbidden", "You don't have permission for this action.");
            else
              this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Restart error", "An error occurred while restarting the task.");
          }
        );
    }
  }

  create() {
    this.dataSource = [
      _.cloneDeep(this.newRecord), ...this.dataSource];

    this.dataSourceSubject.next(this.dataSource);
  }

  duplicatedUserContext() {
    return this.dataSource.some((userContext) => userContext.newRow);
  }

  isActionPresent(action: string) {
    if (this.actions.indexOf(action) > -1)
      return true;
    else
      return false
  }

  onSortChange(pageSorting: { sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }): void {
    this.sortedColumns = pageSorting.sorting;
    this.refreshTable(0, this.dataSource.length, pageSorting.sorting, this.filterValues);
  }

  getSelectOptions(colName: string, endpoint: string) {
    this.adminService.getAdminTableDataOfEndpoint(endpoint).subscribe(
      res => {
        if (!!res) {
          this.selectOptions.set(colName, res.map(item => {
            return item[colName];
          }));
        }
      }
    )
  }

  onEditStart(obj: { column: Header, element: any }) {

    if (obj.column.field === "typology" && this.screen == 'administration.contact_groups') {
      obj.column.selectOptions = this.selectOptions.get("typologyId");
    }
    if (obj.column.field === 'contactGroupId') {
      //const loadingEvent = this.dialog.open(LoadingComponent);
      this.monitorService.getContactGroups(obj.element.typology).subscribe(res => {
        let contactGroups = new Array();
        if (!!res) {
          res.forEach(el => {
            contactGroups.push(el.contactGroupName);
          });
          obj.column.selectOptions = contactGroups;
        }
        ////loadingEvent.close();
this.spinner.hide();
      });
    }
  }
}
