<app-action-bar>
  <label class="page-title" page-title> Administration </label>
</app-action-bar>

<div class="mt-16 ph-16">
  <mat-accordion fxFlexFill fxLayout="column" multi>
    <administration-panel *ngIf="checkRoute('context')" [title]="'User Context'" [headers]="userContextHeader"
      [screen]="screen + '.' + path" [filter]="filterUserContext" [endpoint]="'oemcc_contexts'"
      [newRecord]="newUserContext" [expanded]="true">
    </administration-panel>

    <administration-panel *ngIf="checkRoute('team')" [title]="'User Team'" [headers]="userTeamHeader"
      [screen]="screen + '.' + path" [filter]="filterUserTeam" [endpoint]="'oemcc_teams'" [newRecord]="newUserTeam"
      [expanded]="true">
    </administration-panel>

    <administration-panel *ngIf="checkRoute('task')" [title]="'Tasks'" [headers]="taskHeader"
      [screen]="screen + '.' + path" [filter]="taskFilter" [endpoint]="'oemcc_tasks'" [expanded]="true">
    </administration-panel>

    <administration-panel *ngIf="checkRoute('contact_groups')" [title]="'Contact Group'" [headers]="contactGroupHeader"
      [screen]="screen + '.' + path" [filter]="contactGroupFilter" [endpoint]="'oemcc_contact_groups'"
      [newRecord]="newContactGroup" [idName]="'contactGroupId'" [selectColumns]="selectColumns" [expanded]="true">
    </administration-panel>

    <!--<administration-panel *ngIf="checkRoute('contact_groups')" [title]="'Contact Groups Detail'"
      [headers]="contactGroupHeader" [screen]="screen + '.' + path" [filter]="contactGroupFilter"
      [endpoint]="'contact_groups'" [idName]="idName" [expanded]="true">
    </administration-panel>-->

    <administration-panel *ngIf="checkRoute('attachment_category')" [title]="'Attachment Category'"
      [headers]="attachmentCategoryHeader" [screen]="screen + '.' + path" [filter]="attachmentCategoryFilter"
      [endpoint]="'attachment_category'" [idName]="'categoryId'" [newRecord]="newAttachmentCategory" [expanded]="true">
    </administration-panel>

  </mat-accordion>
</div>