import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateY'
})
export class TranslateYPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return "transform: translateY("+value+"px)";
  }

}
