export const TABLE_STYLE = `
<style>
    table {
        border: 1px solid #1C6EA4 !important;
        background-color: #EEEEEE !important;
        width: 100% !important;
        text-align: left !important;
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        overflow-x: auto !important;
        border-collapse: collapse;
    }

    table td,
    table th {
        border-left: 1px solid rgba(0, 0, 0, 0.05);
        padding: 3px 2px !important;
        max-width: 10rem;
    }

    table tbody td {
        font-size: 13px !important;
    }

    table thead {
        background: #1C6EA4 !important;
    }

    table thead th {
        font-size: 15px !important;
        font-weight: bold !important;
        color: #FFFFFF !important;
    }

    table thead th:first-child {
        border-left: none !important;
    }
</style>

`;

export const removeItemFromLocalstorage = (item: string, posStart?: number, posEnd?: number) => {
  if (!!posStart && !!posEnd) {
    Object.entries(localStorage).map(
      x => x[0]
    ).filter(
      x => !!posEnd && posEnd > posStart ? x.substring(posStart, posEnd) == item : x.substring(posStart) == item
    ).map(
      x => localStorage.removeItem(x))
  }else{
    Object.entries(localStorage).map(
      x => x[0]
    ).filter(
      x => x.startsWith(item)
    ).map(
      x => {
        console.log(x)
        localStorage.removeItem(x)
      })
  }
}




export const splitFilter = (item: string) => {
  if (item.includes(':')) {
    const fv = item.split(':');
    return {
      key: fv[0],
      value: fv[1],
      compare: ':'
    }
    //return item.split(':').push(':')
  } else if (item.includes('<')) {
    const fv = item.split('<');
    return {
      key: fv[0],
      value: fv[1],
      compare: '<'
    }
    //return item.split('<').push('<')
  } else if (item.includes('>')) {
    const fv = item.split('>');
    return {
      key: fv[0],
      value: fv[1],
      compare: '>'
    }
    //return item.split('>').push('>')
  } else if (item.includes('=')) {
    const fv = item.split('=');
    return {
      key: fv[0],
      value: fv[1],
      compare: '='
    }
    //return item.split('=').push('=')
  } else if (item.includes('~')) {
    const fv = item.split('~');
    return {
      key: fv[0],
      value: fv[1],
      compare: '~'
    }
    //return item.split('~').push('~')
  } else if (item.includes('!')) {
    const fv = item.split('!');
    return {
      key: fv[0],
      value: fv[1],
      compare: '!'
    }
    //return item.split('!').push('!')
  } else if (item.includes('^')) {
    const fv = item.split('^');
    return {
      key: fv[0],
      value: fv[1].replace(/[\])}[{(]/g, ''),
      compare: '!'
    }
    //return item.split('!').push('!')
  }
  return null;
}

export const groupBy = (objectArray: any[], property: string, compareTo: string) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    if (!(acc[key].some(e => e[compareTo] === obj[compareTo])))
      acc[key].push(obj);
    return acc;
  }, {});
}


export const reviver = (key, value) => {
  if(typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}


export const replacer = (key, value) => {
  if(value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}


export const RegexParser = (input) => {

  // Validate input
  if (typeof input !== "string") {
    throw new Error("Invalid input. Input must be a string");
  }

  // Parse input
  var m = input.match(/(\/?)(.+)\1([a-z]*)/i);

  if(m == null ){
    throw new Error("No regexp found");
  }

  // Invalid flags
  if (m[3] && !/^(?!.*?(.).*?\1)[gmixXsuUAJ]+$/.test(m[3])) {
    return RegExp(input);
  }

  // Create the regular expression
  return new RegExp(m[2], m[3]);
};



