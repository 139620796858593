import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as _ from 'lodash';
import { AuthService } from 'src/app/services/auth.service';
import { NewDelegationComponent } from '../new-delegation/new-delegation.component';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  username: string | null;
  email: string | null;
  role: string = '';
  actions: string[] = [];

  htmlConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '20rem',
    minHeight: '15rem',
    placeholder: "Write your custom body...",
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'insertVideo',
        'toggleEditorMode'
      ]
    ]
  };
  htmlContent: string = '';
  oldHtmlContent: string = '';

  constructor(
    public dialogRef: MatDialogRef<UserSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _authService: AuthService, public dialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this._authService.getUser().then(user => {
      if (!!user) {
        this.username = user.profile.name;
        this.getUserContext();
        if (user.profile.email == undefined || user.profile.email == null) this.email = user.profile.preferred_username;
        else this.email = user.profile.email;


        //this.usr = (user.profile.name.split(' '))[0].slice(0, 1) + (user.profile.name.split(' '))[1].slice(0, 1);
      }

    })

    this._authService.getUserInfo().subscribe(user =>{
      this.htmlContent = !!user.userSignature ? user.userSignature : '';
      this.oldHtmlContent = _.cloneDeep(!!user.userSignature ? user.userSignature : '');
    });

    this._authService.getRole().subscribe(res => {
      this.role = res['defaultRole'];
    })

    this.getGrantTo();
    this.getGrantFrom();

    this._authService.getAction().subscribe(res => {
      this.actions = res['actions'];
      if (this.isActionPresent('administration.delegate'))
        this.getGrants();
    })

  }

  contextCols: string[] = ['contextId', 'value', 'userId'];
  contextDataSource;

  grantToCols: string[] = ['delegateGranteeId', 'delegateExpirationDate', 'action'];
  grantToDataSource;

  grantFromCols: string[] = ['delegateGrantorId', 'delegateExpirationDate'];
  grantFromDataSource;

  adminGrantCols: string[] = ['delegateGrantorId', 'delegateGranteeId', 'delegateExpirationDate', 'action'];
  adminGrantDataSource;

  makeExpire(delegateId: number) {
    this._authService.expireDelegate(delegateId).subscribe(res => {
      this.grantToDataSource = res['delegate'];
    })
  }

  adminMakeExpire(delegateId: number) {
    this._authService.adminExpireDelegate(delegateId).subscribe(res => {
      this.adminGrantDataSource = res['delegate'];
    })
  }

  getGrantTo() {
    this._authService.getGrantor().subscribe(res => {
      this.grantToDataSource = res['delegate'];
    })
  }

  getUserContext() {
    this._authService.getUserContext().subscribe(res => {

      this.contextDataSource = res.map(userContext => {

        if (userContext.userId === this.email) {
          userContext.userId = null;

        }

        return userContext;
      })
    })
  }

  getGrantFrom() {
    this._authService.getGrantee().subscribe(res => {
      this.grantFromDataSource = res['delegate'];
    });
  }

  getGrants() {
    this._authService.getGrants().subscribe(res => {
      this.adminGrantDataSource = res['delegate'];
    });
  }

  newDelegate() {
    const newDelegation = this.dialog.open(NewDelegationComponent);

    newDelegation.afterClosed().subscribe(res => {
      if (!!res)
        this.getGrantTo();
    })
  }

  newAdminDelegate() {
    const newAdminDelegation = this.dialog.open(NewDelegationComponent, {
      data: 'admin'
    });

    newAdminDelegation.afterClosed().subscribe(res => {
      if (!!res)
        this.getGrants();
    });
  }

  isActionPresent(action: string) {
    if (this.actions.indexOf(action) > -1)
      return true;
    else
      return false
  }

  saveUserSignature() {
    this._authService.saveUserSignature(this.htmlContent).subscribe(user => {
      this.htmlContent = !!user.userSignature ? user.userSignature : '';
      this.oldHtmlContent = _.cloneDeep(!!user.userSignature ? user.userSignature : '');
    });
  }
}
