<h2 mat-dialog-title>Edit Final Destination</h2>
<mat-dialog-content class="mat-typography">
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 'select'" (opened)="setStep('select')" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Select Final Destination
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field appearance="fill" (click)="$event.stopPropagation()">
        <mat-label>Select related destination</mat-label>
        <mat-select [id]="'finalDestination'" [(ngModel)]="rawDestinationSelected">
          <mat-option *ngFor="let option of rawDestinationsOptions ?? []" [value]="option.value">{{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 'new'" (opened)="setStep('new')" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          New Raw Destination
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field appearance="fill">
        <mat-label>Final Destination</mat-label>
        <input matInput type="text" [(ngModel)]="rawDestinationSelected">
      </mat-form-field>

      <mat-form-field appearance="fill" style="margin-left: 5px" (click)="$event.stopPropagation()">
        <mat-label>Select related destination</mat-label>
        <mat-select [id]="'destination'" [(ngModel)]="destinationSelected">
          <mat-option *ngFor="let option of destinationsOptions ?? []" [value]="option.value">{{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="{save:false, forms: null}" cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="close(true)" >Save</button>
</mat-dialog-actions>
