import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'stringToDateNoObs'
})
export class StringToDateNoObsPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {

    if (!!value) {
      return moment(value, "DD/MM/YYYY").toDate().toString();
    }
    return '';
  }

}
