<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">Master Data - </label> Subproduct Lines</label>
  <button mat-flat-button action-type="button" class="action-button"
    (click)="refreshSubproductLines(0, (pageEvent.pageIndex+1)*pageEvent.loader, sortedColumns, filterValues)"
    color="primary" matTooltip="Refresh">Refresh</button>
  <button *ngIf="isActionPresent('subproductLine.edit')" mat-flat-button action-type="button" class="action-button"
    (click)="newSubproductLine()" [disabled]="newSubproductLineAlreadyExists()" color="primary"
    matTooltip="Add a new Subproduct Line">New Subproduct
    Line</button>
</app-action-bar>

<div class="alert-container">
  <alert-component #alertMessageMonitor></alert-component>
</div>

<div class="datatable-container ph-16">
  <div class="datatable-content">
    <app-filter [filterObj]="filterObjs" (applyFilterEv)="onApplyFilter($event, 0)"></app-filter>
    <app-data-table class="datatable-content" [headers]="subproductLinesHeaders" [length]="subproductLinesLength"
      [loadingTable]="loading" [dataSource]="subproductLinesDataSourceObservable"
      [numElementsLoaded]="(subproductLinesDataSourceObservable | async)?.length" (onSortChange)="onSortChange($event)"
      [sortedColumns]="sortedColumns" (onEditStart)="onSubproductLinesEditStart($event)"
      (onPaginationChange)="onPageChange($event, 2)" (onEditCell)="onEditSubproductLines($event, true)"
      (onActionClick)="onActionSubproductLine($event)" [screen]="screen" #childDataTable>
    </app-data-table>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white; font-size: 1.5rem" > Loading... </p></ngx-spinner>
