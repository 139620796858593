<nb-layout>

  <!--<nb-layout-header fixed>
    Insert header here
  </nb-layout-header>-->

  <nb-layout-column>
    <app-topbar pageTitle="ELX OEMCC MONITOR" (onClickLogin)="login()" (onClickLogout)="logout()" (onClickLogo)="onClickLogo()" (onClickInfo)="userInfo()"
      (toggleSidenav)="appDrawer.toggle()" *ngIf="isLoggedIn" [actions]="actions" [version]="version"></app-topbar>
    <!--<router-outlet></router-outlet> -->


    <mat-sidenav-container autosize class="sidenav-container">

      <mat-sidenav #appDrawer [mode]="'over'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56"
        [ngClass]="{'sidenav-testing':isDevMode(), 'sidenav':!isDevMode()}">

        <mat-nav-list>
          <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
        </mat-nav-list>

      </mat-sidenav>

      <mat-sidenav-content>

        <router-outlet></router-outlet>

      </mat-sidenav-content>

    </mat-sidenav-container>

  </nb-layout-column>

  <!--<nb-layout-footer fixed>
    Insert footer here
  </nb-layout-footer>-->

</nb-layout>
