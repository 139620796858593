<h2 mat-dialog-title>{{ dialog['title'] }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>Category</mat-label>
      <mat-select [formControl]="categoriesControl" name="food">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let cat of categories" [value]="cat.value">
          {{cat.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div class="container-list">
    <mat-selection-list  #list [(ngModel)]="selectedOptions" (ngModelChange)="onNgModelChange($event)">
      <mat-list-option checkboxPosition="before" *ngFor="let toElement of toList" [value]="toElement.id" color="primary">
        {{toElement.id}} - {{toElement.container}}
      </mat-list-option>
    </mat-selection-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="{send:false}" cdkFocusInitial>{{ dialog['closeButtonLabel'] }}</button>
  <button mat-button (click)="saveFileTo()" [disabled]="!(!!categoriesControl.value && (!!selectedOptions && selectedOptions.length > 0))">{{ dialog['confirmButtonLabel'] }}</button>
</mat-dialog-actions>
