<!--
  Wrap the rendered content in an element that will be used to offset it based on the scroll
  position.
-->
<div #contentWrapper class="app-table-virtual-scroll-content-wrapper">
	<ng-content></ng-content>
</div>
<!--
  Spacer used to force the scrolling container to the correct size for the *total* number of items
  so that the scrollbar captures the size of the entire data set.
-->
<div class="app-table-virtual-scroll-spacer" [style.width]="_totalContentWidth" [style.height]="_totalContentHeight">
</div>
