import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Header } from '../data-table/data-table.component';

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: AutocompleteComponent }],
})
export class AutocompleteComponent implements OnInit, AfterViewInit {

  @Input()
  selectedOptions: any[];

  @Input()
  element: any;

  @Output()
  elementChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  column: Header;

  @Input()
  identifier: string;

  @Output()
  onFocusOut: EventEmitter<any> = new EventEmitter<any>();

  filteredOptions: Observable<any[]> = of([]);

  optionsCtrl: FormControl;

  @Input()
  defaultSearch: string = '';

  constructor() {
  }

  ngOnInit(): void {
    this.optionsCtrl = new FormControl(this.defaultSearch);

    this.filteredOptions = this.optionsCtrl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.label;
        return name ? this.filterOption(name as string) : this.selectedOptions.slice();
      }),
    );
  }

  ngAfterViewInit(): void {
    this.optionsCtrl.setValue(this.element[this.column.field].value ?? this.element[this.column.field]);
    this.filteredOptions = of(this.filterOption(this.element[this.column.field].value ?? this.element[this.column.field]));
  }

  filterOption(option: string): any[] {

    const filterValue = option?.toLowerCase() ?? '';
    return this.selectedOptions.filter(opt => {
      return opt.label.toLowerCase().includes(filterValue)
    });
  }

  onChange() {
    this.element[this.column.field].value = this.optionsCtrl.value;
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.element[this.column.field].value = event.option.value

    this.elementChange.emit(this.element)
  }

  displayFn(value?: string) {
    return value ? this.selectedOptions?.find(_ => _.value === value)?.label : undefined;
  }

}
