import { trigger, state, style, transition, animate } from '@angular/animations';
import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { async, BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { logspace } from '../customs/logspace.service';
import { PageEventType } from '../paginator/paginator.component';

@Component({
  selector: 'admin-data-table',
  templateUrl: './admin-data-table.component.html',
  styleUrls: ['./admin-data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminDataTableComponent implements OnInit {

  ITEM_SIZE = 48;
  offset: number = 0;
  offsetObs: Observable<number>;
  offsetChange = new BehaviorSubject(0);

  @Input()
  length: number = 0

  @Input() isFilter: boolean = true;

  @Input()
  dataSource: Observable<any>;
  @Input()
  detailHeaders: Header[] = [];
  @Input()
  detailDataObs: Observable<any>;

  trueDataSource: ArrayDataSource<any>;

  //dataSourceTable: DataTableSource;

  dataTable: MatTableDataSource<{ [key: string]: any }> = new MatTableDataSource<{ [key: string]: any }>([]);

  @Input()
  headers: Observable<Header[]>;
  @Input()
  loadingTable: boolean = false;
  @Input()
  showMerge: boolean = false;

  @ViewChild(MatSort) sort: MatSort;

  @Output()
  onCheckboxClick: EventEmitter<{ info: boolean, warning: boolean, error: boolean }> = new EventEmitter<{ info: boolean, warning: boolean, error: boolean }>();
  @Output()
  onEditStart: EventEmitter<{ column: Header, element: any }> = new EventEmitter<{ column: Header, element: any }>();
  @Output()
  onEditCell: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditFinalDestinationCell: EventEmitter<{ col: any, element: any }> = new EventEmitter<{ col: any, element: any }>();
  @Output()
  onMergeClick: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output()
  onSortChange: EventEmitter<{ sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }> = new EventEmitter<{ sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }>();
  @Output()
  onPaginationChange: EventEmitter<PageEventType> = new EventEmitter<PageEventType>();
  @Output()
  onActionClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onExpandEmitter: EventEmitter<any> = new EventEmitter<any>();


  displayedColumns: string[] = [];
  errors: CellError[] = [];
  @Input()
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };

  @Input()
  screen: string;

  displayedHeaders: Header[] = [];

  oldElement: any;

  @Input()
  numElementsLoaded: number = 0;

  @Input()
  numElementsSize: number = 0;

  @Input() public set dataSourceObservable(d: Observable<any[]>) {
    if (d !== null) {
      this.dataSource = d;
      d.subscribe(table => {
        this.dataTable.data = table;
        this.trueDataSource = new ArrayDataSource(table);
        //this.dataSourceTable.matTableDataSource.data = table;
        //this.viewPort.checkViewportSize();
      })
      /*d.subscribe((res: any[]) => {
        this.dataSource = res;
      });*/
    }
  }

  permittedActions: String[] = [];

  expandedElement: any | null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {

      this.dataSource = changes['dataSource'].currentValue
      changes['dataSource'].currentValue.subscribe(table => {
        !!table ? this.dataTable.data = table : this.dataTable.data = [];
        !!table ? this.trueDataSource = new ArrayDataSource(table) : this.trueDataSource = new ArrayDataSource([]);
        //!!table ? this.dataSourceTable.matTableDataSource.data = table : this.dataSourceTable.matTableDataSource.data = []
      })
    }
  }

  @Input()
  sortedColumns: Map<string, { order: number, type: 'asc' | 'desc' }> = new Map();

  selection = new SelectionModel<any>(true, []);

  sortingList: Array<{ column: String; isAsc: boolean }> = [];

  isEnded: boolean = false;

  @Input()
  checkboxes: { info: boolean, warning: boolean, error: boolean };

  keyDownEvent: boolean = false;
  rendered: boolean = false;


  constructor(public authService: AuthService) {
    this.pageEvent = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
  }

  ngOnInit(): void {
    this.headers.subscribe(headers => {
      this.displayedColumns = headers.map((header: Header) => header.field);
      this.displayedHeaders = headers;
    });

    this.authService.getAction().subscribe(actions => {
      this.permittedActions = actions['actions'];
    });
  }

  onPageChange(pageEvent: any) {
    //this.pageEvent = pageEvent;
    this.onPaginationChange.emit(pageEvent)
  }

  onAction(element: any, action: string) {
    this.onActionClick.emit({ element, action })
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      //sort direction
    }
  }

  onClickCell(element: any, col: Header) {
    //console.log(element)
    if (!this.isAdding() && !element.newRow) {
      if (col.editable && element.editing !== col.field) {
        //Remove editing from any cell
        this.dataSource.subscribe(table => table.filter((el) => !!el.editing).forEach((el) => el.editing = null));
        //Set this cell in editing
        element.editing = col.field;

        this.oldElement = _.cloneDeep(element);

        this.onEditStart.emit({ column: col, element: element })
      }
    }
  }

  onCellFocus($event: any) {
    if (!!$event && !!$event.target)
      $event.target.select()
  }

  validateCell(e: any, element: any, col: Header): boolean {
    let error: any = null
    if (col.validation) {
      if (col.validation.required && !e)
        error = "Field required"
      else if (col.validation.maxChar !== undefined && e.length > col.validation.maxChar)
        error = "Max characters " + col.validation.maxChar
      else if (col.validation.minChar !== undefined && e.length < col.validation.minChar)
        error = "Min characters " + col.validation.minChar
      else
        error = null
    } else
      error = null

    let cellError = this.errors.find((err) => err.elementId === element.id && err.headerId === col.id)
    if (!!cellError)
      cellError.message = error
    else if (error !== null)
      this.errors.push({ elementId: element.id, headerId: col.id, message: error })

    return !error;
  }

  onCellExit(element: any, col: Header) {
    if (!element.newRow) {
      //this.selection.clear();
      this.onEditCell.emit({ record: element, oldRecord: this.oldElement, cel: col.field, editType: col.editType });
      //element.editing = undefined;
    }
  }

  onDatepickerClose(element: any, col: Header, e: any) {
    if (e.substring(0, 1) !== '0') {
      this.onCellExit(element, col)
    }
  }

  hasError(elementId: number, colId: number) {
    return this.errors.find((err) => err.elementId === elementId && err.headerId === colId)
  }

  getErrorMessage(elementId: number, colId: number) {
    return this.errors.find((err) => err.elementId === elementId && err.headerId === colId)?.message
  }

  isAdding() {
    let isAdding = false;
    this.dataSource.subscribe(table => {
      table.some((item) => item.newRow) ?? false;
    })
    return isAdding;
  }

  checkCellError(errors: any[], field: string) {
    let classes: any = {};

    errors?.some((error: any) => {
      if (error?.property === field) {
        classes.errorCell = true;
        return true;
      }
      return false;
    })
    return classes;
  }

  addBorder(element, field) {

    if (!!element.meta) {
      if (!!element.meta.remarks) {
        if ((!element.meta.remarks.every((remark) => (remark.column !== field) || remark.status !== 'ERROR') && !(field == 'destinationCid' || field == 'destinationCountry')))
          return 'error'
        else if (element.meta.remarks.every((remark) => remark.column === field && remark.status !== 'ERROR') && element.meta.remarks.every((remark) => remark.column === field && remark.status === 'INFO' && remark.action === 'AUTOCALC'))
          return 'info'
        else if (element.meta.remarks.every((remark) => remark.column === field && remark.status !== 'ERROR') && element.meta.remarks.every((remark) => remark.column === field && remark.status === 'WARNING' && remark.action === 'COMPARE'))
          return 'compare'
        else if (element.meta.remarks.every((remark) => remark.column === field && remark.status !== 'ERROR') && element.meta.remarks.some((remark) => remark.column === field && remark.status === 'WARNING'))
          return 'warning'
      }
    }
    return 'null';
  }

  addBorderRow(element, field) {
    if (!!element.meta) {
      if (!!element.meta.remarks) {
        if (element.meta.remarks.some((remark) => remark.column !== field && remark.action === 'COMPARE'))
          return 'compareRow'
        if (element.meta.remarks.some((remark) => remark.column !== field && remark.status === 'WARNING' && remark.action === 'FIND'))
          return 'newRow'
      }
    }
    return 'null';
  }

  logspaceFunction() {
    //return logspace(25, this.length, 2);
    return [50, 100, 250];
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = -1;
    this.dataSource.subscribe(table => numRows = table.length);
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(evt: any) {
    if (evt.checked) {
      this.dataSource.subscribe(table => {
        this.selection.select(...table);
      })
      //this.selection.select(...this.dataSource);
      return;
    } else {
      this.selection.clear();
      return;
    }
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  isCheckboxDisabled(row: any) {
    return false;
  }


  sortColumn(name: string) {

    let poolMap: Map<string, { order: number, type: 'asc' | 'desc' }> = new Map();
    this.sortedColumns.forEach((value, key, map) => {
      poolMap.set(key, value);
    });
    if (!!this.sortedColumns.get(name) && (this.sortedColumns.get(name).type == 'asc' || this.sortedColumns.get(name).type == 'desc')) {
      poolMap.delete(name);
      if (this.sortedColumns.get(name).type == 'asc') {
        const order = this.sortedColumns.get(name).order;
        this.sortedColumns = new Map();
        this.sortedColumns.set(name, { order: order, type: 'desc' });
        poolMap.forEach((value, key, map) => {
          this.sortedColumns.set(key, value);
        })
      } else {
        const order = this.sortedColumns.get(name).order;
        this.sortedColumns.delete(name);
        this.sortedColumns.forEach((value, key, map) => {
          this.sortedColumns.set(key, { order: value.order > order ? value.order - 1 : value.order, type: value.type });
        })
      }
    } else {
      if (!(!!this.sortedColumns.get(name))) {
        let maxIndex = 0;
        this.sortedColumns.forEach((value, key, map) => {
          maxIndex = maxIndex < value.order ? value.order : maxIndex;
        })
        this.sortedColumns.set(name, { order: maxIndex + 1, type: 'asc' });
      }
    }

    this.onSortChange.emit({ sorting: this.sortedColumns, pageEvent: this.pageEvent });
  }

  mergeToMonitor() {
    let selected = new Array();
    this.selection.selected.forEach((el) => {
      selected.push(Object.assign({}, el));
    })
    this.onMergeClick.emit(selected);
    this.selection = new SelectionModel<Element>(true, []);
  }

  emitCheck(evt: any) {
    /*if (!this.checkboxes.info && !this.checkboxes.warning && !this.checkboxes.error) {
      this.checkboxes = {
        info: true,
        warning: true,
        error: true
      }
      this.checkboxes[evt.source.name] = false;
    }*/
    this.onCheckboxClick.emit(this.checkboxes)
  }

  getCellToolTip(element: any, field: any) {
    return element[field].label;
  }

  typeof(x: any) {
    return typeof x;
  }

  filterInfoCounter = (obj) => !(!!obj.meta) || !(!!obj.meta.remarks) || obj.meta.remarks.every(remark => remark.status == 'INFO')

  dateFormatter(stringDate: any) {
    if (!!stringDate && typeof stringDate === 'string' && stringDate.includes('-')) {
      stringDate = stringDate.substring(8, 10) + "/" + stringDate.substring(5, 7) + "/" + stringDate.substring(0, 4);
    }
    return stringDate;
  }

  isDuplicated(element: any) {
    if (!!element && !!element.meta && !!element.meta.remarks) {
      const remarks = element.meta.remarks;
      if (!!remarks.find(remark => remark.column === 'container')) {
        return true;
      }
    }
    return false;
  }


  dateInit(date: any, id: any) {
    if (!!date) {
      date = date.toString();
      id = id.toString();
      if (date.includes('/')) {
        date = date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2);
        if ((<HTMLInputElement>document.getElementById(id)).value != null) {
          (<HTMLInputElement>document.getElementById(id)).value = date;
        }
      }
    }
  }

  isActionPresent(action: string) {
    if (this.permittedActions.indexOf(action) > -1)
      return true;
    else
      return false;
  }



}

export interface Header {
  id: number,
  label: string,
  field: string,
  editable?: boolean,
  actions?: any[],
  validation?: CellValidation,
  cellTextClass?: string,
  cellClass?: string,
  headerClass?: string,
  editType?: string,
  selectOptions?: any[],
  fix?: boolean
}

export interface CellValidation {
  required?: boolean;
  type?: "text" | "number" | "date"
  maxChar?: number;
  minChar?: number;
}

export interface CellError {
  elementId: number,
  headerId: number,
  message?: string,
}
