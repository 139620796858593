import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { flatMap } from 'lodash';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { BehaviorSubject, Observable, ReplaySubject, Subject, from, merge, combineLatest, of, mergeMap, map } from 'rxjs';
import { environment } from 'src/environments/environment';


export { User };

@Injectable()
export class AuthService {
  private _userManager: UserManager;
  _user: User

  _loginChangedSubject = new Subject<boolean>();

  loginChanged = this._loginChangedSubject.asObservable();

  _actionSubject = new Subject<String[]>();
  public _actionObs = this._actionSubject.asObservable();

  constructor(private http: HttpClient, private router: Router,) {
    const settings = {
      authority: environment.stsAuthority,
      client_id: environment.clientId,
      redirect_uri: `${window.location.origin}/oem`,
      silent_redirect_uri: `${window.location.origin}/oem`,
      post_logout_redirect_uri: `${window.location.origin}/oem/signout-callback`,
      response_type: 'id_token',
      scope: environment.clientScope,
      automaticSilentRenew: true
    };

    this._userManager = new UserManager(settings);
    this._userManager.events.addAccessTokenExpired(_ => {
      this._loginChangedSubject.next(false);
    });

    this._userManager.events.addUserLoaded(user => {
      if (this._user !== user) {
        this._user = user;
        this._loginChangedSubject.next(!!user);
      }
    });
  }

  getUser() {
    return this._userManager.getUser();
  }


  login() {
    return this._userManager.signinRedirect();
  }

  isLoggedIn(): Promise<boolean> {
    return this._userManager.getUser().then(user => {
      if (!!user) {
        const userCurrent = !!user //&& user.profile.exp > Date.now();
        if (this._user !== user) {
          this._loginChangedSubject.next(userCurrent);
        }
        this._user = user;
        return userCurrent;
      } else {
        return false;
      }
    });
  }

  completeLogin() {

    this._userManager.startSilentRenew();

    return this._userManager.signinRedirectCallback().then(user => {
      this._user = user;
      this._loginChangedSubject.next(!!user && user.profile.exp > Date.now());
      return user;
    });

  }

  checkLoggedIn() {
    return this._userManager.signinSilentCallback().then(usr => {
      this._user = usr;
      this._loginChangedSubject.next(!!usr && usr.profile.exp > Date.now());
      return usr;
    })
  }

  logout() {
    this._userManager.signoutRedirect();
  }

  completeLogout() {
    this._userManager.stopSilentRenew()
    this._user = null;
    this._loginChangedSubject.next(false);
    return this._userManager.signoutRedirectCallback();
  }

  getAccessToken() {
    return this._userManager.getUser().then(user => {
      if (!!user.access_token)
        return user.access_token;
      return user.id_token;
    });
  }

  getIdToken() {
    return this._userManager.getUser().then(user => {
      if (!!user)
        return user.id_token;
      return null;
    });
  }

  getUserPicture() {
    return this._userManager.getUser().then(user => {
      return user.profile.given_name.slice(0, 1) + user.profile.family_name.slice(0, 1);
    });
  }

  renewToken(): Promise<User> {
    return this._userManager.signinSilent();
  }

  getAction() {
    return from(this._userManager.getUser()).pipe(
      mergeMap(user => {
        const requestBody = {
          email: user.profile.email
        }
        return this.http.post<any[]>(environment.apiUrl + 'oemcc_actions', requestBody, {});
      })
    )
  }

  getRole() {
    return from(this._userManager.getUser()).pipe(
      mergeMap(user => {
        const requestBody = {
          email: user.profile.email
        }
        return this.http.post<any[]>(environment.apiUrl + 'oemcc_roles', requestBody, {});
      })
    )
  }

  getRoles() {
    return from(this._userManager.getUser()).pipe(
      mergeMap(user => {
        let httpParam: HttpParams = new HttpParams().set('email', user.profile.email)
        return this.http.post<any>(environment.apiUrl + 'roles',  {}, { params: httpParam });
      })
    )
  }

  setRole(role: string) {
    return from(this._userManager.getUser()).pipe(
      mergeMap(user => {
        let httpParam: HttpParams = new HttpParams().set('email', user.profile.email).set('role', role)
        return this.http.post<any>(environment.apiUrl + 'roles/set', {},  { params: httpParam });
      })
    )
  }

  getHeader(screen: string) {
    return from(this._userManager.getUser()).pipe(
      mergeMap(user => {
        const requestBody = {
          table: screen,
          //role: this._role,
          email: user.profile.email
        }
        return this.http.post<any[]>(environment.apiUrl + 'oemcc_view', requestBody, {});
      })
    );
  }

  getTableSetDetail(tableSet: string) {
    const params = new HttpParams().set('tableSet', tableSet);
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_table_set_detail', { params });
  }

  getHeaderImport(screen: string) {
    return from(this._userManager.getUser()).pipe(
      mergeMap(user => {
        const requestBody = {
          table: screen,
          email: user.profile.email
        }
        return this.http.post<any[]>(environment.apiUrl + 'oemcc_view_import', requestBody, {});
      })
    )
  }

  getUserAction() {
    this.getAction().subscribe(res => {
      this._actionSubject.next(res['actions']);
    }, err => {
      this.router.navigate(['/help']);
    })
  }

  getGrantor() {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_grantor');
  }

  getGrantee() {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_grantee');
  }

  getGrants() {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_grants');
  }

  getUsersToDelegate() {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_delegate/users');
  }

  expireDelegate(delegateId: number) {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_grantor/' + delegateId);
  }

  adminExpireDelegate(delegateId: number) {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_delegate/' + delegateId);
  }

  newDelegate(delegation: {
    granteeUserId: string,
    expirationDate: string
  }) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_delegate', delegation, {});
  }

  newAdminDelegate(delegation: {
    grantorUserId: string,
    granteeUserId: string,
    expirationDate: string
  }) {
    return this.http.post<any>(environment.apiUrl + 'oemcc_delegate/admin', delegation, {});
  }

  getUserContext() {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_context');
  }

  getUserInfo() {
    return this.http.get<UserInfo>(environment.apiUrl + 'user/signature');
  }

  saveUserSignature(htmlContent: string) {
    return this.http.post<UserInfo>(environment.apiUrl + 'user/signature', htmlContent, {});
  }

  getUserList() {
    return this.http.get<any[]>(environment.apiUrl + 'oemcc_delegate/users');
  }

}

export interface UserInfo {
  userId: string,
  userEmail: string,
  userUuid?: string,
  userDefaultRole: string,
  userSignature?: string
}
