<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">Master Data - </label> Raw Destinations</label>

  <button class="action-button" mat-raised-button color="primary" matTooltip="Refresh" action-type="button"
    (click)="refreshRawDestinations(0, (pageEvent.pageIndex+1)*pageEvent.loader, sortedColumns, filterValues)">Refresh</button>
  <button *ngIf="isActionPresent('rawDestination.insert')" class="action-button" mat-raised-button color="primary"
    matTooltip="Add a new row destination" action-type="button" (click)="newRawDestination()"
    [disabled]="newRawDestinationAlreadyExists()">New Raw
    Destination</button>
</app-action-bar>

<div class="alert-container">
  <alert-component #alertMessageMonitor></alert-component>
</div>

<div class="datatable-container ph-16">
  <div class="datatable-content">
    <app-filter [filterObj]="filterObjs" (applyFilterEv)="onApplyFilter($event, 0)"></app-filter>
    <app-data-table class="datatable-content" [headers]="rawDestinationsHeaders" [length]="rawDestinationsLength"
      [loadingTable]="loading" [numElementsLoaded]="(rawDestinationsDataSourceObservable | async)?.length"
      [dataSource]="rawDestinationsDataSourceObservable" (onEditStart)="onRawDestinationEditStart($event)"
      (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumns" [pageEvent]="pageEvent"
      [numElementsLoaded]="(rawDestinationsDataSourceObservable | async)?.length"
      (onPaginationChange)="onPageChange($event)" (onEditCell)="onEditRawDestination($event, true)"
      (onActionClick)="onActionRawDestination($event)" [screen]="screen" #childDataTable>
    </app-data-table>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white; font-size: 1.5rem" > Loading... </p></ngx-spinner>
