import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import {RegexParser} from "../utils/generic";

@Pipe({
  name: 'validateRegex'
})
export class ValidateRegexPipe implements PipeTransform {

  transform(value: any, regex?: string): boolean {
    if(regex){
      if(value){
        return this.regexParse(regex).test('' + value)
      }else{
        return this.regexParse(regex).test('' )
      }
    }
    return false;

  }

  regexParse(regex?: string){
    if(regex){
      return RegexParser(regex)
    }
    return undefined
  }

}
