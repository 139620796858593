<div class="container">
  <section>
    <h1 mat-dialog-title>Delegate to</h1>
    <div mat-dialog-content>
      <mat-form-field appearance="fill" *ngIf="data == 'admin'">
        <mat-label>Select Grantor</mat-label>
        <mat-select [(ngModel)]="selectedGrantor">
          <mat-option *ngFor="let grantor of grantors" [value]="grantor">
            {{grantor}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Select Grantee</mat-label>
        <mat-select [(ngModel)]="selectedGrantee">
          <mat-option *ngFor="let grantee of grantees" [value]="grantee">
            {{grantee}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Expiration Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="expDate">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

    </div>
    <div mat-dialog-actions align="end">
      <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
      <button mat-stroked-button (click)="data == 'admin' ? confirmAdminDelegate() : confirmDelegate()">Confirm</button>
    </div>
  </section>
</div>
