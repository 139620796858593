<!--<div *ngIf="!!(detailsDataObs | async)">

    <admin-data-table [headers]="detailHeaders" [dataSource]="detailsDataObs" [length]="detailsDataLength"
        [numElementsLoaded]="(detailsDataObs | async)?.length" (onEditCell)="onEditDetail($event)"
        (onActionClick)="onActionDetail($event)" (onExpandEmitter)="onExpandEmitter($event)" #childDataTable>
    </admin-data-table>
</div>-->

<button *ngIf="isActionPresent('monitor.detail.insert') && screen != 'monitor-su'" class="new-detai-btn" mat-flat-button
    matTooltip="Add a new detail" (click)="newDetail()" [disabled]="newDetailAlreadyExists()" color="primary">New
    Detail
</button>

<div class="alert-container">
    <alert-component #alertMessageMonitor></alert-component>
</div>

<table datatable class="row-border hover">
    <thead>
        <tr>
            <th *ngFor="let header of detailHeaders | async">{{header.label}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of detailsDataObs | async">
            <!-- TEST -->
            <td *ngFor="let header of detailHeaders | async" class="relative" [class.editableCell]="header.editable"
                (click)="onClickCell(row, header)">

                <!-- STANDARD VIEW -->
                <div *ngIf="(!header.editable || (header.editable && row.editing !== header.field))">
                    <!-- NORMAL CELL -->
                    <span
                        *ngIf="header.field !== 'actions' && header.editType !== 'datePicker' && header.editType !== 'check' && !row.newRow">
                        {{row[header.field] != null ? row[header.field].value : null}}
                    </span>

                    <span *ngIf="!row.newRow && header.field !== 'actions' && header.editType === 'datePicker'">
                        {{row[header.field] != null ? row[header.field].value : null}}
                    </span>

                    <div *ngIf="screen != 'monitor-su'" class="center">
                        <span *ngIf="!row.newRow && header.field !== 'actions' && header.editType === 'check'"
                            (click)="onCheckClick(row, header, header.field)">
                            <mat-icon *ngIf="row[header.field].value == 'Y'">check_box</mat-icon>
                            <mat-icon *ngIf="row[header.field].value !== 'Y'">check_box_outline_blank</mat-icon>
                        </span>
                    </div>

                    <div *ngIf="screen == 'monitor-su'" class="center">
                        <span *ngIf="!row.newRow && header.field !== 'actions' && header.editType === 'check'">
                            <mat-icon *ngIf="row[header.field].value == 'Y'">check_box</mat-icon>
                            <mat-icon *ngIf="row[header.field].value !== 'Y'">check_box_outline_blank</mat-icon>
                        </span>
                    </div>

                    <!--ACTIONS CELL (EDITING) -->
                    <div class="center">
                        <span *ngIf=" header.field==='actions' && !row.newRow">
                            <ng-template *ngFor="let action of header.actions" [ngIf]="(action==='delete')">
                                <button mat-icon-button
                                    [disabled]="!isActionPresent('monitor.detail'+'.delete') || screen == 'monitor-su'"
                                    color="primary" class="table-button" (click)="onAction(row, action)">
                                    <mat-icon>{{action}}</mat-icon>
                                </button>
                            </ng-template>
                        </span>
                    </div>

                    <!-- ACTIONS CELL (NEW ROW) -->
                    <div class="center">
                        <div *ngIf="header.field === 'actions' && row.newRow">
                            <button mat-icon-button color="primary" (click)="onAction(row, 'create')">
                                <mat-icon>check</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="onAction(row, 'cancel')">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- EDITING VIEW -->
                <div *ngIf="header.editable && row.editing === header.field && !row.newRow && screen != 'monitor-su'">
                    <!-- STANDARD INPUT -->
                    <mat-form-field
                        *ngIf="(!header.editType || header.editType === 'input') && header.field !== 'container' && header.field !== 'actions'"
                        (click)="$event.stopPropagation()" class="full-width">
                        <input [id]="row.id.value+'-'+header.id" matInput [(ngModel)]="row[header.field].value"
                            (focusout)="onCellExit(row, header)">
                    </mat-form-field>

                    <!-- NUMERIC INPUT -->
                    <mat-form-field *ngIf="(!header.editType || header.editType === 'number')"
                        (click)="$event.stopPropagation()" class="full-width">
                        <input type="number" [id]="row.id.value+'-'+header.id" matInput
                            [(ngModel)]="row[header.field].value" (focusout)="onCellExit(row, header)">
                    </mat-form-field>

                    <div class="center">
                        <span *ngIf="!row.newRow && header.field !== 'actions' && header.editType === 'check'"
                            (click)="onCheckClick(row, header, header.field)">
                            <mat-icon *ngIf="row[header.field].value == 'Y'">check_box</mat-icon>
                            <mat-icon *ngIf="row[header.field].value !== 'Y'">check_box_outline_blank</mat-icon>
                        </span>
                    </div>

                    <!-- SELECT INPUT TO MONITOR AND PREVIEW -->
                    <mat-form-field *ngIf="header.editType && header.editType === 'select'"
                        (click)="$event.stopPropagation()">
                        <mat-select [id]="row.id.value+'-'+header.id" [(ngModel)]="row[header.field].value"
                            (selectionChange)="onCellExit(row, header)">
                            <mat-option *ngFor="let option of header.selectOptions ?? []" [value]="option">{{option}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- DATEPICKER INPUT -->
                    <div *ngIf="header.editType && header.editType === 'datePicker'"
                        (click)="$event.stopPropagation(); ">
                        <input class="picker" [nbDatepicker]="datepicker" [id]="row.id.value+'-'+header.id"
                            [ngModel]="row[header.field].value | stringToDate | async"
                            (ngModelChange)="dateModelChange(row.id.value+'-'+header.id, $event)"
                            [class.errorText]="!checkDateById(row.id.value+'-'+header.id)"
                            [matTooltip]="!checkDateById(row.id.value+'-'+header.id) ? 'Format must be DD/MM/YYYY' : ''"
                            (change)="onDateCellExit($event, row, header)" #dateInput>
                        <span class="t-1" *ngIf="inputExists(row.id.value+'-'+header.id)" nbButton
                            (click)="clearInput(row.id.value+'-'+header.id, row, header);">
                            <mat-icon>event_busy</mat-icon>
                        </span>
                        <nb-datepicker #datepicker (dateChange)="onDatepickerClose($event, row, header);"
                            format="dd/MM/yyyy">
                        </nb-datepicker>
                    </div>

                    <!-- TIMEPICKER INPUT -->
                    <mat-form-field *ngIf="header.editType && header.editType === 'time'" class="picker"
                        (click)="$event.stopPropagation()">
                        <mat-label>Choose a date</mat-label>
                        <input matInput type="time" [id]="row.id.value+'-'+header.id"
                            [(ngModel)]="row[header.field].value">
                    </mat-form-field>
                </div>

                <!-- NEW ROW VIEW -->
                <div *ngIf="row.newRow && header.field !== 'id' && header.field !== 'actions'">
                    <!-- STANDARD INPUT -->
                    <mat-form-field *ngIf="!header.editType || header.editType === 'input'"
                        (click)="$event.stopPropagation()" class="full-width">
                        <input [id]="row.id.value+'-'+header.id" matInput [(ngModel)]="row[header.field].value"
                            [autofocus]="true" (focus)="onCellFocus($event)" (focusout)="onCellExit(row, header)">
                    </mat-form-field>

                  <!-- NUMERIC INPUT -->
                  <mat-form-field *ngIf="(!header.editType || header.editType === 'number')"
                                  (click)="$event.stopPropagation()" class="full-width">
                    <input type="number" [id]="row.id.value+'-'+header.id" matInput
                           [(ngModel)]="row[header.field].value" (focus)="onCellFocus($event)" (focusout)="onCellExit(row, header)">
                  </mat-form-field>

                    <!-- CHECKBOX INPUT -->
                    <div class="center">
                        <span *ngIf="header.field !== 'actions' && header.editType === 'check'"
                            (click)="onCheckClick(row, header, header.field)">
                            <mat-icon *ngIf="row[header.field].value == 'Y'">check_box</mat-icon>
                            <mat-icon *ngIf="row[header.field].value !== 'Y'">check_box_outline_blank</mat-icon>
                        </span>
                    </div>

                    <!-- DATEPICKER INPUT -->
                    <div *ngIf="header.editType && header.editType === 'datePicker'"
                        (click)="$event.stopPropagation(); ">
                        <input class="picker" [nbDatepicker]="datepicker" [id]="row.id.value+'-'+header.id"
                            [ngModel]="row[header.field].value | stringToDate | async"
                            (ngModelChange)="dateModelChange(row.id.value+'-'+header.id, $event)"
                            [class.errorText]="!checkDateById(row.id.value+'-'+header.id)"
                            [matTooltip]="!checkDateById(row.id.value+'-'+header.id) ? 'Format must be DD/MM/YYYY' : ''"
                            (change)="onDateCellExit($event, row, header)" #dateInput>
                        <span class="t-1" *ngIf="inputExists(row.id.value+'-'+header.id)" nbButton
                            (click)="clearInput(row.id.value+'-'+header.id, row, header);">
                            <mat-icon>event_busy</mat-icon>
                        </span>
                        <nb-datepicker #datepicker (dateChange)="onDatepickerClose($event, row, header);"
                            format="dd/MM/yyyy">
                        </nb-datepicker>
                    </div>

                    <!-- SELECT INPUT -->
                    <mat-form-field *ngIf="header.editType && header.editType === 'select'"
                        (click)="$event.stopPropagation()">
                        <mat-select [id]="row.id.value+'-'+header.id" [(ngModel)]="row[header.field].value">
                            <mat-option *ngFor="let option of header.selectOptions ?? []" [value]="option.value">
                                {{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- SELECT MP INPUT -->
                    <mat-form-field *ngIf="header.editType && header.editType === 'selectMP'">
                        <mat-select [id]="row.id.value+'-'+header.id" [(ngModel)]="row[header.field].value">
                            <mat-option *ngFor="let option of header.selectOptions ?? []" [value]="option.value">
                                {{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                  <!-- ACTIONS CELL (NEW ROW) -->
                  <div class="center">
                    <div *ngIf="header.field === 'actions' && row.newRow">
                      <button mat-icon-button color="primary" (click)="onAction(row, 'create')">
                        <mat-icon>check</mat-icon>
                      </button>
                      <button mat-icon-button color="primary" (click)="onAction(row, 'cancel')">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

            </td>
            <!-- <td *ngFor="let header of detailHeaders | async">{{row[header.field].value}}</td> -->
        </tr>
    </tbody>
</table>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="square-jelly-box" [fullScreen]="true"
    [name]="'detail_table'">
    <p style="color: white; font-size: 1.5rem"> Loading... </p>
</ngx-spinner>
