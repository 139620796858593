<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">OEMCC - </label> Monitor ADM</label>

</app-action-bar>

<div class="alert-container">
  <alert-component #alertMessageMonitor></alert-component>
</div>

<div class="datatable-container ph-16">
  <div class="datatable-content">

    <app-filter *ngIf="!isChanged" [filterObj]="filterObjMonitor" (applyFilterEv)="onApplyFilter($event)">
    </app-filter>

    <div class="datatable-container" #dataTableContainer>
      <app-static-data-table *ngIf="showTableObs | async" class="datatable-content" [headers]="monitorHeaders"
        [length]="monitorLength" [loadingTable]="loading" [pageEvent]="pageEvent"
        [dataSource]="monitorDataSourceObservable" [numElementsLoaded]="(monitorDataSourceObservable | async)?.length"
        [dataSourceObservable]="monitorDataSourceObservable" (onPaginationChange)="onPageChange($event)"
        (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumnsMonitor" [showMerge]="false"
        (onDeleteRecordClick)="onDeleteRecordClick($event)" (searchForDuplicate)="searchForDuplicate($event)"
        (onReopenRecordsClick)="onReopenRecordsClick($event)" (searchForDeleting)="searchForDeleting($event)"
        [screen]="'monitor-su'" (onDetailChangeEmit)="onDetailChange($event)" #childDataTableMonitor>
      </app-static-data-table>
    </div>

    <div *ngIf="!(showTableObs | async) && !isChanged" class="alert2-container alert2-success mt-16">
      <div class="alert2-content">
        <div class="alert2-detail">
          <label class="alert2-description">Please close filter panel, or riduce page zoom to view the table.</label>
        </div>
      </div>
    </div>

  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white; font-size: 1.5rem"> Loading... </p>
</ngx-spinner>


<!--<div class="mt-16 ph-16" *ngIf="isChanged">

  <app-data-table [isFilter]="!isChanged" [headers]="monitorHeadersImport"
    [length]="(monitorImportDataSourceObservable | async)?.length" [loadingTable]="loading" [pageEvent]="pageEvent"
    [dataSource]="monitorImportDataSourceObservable"
    [numElementsLoaded]="(monitorImportDataSourceObservable | async)?.length"
    [dataSourceObservable]="monitorImportDataSourceObservable" (onPaginationChange)="onPageChange($event)"
    (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumnsMonitor" [showMerge]="false"
    (onActionClick)="onActionMonitor($event)" (onCloseRecordsClick)="onCloseRecordsClick($event)" [screen]="'import'"
    #childDataTableMonitorImport>
  </app-data-table>
</div>-->
