import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { AlertType } from 'src/app/shared/components/alert/alert.component';
import { Header } from 'src/app/shared/components/data-table/data-table.component';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { PageEventType } from 'src/app/shared/components/paginator/paginator.component';
import { replacer, reviver } from 'src/app/shared/utils/generic';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent implements OnInit {

  @ViewChild('childDataTable') childDataTable;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  unitsHeaders: Observable<Header[]> = of([
    { id: 0, field: 'id', label: 'ID', editable: false, headerClass: "w-1-20", cellTextClass: "bold" },
    { id: 1, field: 'name', label: 'Name', editable: true, headerClass: "w-4-20" },
    { id: 2, field: 'description', label: 'Description', editable: true },
    { id: 3, field: 'actions', label: 'Actions', editable: false, headerClass: "w-1-20", actions: ["delete_forever"] },
  ]);

  unitsDataSourceSubject = new BehaviorSubject<any[]>([]);
  unitsDataSourceObservable = this.unitsDataSourceSubject.asObservable();
  unitsDataSource: any[] = [];
  unitsLength = 0;

  loading: boolean = false;
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
  sortedColumns: Map<string, { order: number, type: 'asc' | 'desc' }>;

  subscriptionArray: Subscription[] = [];

  actions: string[] = [];

  screen: string = 'unit'

  constructor(
    private masterDataService: MasterDataService,
    public dialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([['name', { order: 1, type: 'asc' }]]);
  }

  ngOnInit(): void {
    this.sortedColumns = new Map<string, { order: number, type: 'asc' | 'desc' }>([['name', { order: 1, type: 'asc' }]]);

    const backupSorting = JSON.parse(sessionStorage.getItem('backup.sorting.units'), reviver);
    if (!!backupSorting) {
      this.sortedColumns = backupSorting;
    }

    this.getUnits(0, this.pageEvent.loader, this.sortedColumns);

    this.subscriptionArray.push(
      this.authService.getAction().subscribe(actions => {
        this.actions = actions['actions'];
        this.subscriptionArray.forEach(sub => sub.unsubscribe());
    }));
  }

  onSortChange(pageSorting: { sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }): void {
    this.sortedColumns = pageSorting.sorting;
    sessionStorage.setItem('backup.sorting.monitor', JSON.stringify(pageSorting.sorting, replacer));
    this.refreshUnits(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, pageSorting.sorting);
  }


  onPageChange(pageEvent: PageEventType, tab: number): void {
    this.pageEvent = pageEvent;

    this.getUnits(pageEvent.pageIndex, pageEvent.loader, this.sortedColumns)
  }

  hasSubArray(master: any[], sub: any[]) {
    /*if (sub.every((i => v => i = master.indexOf(v, i) + 1)(0))) {
      return master;
    }
    return master.concat(sub);*/
    sub.forEach(elem => {
      const index = master.findIndex(e => e.id === elem.id);
      if (index !== -1) {
        master[index] = elem;
      } else {
        master.push(elem);
      }
    })
    return master;
  }

  getUnits(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>) {
    this.loading = true;
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })

    this.masterDataService.getUnits(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.unitsDataSource = this.hasSubArray(this.unitsDataSource, res.content);
            this.unitsLength = res.totalElements;
            this.unitsDataSourceSubject.next(this.unitsDataSource);
          }
          //loadingEvent.close();
this.spinner.hide();
        },
        (err: any) => {
          //loadingEvent.close();
this.spinner.hide();
          this.loading = false;
        },
        () => this.loading = false)
  }

  refreshUnits(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>) {
    this.loading = true;
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    this.masterDataService.getUnits(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.unitsDataSource = []
            this.unitsDataSource = this.hasSubArray(this.unitsDataSource, res.content);
            this.unitsLength = res.totalElements;
            this.unitsDataSourceSubject.next(this.unitsDataSource);
          }
          //loadingEvent.close();
this.spinner.hide();
        },
        (err: any) => {
          //loadingEvent.close();
this.spinner.hide();
          this.loading = false;
        },
        () => this.loading = false)
  }

  onEditUnit(data: any, reload = false) {
    if (!!data.record && !!data.oldRecord && data.record[data.cel].value === data.oldRecord[data.cel].value) return;
    let element;
    if (!!data.record) {
      delete data.editing;
      element = data.record;
    } else
      element = data;
    this.loading = reload;
    this.masterDataService.editUnits(element)
      .subscribe(
        (res: any) => {
          if (reload) {
            this.refreshUnits(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns);
          }

        },
        (err: any) => {
          this.refreshUnits(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns);
          if (err.status == 403)
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
          else
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Edit error", "An error occurred during editing.", 10);
          this.loading = false;
        },
        () => this.loading = false)
  }

  onActionUnit(row: any) {
    switch (row.action) {
      case "delete_forever":
        const dialogRef = this.dialog.open(DialogComponent, {
          // width: '250px',
          data: {
            title: "Confirm deletion",
            content: `This action will delete the unit "${row.element.name.value}". Proceed?`,
            cancelButtonLabel: "Cancel",
            cancelButtonColor: "basic",
            confirmButtonLabel: "Delete",
            confirmButtonColor: "warn"
          }
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (!!res) {
            this.masterDataService.deleteUnit(row.element.id.value)
              .subscribe(
                (res: any) => {
                  this.refreshUnits(0, (this.pageEvent.pageIndex + 1) * this.pageEvent.loader, this.sortedColumns);
                },
                (err: any) => {
                  this.loading = false;
                },
                () => this.loading = false)
          }
        });

        break
      case "cancelNew":
        this.unitsDataSource = this.unitsDataSource.slice(1);
        this.unitsDataSourceSubject.next(this.unitsDataSource);
        break;
      case "create":
        delete row.element.newRow;
        this.onEditUnit(row.element, true);

        break;
    }
  }

  newUnit() {
    this.unitsDataSource = [{
      id: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      name: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      description: {
        value: '',
        errorType: '',
        errorList: [],
        errorAction: ''
      },
      newRow: true
    }, ...this.unitsDataSource];

    this.unitsDataSourceSubject.next(this.unitsDataSource);
  }

  newUnitAlreadyExists() {
    return this.unitsDataSource.some((unit) => unit.newRow)
  }

  isActionPresent(action: string) {
    if(this.actions.indexOf(action) > -1)
     return true;
    else
      return false
  }
}

