<section>

  <h2 mat-dialog-title>{{data.title}}
    <span *ngIf="!fullBtn" class="right" (click)="openFull()">
      <mat-icon>fullscreen</mat-icon>
    </span>
    <span *ngIf="fullBtn" class="right" (click)="exitFull()">
      <mat-icon>fullscreen_exit</mat-icon>
    </span>
  </h2>
  <div class="alert">
    <alert-component #alertMessageMonitor></alert-component>
  </div>
  <div mat-dialog-content [ngClass]="{'default-height': !fullBtn, 'max-height': fullBtn}">
    <mat-accordion multi>
      <ng-container *ngFor="let dataSource of dataSourceArr; let i= index">

        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="status-text-error" *ngIf="!!sendingMailList[i] && sendingMailList[i].length == 0">* Preview
                Mail {{i+1}}</span>
              <span *ngIf="!!sendingMailList[i] && sendingMailList[i].length > 0">Preview Mail {{i+1}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <b>Subject:</b>
          <mat-form-field class="full-width" appearance="fill">
            <textarea matInput [(ngModel)]="htmlSubjectContent[i]"
              placeholder="Write your custom subject..."></textarea>
          </mat-form-field>

          <br />

          <b>Mail body:</b>
          <angular-editor [(ngModel)]="htmlContent[i]" [config]="htmlConfig.get(i)"></angular-editor>

          <br />
          Notify To:
          <mat-form-field appearance="fill" class="mt-16">
            <mat-label>Contact Group</mat-label>
            <mat-select [(value)]="selectedFields[i]" (selectionChange)="checkSelects($event, i)">
              <mat-option *ngFor=" let contactGroup of contactGroups" [value]="contactGroup">
                {{contactGroup}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="mail-section">
            <div class="mail-to">
              <mat-list>
                <span class="status-text-error" *ngIf="!!sendingMailList[i] && sendingMailList[i].length == 0">* Emails
                  To: (One email is
                  required)</span>
                <span *ngIf="!!sendingMailList[i] && sendingMailList[i].length > 0">Emails To: </span>
                <mat-list-item *ngFor="let email of sendTo[i]">
                  <mat-icon class="delete-icon" (click)="deleteEmail(email, i, 'sendTo')">delete</mat-icon> {{email}}
                </mat-list-item>
                <mat-list-item *ngFor="let email of manualSendTo[i]">
                  <mat-icon class="delete-icon" (click)="deleteEmail(email, i, 'manualSendTo')">delete</mat-icon>
                  {{email}}
                </mat-list-item>
              </mat-list>

              <div>
                <mat-form-field appearance="fill" class="new-mail-input">
                  <mat-label>New To Email</mat-label>
                  <input matInput [(ngModel)]='newMail' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                </mat-form-field>
                <button mat-stroked-button (click)="done(i)">
                  <mat-icon>person_add</mat-icon>
                </button>
              </div>
            </div>
            <div class="mail-cc">
              <mat-list>
                <span>Emails Cc: </span>
                <mat-list-item *ngFor="let email of sendCc[i]">
                  <mat-icon class="delete-icon" (click)="deleteEmail(email, i, 'sendCc')">delete</mat-icon> {{email}}
                </mat-list-item>
                <mat-list-item *ngFor="let email of manualSendCc[i]">
                  <mat-icon class="delete-icon" (click)="deleteEmail(email, i, 'manualSendCc')">delete</mat-icon>
                  {{email}}
                </mat-list-item>
              </mat-list>

              <div>
                <mat-form-field appearance="fill" class="new-mail-input">
                  <mat-label>New Cc Email</mat-label>
                  <input matInput [(ngModel)]='newMailCc' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                </mat-form-field>
                <button mat-stroked-button (click)="done(i, true)">
                  <mat-icon>person_add</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="attachment-section">
            <div class="attachment-list">
              <mat-list>
                <span>Your attachments: </span>
                <mat-list-item *ngFor="let attachment of sendAttachment[i]">
                  <mat-icon mat-list-icon class="delete-file-icon"
                    (click)="deleteEmail(attachment.name, i, 'sendAttachment')" matTooltip="Remove file from list"
                    matTooltipClass="tooltip-red">
                    link_off</mat-icon>
                  <div mat-line>{{attachment.name}}</div>
                  <div mat-line> {{ formatBytes(attachment.size) }} </div>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
          <br />
          <button mat-stroked-button (click)="attachFile(i)">
            <mat-icon>attach_file</mat-icon>
          </button>
          <button mat-raised-button color="primary" (click)="sendNotify(i)"
            class="right">{{data.confirmButtonLabel}}</button>
        </mat-expansion-panel>

        <br />
      </ng-container>
    </mat-accordion>

  </div>

  <div class="spacer"></div>

  <div mat-dialog-actions align="end" mat-elevation-z8>
    <button mat-button [mat-dialog-close]="false">{{data.closeButtonLabel}}</button>

  </div>
</section>