import { Pipe, PipeTransform } from '@angular/core';
import {FileContext} from "../models/FileContext";
import {Observable, of} from "rxjs";

@Pipe({
  name: 'containStatus'
})
export class ContainStatusPipe implements PipeTransform {

  transform(values: unknown[], field: string, type: string, toCompare: string): Observable<boolean> {
    if(type == 'in'){
      console.log(values.some(elem => !!elem && !!elem[field] && !!elem[field].value && elem[field].value == toCompare ))
      return of(values.some(elem => !!elem && !!elem[field] && !!elem[field].value && elem[field].value == toCompare ));
    }else if (type == 'not in'){
      console.log(values.some(elem => !!elem && !!elem[field] && !!elem[field].value && elem[field].value != toCompare ))
      return of(!values.some(elem => !!elem && !!elem[field] && !!elem[field].value && elem[field].value != toCompare ))
    }
    return of(false);
  }

}
