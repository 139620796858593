<app-action-bar>
  <label class="page-title" page-title><label class="page-subtitle">Master Data - </label> Destinations</label>
  <button mat-flat-button action-type="button" class="action-button" matTooltip="Refresh"
    (click)="refreshDestinations(0, (pageEvent.pageIndex+1)*pageEvent.loader, sortedColumns, filterValues)"
    color="primary">Refresh</button>
  <button *ngIf="isActionPresent('destination.insert')" mat-flat-button action-type="button" class="action-button"
    matTooltip="Add a new destination" (click)="newDestination()" [disabled]="newDestinationAlreadyExists()"
    color="primary">New
    Destination</button>
</app-action-bar>

<div class="alert-container">
  <alert-component #alertMessageMonitor></alert-component>
</div>

<div class="datatable-container ph-16">
  <div class="datatable-content">
    <app-filter [filterObj]="filterObjs" (applyFilterEv)="onApplyFilter($event, 0)"></app-filter>
    <app-data-table class="datatable-content" [headers]="destinationsHeaders" [length]="destinationsLength"
      [loadingTable]="loading" [numElementsLoaded]="(destinationsDataSourceObservable | async)?.length"
      [dataSource]="destinationsDataSourceObservable" (onPaginationChange)="onPageChange($event, 0)"
      (onSortChange)="onSortChange($event)" [sortedColumns]="sortedColumns"
      (onEditCell)="onEditDestination($event, true)" (onActionClick)="onActionDestination($event)" [screen]="screen"
      #childDataTable>
    </app-data-table>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white; font-size: 1.5rem" > Loading... </p></ngx-spinner>
