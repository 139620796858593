<section>

  <h2 mat-dialog-title>{{data.title}} <span class="status-text-error" *ngIf="sendingMailList.length == 0">* Reply
      Mail</span>
    <span *ngIf="sendingMailList.length > 0">Reply Mail</span>
    <span *ngIf="!fullBtn" class="right" (click)="openFull()">
      <mat-icon>fullscreen</mat-icon>
    </span>
    <span *ngIf="fullBtn" class="right" (click)="exitFull()">
      <mat-icon>fullscreen_exit</mat-icon>
    </span>
  </h2>
  <div class="alert">
    <alert-component #alertMessageMonitor></alert-component>
  </div>
  <div mat-dialog-content [ngClass]="{'default-height': !fullBtn, 'max-height': fullBtn}">

    <b>Subject:</b>
    <mat-form-field class="full-width" appearance="fill">
      <textarea matInput [(ngModel)]="htmlSubjectContent" placeholder="Write your custom subject..."></textarea>
    </mat-form-field>

    <b>Mail body:</b>
    <angular-editor [(ngModel)]="htmlContent" [config]="htmlConfig"></angular-editor>


    <br />
    <div class="mail-section">
      <div class="mail-to">
        <mat-list>
          <span class="status-text-error" *ngIf="sendingMailList.length == 0">* Emails To: (One email is
            required)</span>
          <span *ngIf="sendingMailList.length > 0">Emails To: </span>
          <mat-list-item *ngFor="let email of sendingMailList">
            <mat-icon class="delete-icon" (click)="deleteEmail(email, 'sendTo')">delete</mat-icon> {{email}}
          </mat-list-item>
        </mat-list>

        <div>
          <mat-form-field appearance="fill" class="new-mail-input">
            <mat-label>New To Email</mat-label>
            <input matInput [(ngModel)]='newMail' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
          </mat-form-field>
          <button mat-stroked-button (click)="done()">
            <mat-icon>person_add</mat-icon>
          </button>
        </div>
      </div>
      <div class="mail-cc">
        <mat-list>
          <span>Emails Cc: </span>
          <mat-list-item *ngFor="let email of sendingMailCcList">
            <mat-icon class="delete-icon" (click)="deleteEmail(email, 'sendCc')">delete</mat-icon> {{email}}
          </mat-list-item>
        </mat-list>

        <div>
          <mat-form-field appearance="fill" class="new-mail-input">
            <mat-label>New Cc Email</mat-label>
            <input matInput [(ngModel)]='newMailCc' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
          </mat-form-field>
          <button mat-stroked-button (click)="done(true)">
            <mat-icon>person_add</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="attachment-section">
      <div class="attachment-list">
        <mat-list>
          <span>Your attachments: </span>
          <mat-list-item *ngFor="let attachment of sendAttachment">
            <mat-icon mat-list-icon class="delete-file-icon" (click)="deleteEmail(attachment.name, 'sendAttachment')"
              matTooltip="Remove file from list" matTooltipClass="tooltip-red">
              link_off</mat-icon>
            <div mat-line>{{attachment.name}}</div>
            <div mat-line> {{ formatBytes(attachment.size) }} </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <br />
    <button mat-stroked-button (click)="attachFile()">
      <mat-icon>attach_file</mat-icon>
    </button>
  </div>

  <div class="spacer"></div>

  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Close</button>
    <button mat-button (click)="sendReply()">Reply</button>
  </div>
</section>