import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

import {FileContext} from '../models/FileContext'

@Pipe({
  name: 'fileTypeTransfom'
})
export class FileTypeTransfomPipe implements PipeTransform {

  public typeFile = [
    {
      "application": "Corel Envoy",
      "mime_type": "application/envoy",
      "file_extension": "evy"
    },
    {
      "application": "fractal image file",
      "mime_type": "application/fractals",
      "file_extension": "fif"
    },
    {
      "application": "Windows print spool file",
      "mime_type": "application/futuresplash",
      "file_extension": "spl"
    },
    {
      "application": "HTML application",
      "mime_type": "application/hta",
      "file_extension": "hta"
    },
    {
      "application": "Atari ST Program",
      "mime_type": "application/internet-property-stream",
      "file_extension": "acx"
    },
    {
      "application": "BinHex encoded file",
      "mime_type": "application/mac-binhex40",
      "file_extension": "hqx"
    },
    {
      "application": "Word document",
      "mime_type": "application/msword",
      "file_extension": "doc"
    },
    {
      "application": "Word document template",
      "mime_type": "application/msword",
      "file_extension": "dot"
    },
    {
      "application": "Binary file",
      "mime_type": "application/octet-stream",
      "file_extension": "*"
    },
    {
      "application": "binary disk image",
      "mime_type": "application/octet-stream",
      "file_extension": "bin"
    },
    {
      "application": "Java class file",
      "mime_type": "application/octet-stream",
      "file_extension": "class"
    },
    {
      "application": "Disk Masher image",
      "mime_type": "application/octet-stream",
      "file_extension": "dms"
    },
    {
      "application": "executable file",
      "mime_type": "application/octet-stream",
      "file_extension": "exe"
    },
    {
      "application": "LHARC compressed archive",
      "mime_type": "application/octet-stream",
      "file_extension": "lha"
    },
    {
      "application": "LZH compressed file",
      "mime_type": "application/octet-stream",
      "file_extension": "lzh"
    },
    {
      "application": "CALS raster image",
      "mime_type": "application/oda",
      "file_extension": "oda"
    },
    {
      "application": "ActiveX script",
      "mime_type": "application/olescript",
      "file_extension": "axs"
    },
    {
      "application": "Acrobat file",
      "mime_type": "application/pdf",
      "file_extension": "pdf"
    },
    {
      "application": "Outlook profile file",
      "mime_type": "application/pics-rules",
      "file_extension": "prf"
    },
    {
      "application": "certificate request file",
      "mime_type": "application/pkcs10",
      "file_extension": "p10"
    },
    {
      "application": "certificate revocation list file",
      "mime_type": "application/pkix-crl",
      "file_extension": "crl"
    },
    {
      "application": "Adobe Illustrator file",
      "mime_type": "application/postscript",
      "file_extension": "ai"
    },
    {
      "application": "postscript file",
      "mime_type": "application/postscript",
      "file_extension": "eps"
    },
    {
      "application": "postscript file",
      "mime_type": "application/postscript",
      "file_extension": "ps"
    },
    {
      "application": "rich text format file",
      "mime_type": "application/rtf",
      "file_extension": "rtf"
    },
    {
      "application": "set payment initiation",
      "mime_type": "application/set-payment-initiation",
      "file_extension": "setpay"
    },
    {
      "application": "set registration initiation",
      "mime_type": "application/set-registration-initiation",
      "file_extension": "setreg"
    },
    {
      "application": "Excel Add-in file",
      "mime_type": "application/vnd.ms-excel",
      "file_extension": "xla"
    },
    {
      "application": "Excel chart",
      "mime_type": "application/vnd.ms-excel",
      "file_extension": "xlc"
    },
    {
      "application": "Excel macro",
      "mime_type": "application/vnd.ms-excel",
      "file_extension": "xlm"
    },
    {
      "application": "Excel spreadsheet",
      "mime_type": "application/vnd.ms-excel",
      "file_extension": "xls"
    },
    {
      "application": "Excel template",
      "mime_type": "application/vnd.ms-excel",
      "file_extension": "xlt"
    },
    {
      "application": "Excel workspace",
      "mime_type": "application/vnd.ms-excel",
      "file_extension": "xlw"
    },
    {
      "application": "Outlook mail message",
      "mime_type": "application/vnd.ms-outlook",
      "file_extension": "msg"
    },
    {
      "application": "serialized certificate store file",
      "mime_type": "application/vnd.ms-pkicertstore",
      "file_extension": "sst"
    },
    {
      "application": "Windows catalog file",
      "mime_type": "application/vnd.ms-pkiseccat",
      "file_extension": "cat"
    },
    {
      "application": "stereolithography file",
      "mime_type": "application/vnd.ms-pkistl",
      "file_extension": "stl"
    },
    {
      "application": "PowerPoint template",
      "mime_type": "application/vnd.ms-powerpoint",
      "file_extension": "pot"
    },
    {
      "application": "PowerPoint slide show",
      "mime_type": "application/vnd.ms-powerpoint",
      "file_extension": "pps"
    },
    {
      "application": "PowerPoint presentation",
      "mime_type": "application/vnd.ms-powerpoint",
      "file_extension": "ppt"
    },
    {
      "application": "Microsoft Project file",
      "mime_type": "application/vnd.ms-project",
      "file_extension": "mpp"
    },
    {
      "application": "WordPerfect macro",
      "mime_type": "application/vnd.ms-works",
      "file_extension": "wcm"
    },
    {
      "application": "Microsoft Works database",
      "mime_type": "application/vnd.ms-works",
      "file_extension": "wdb"
    },
    {
      "application": "Microsoft Works spreadsheet",
      "mime_type": "application/vnd.ms-works",
      "file_extension": "wks"
    },
    {
      "application": "Microsoft Works word processor document",
      "mime_type": "application/vnd.ms-works",
      "file_extension": "wps"
    },
    {
      "application": "Windows help file",
      "mime_type": "application/winhlp",
      "file_extension": "hlp"
    },
    {
      "application": "binary CPIO archive",
      "mime_type": "application/x-bcpio",
      "file_extension": "bcpio"
    },
    {
      "application": "computable document format file",
      "mime_type": "application/x-cdf",
      "file_extension": "cdf"
    },
    {
      "application": "Unix compressed file",
      "mime_type": "application/x-compress",
      "file_extension": "z"
    },
    {
      "application": "gzipped tar file",
      "mime_type": "application/x-compressed",
      "file_extension": "tgz"
    },
    {
      "application": "Unix CPIO archive",
      "mime_type": "application/x-cpio",
      "file_extension": "cpio"
    },
    {
      "application": "Photoshop custom shapes file",
      "mime_type": "application/x-csh",
      "file_extension": "csh"
    },
    {
      "application": "Kodak RAW image file",
      "mime_type": "application/x-director",
      "file_extension": "dcr"
    },
    {
      "application": "Adobe Director movie",
      "mime_type": "application/x-director",
      "file_extension": "dir"
    },
    {
      "application": "Macromedia Director movie",
      "mime_type": "application/x-director",
      "file_extension": "dxr"
    },
    {
      "application": "device independent format file",
      "mime_type": "application/x-dvi",
      "file_extension": "dvi"
    },
    {
      "application": "Gnu tar archive",
      "mime_type": "application/x-gtar",
      "file_extension": "gtar"
    },
    {
      "application": "Gnu zipped archive",
      "mime_type": "application/x-gzip",
      "file_extension": "gz"
    },
    {
      "application": "hierarchical data format file",
      "mime_type": "application/x-hdf",
      "file_extension": "hdf"
    },
    {
      "application": "internet settings file",
      "mime_type": "application/x-internet-signup",
      "file_extension": "ins"
    },
    {
      "application": "IIS internet service provider settings",
      "mime_type": "application/x-internet-signup",
      "file_extension": "isp"
    },
    {
      "application": "ARC+ architectural file",
      "mime_type": "application/x-iphone",
      "file_extension": "iii"
    },
    {
      "application": "JavaScript file",
      "mime_type": "application/x-javascript",
      "file_extension": "js"
    },
    {
      "application": "LaTex document",
      "mime_type": "application/x-latex",
      "file_extension": "latex"
    },
    {
      "application": "Microsoft Access database",
      "mime_type": "application/x-msaccess",
      "file_extension": "mdb"
    },
    {
      "application": "Windows CardSpace file",
      "mime_type": "application/x-mscardfile",
      "file_extension": "crd"
    },
    {
      "application": "CrazyTalk clip file",
      "mime_type": "application/x-msclip",
      "file_extension": "clp"
    },
    {
      "application": "dynamic link library",
      "mime_type": "application/x-msdownload",
      "file_extension": "dll"
    },
    {
      "application": "Microsoft media viewer file",
      "mime_type": "application/x-msmediaview",
      "file_extension": "m13"
    },
    {
      "application": "Steuer2001 file",
      "mime_type": "application/x-msmediaview",
      "file_extension": "m14"
    },
    {
      "application": "multimedia viewer book source file",
      "mime_type": "application/x-msmediaview",
      "file_extension": "mvb"
    },
    {
      "application": "Windows meta file",
      "mime_type": "application/x-msmetafile",
      "file_extension": "wmf"
    },
    {
      "application": "Microsoft Money file",
      "mime_type": "application/x-msmoney",
      "file_extension": "mny"
    },
    {
      "application": "Microsoft Publisher file",
      "mime_type": "application/x-mspublisher",
      "file_extension": "pub"
    },
    {
      "application": "Turbo Tax tax schedule list",
      "mime_type": "application/x-msschedule",
      "file_extension": "scd"
    },
    {
      "application": "FTR media file",
      "mime_type": "application/x-msterminal",
      "file_extension": "trm"
    },
    {
      "application": "Microsoft Write file",
      "mime_type": "application/x-mswrite",
      "file_extension": "wri"
    },
    {
      "application": "computable document format file",
      "mime_type": "application/x-netcdf",
      "file_extension": "cdf"
    },
    {
      "application": "Mastercam numerical control file",
      "mime_type": "application/x-netcdf",
      "file_extension": "nc"
    },
    {
      "application": "MSX computers archive format",
      "mime_type": "application/x-perfmon",
      "file_extension": "pma"
    },
    {
      "application": "performance monitor counter file",
      "mime_type": "application/x-perfmon",
      "file_extension": "pmc"
    },
    {
      "application": "process monitor log file",
      "mime_type": "application/x-perfmon",
      "file_extension": "pml"
    },
    {
      "application": "Avid persistent media record file",
      "mime_type": "application/x-perfmon",
      "file_extension": "pmr"
    },
    {
      "application": "Pegasus Mail draft stored message",
      "mime_type": "application/x-perfmon",
      "file_extension": "pmw"
    },
    {
      "application": "personal information exchange file",
      "mime_type": "application/x-pkcs12",
      "file_extension": "p12"
    },
    {
      "application": "PKCS #12 certificate file",
      "mime_type": "application/x-pkcs12",
      "file_extension": "pfx"
    },
    {
      "application": "PKCS #7 certificate file",
      "mime_type": "application/x-pkcs7-certificates",
      "file_extension": "p7b"
    },
    {
      "application": "software publisher certificate file",
      "mime_type": "application/x-pkcs7-certificates",
      "file_extension": "spc"
    },
    {
      "application": "certificate request response file",
      "mime_type": "application/x-pkcs7-certreqresp",
      "file_extension": "p7r"
    },
    {
      "application": "PKCS #7 certificate file",
      "mime_type": "application/x-pkcs7-mime",
      "file_extension": "p7c"
    },
    {
      "application": "digitally encrypted message",
      "mime_type": "application/x-pkcs7-mime",
      "file_extension": "p7m"
    },
    {
      "application": "digitally signed email message",
      "mime_type": "application/x-pkcs7-signature",
      "file_extension": "p7s"
    },
    {
      "application": "Bash shell script",
      "mime_type": "application/x-sh",
      "file_extension": "sh"
    },
    {
      "application": "Unix shar archive",
      "mime_type": "application/x-shar",
      "file_extension": "shar"
    },
    {
      "application": "Flash file",
      "mime_type": "application/x-shockwave-flash",
      "file_extension": "swf"
    },
    {
      "application": "Stuffit archive file",
      "mime_type": "application/x-stuffit",
      "file_extension": "sit"
    },
    {
      "application": "system 5 release 4 CPIO file",
      "mime_type": "application/x-sv4cpio",
      "file_extension": "sv4cpio"
    },
    {
      "application": "system 5 release 4 CPIO checksum data",
      "mime_type": "application/x-sv4crc",
      "file_extension": "sv4crc"
    },
    {
      "application": "consolidated Unix file archive",
      "mime_type": "application/x-tar",
      "file_extension": "tar"
    },
    {
      "application": "Tcl script",
      "mime_type": "application/x-tcl",
      "file_extension": "tcl"
    },
    {
      "application": "LaTeX source document",
      "mime_type": "application/x-tex",
      "file_extension": "tex"
    },
    {
      "application": "LaTeX info document",
      "mime_type": "application/x-texinfo",
      "file_extension": "texi"
    },
    {
      "application": "LaTeX info document",
      "mime_type": "application/x-texinfo",
      "file_extension": "texinfo"
    },
    {
      "application": "unformatted manual page",
      "mime_type": "application/x-troff",
      "file_extension": "roff"
    },
    {
      "application": "Turing source code file",
      "mime_type": "application/x-troff",
      "file_extension": "t"
    },
    {
      "application": "TomeRaider 2 ebook file",
      "mime_type": "application/x-troff",
      "file_extension": "tr"
    },
    {
      "application": "Unix manual",
      "mime_type": "application/x-troff-man",
      "file_extension": "man"
    },
    {
      "application": "readmE_Text file",
      "mime_type": "application/x-troff-me",
      "file_extension": "me"
    },
    {
      "application": "3ds Max script file",
      "mime_type": "application/x-troff-ms",
      "file_extension": "ms"
    },
    {
      "application": "uniform standard tape archive format file",
      "mime_type": "application/x-ustar",
      "file_extension": "ustar"
    },
    {
      "application": "source code",
      "mime_type": "application/x-wais-source",
      "file_extension": "src"
    },
    {
      "application": "internet security certificate",
      "mime_type": "application/x-x509-ca-cert",
      "file_extension": "cer"
    },
    {
      "application": "security certificate",
      "mime_type": "application/x-x509-ca-cert",
      "file_extension": "crt"
    },
    {
      "application": "DER certificate file",
      "mime_type": "application/x-x509-ca-cert",
      "file_extension": "der"
    },
    {
      "application": "public key security object",
      "mime_type": "application/ynd.ms-pkipko",
      "file_extension": "pko"
    },
    {
      "application": "zipped file",
      "mime_type": "application/zip",
      "file_extension": "zip"
    },
    {
      "application": "audio file",
      "mime_type": "audio/basic",
      "file_extension": "au"
    },
    {
      "application": "sound file",
      "mime_type": "audio/basic",
      "file_extension": "snd"
    },
    {
      "application": "midi file",
      "mime_type": "audio/mid",
      "file_extension": "mid"
    },
    {
      "application": "media processing server studio",
      "mime_type": "audio/mid",
      "file_extension": "rmi"
    },
    {
      "application": "MP3 file",
      "mime_type": "audio/mpeg",
      "file_extension": "mp3"
    },
    {
      "application": "audio interchange file format",
      "mime_type": "audio/x-aiff",
      "file_extension": "aif"
    },
    {
      "application": "compressed audio interchange file",
      "mime_type": "audio/x-aiff",
      "file_extension": "aifc"
    },
    {
      "application": "audio interchange file format",
      "mime_type": "audio/x-aiff",
      "file_extension": "aiff"
    },
    {
      "application": "media playlist file",
      "mime_type": "audio/x-mpegurl",
      "file_extension": "m3u"
    },
    {
      "application": "Real Audio file",
      "mime_type": "audio/x-pn-realaudio",
      "file_extension": "ra"
    },
    {
      "application": "Real Audio metadata file",
      "mime_type": "audio/x-pn-realaudio",
      "file_extension": "ram"
    },
    {
      "application": "WAVE audio file",
      "mime_type": "audio/x-wav",
      "file_extension": "wav"
    },
    {
      "application": "Bitmap",
      "mime_type": "image/bmp",
      "file_extension": "bmp"
    },
    {
      "application": "compiled source code",
      "mime_type": "image/cis-cod",
      "file_extension": "cod"
    },
    {
      "application": "graphic interchange format",
      "mime_type": "image/gif",
      "file_extension": "gif"
    },
    {
      "application": "image file",
      "mime_type": "image/ief",
      "file_extension": "ief"
    },
    {
      "application": "JPEG image",
      "mime_type": "image/jpeg",
      "file_extension": "jpe"
    },
    {
      "application": "JPEG image",
      "mime_type": "image/jpeg",
      "file_extension": "jpeg"
    },
    {
      "application": "JPEG image",
      "mime_type": "image/jpeg",
      "file_extension": "jpg"
    },
    {
      "application": "JPEG file interchange format",
      "mime_type": "image/pipeg",
      "file_extension": "jfif"
    },
    {
      "application": "scalable vector graphic",
      "mime_type": "image/svg+xml",
      "file_extension": "svg"
    },
    {
      "application": "TIF image",
      "mime_type": "image/tiff",
      "file_extension": "tif"
    },
    {
      "application": "TIF image",
      "mime_type": "image/tiff",
      "file_extension": "tiff"
    },
    {
      "application": "Sun raster graphic",
      "mime_type": "image/x-cmu-raster",
      "file_extension": "ras"
    },
    {
      "application": "Corel metafile_Exchange image file",
      "mime_type": "image/x-cmx",
      "file_extension": "cmx"
    },
    {
      "application": "icon",
      "mime_type": "image/x-icon",
      "file_extension": "ico"
    },
    {
      "application": "portable any map image",
      "mime_type": "image/x-portable-anymap",
      "file_extension": "pnm"
    },
    {
      "application": "portable bitmap image",
      "mime_type": "image/x-portable-bitmap",
      "file_extension": "pbm"
    },
    {
      "application": "portable graymap image",
      "mime_type": "image/x-portable-graymap",
      "file_extension": "pgm"
    },
    {
      "application": "portable pixmap image",
      "mime_type": "image/x-portable-pixmap",
      "file_extension": "ppm"
    },
    {
      "application": "RGB bitmap",
      "mime_type": "image/x-rgb",
      "file_extension": "rgb"
    },
    {
      "application": "X11 bitmap",
      "mime_type": "image/x-xbitmap",
      "file_extension": "xbm"
    },
    {
      "application": "X11 pixmap",
      "mime_type": "image/x-xpixmap",
      "file_extension": "xpm"
    },
    {
      "application": "X-Windows dump image",
      "mime_type": "image/x-xwindowdump",
      "file_extension": "xwd"
    },
    {
      "application": "MHTML web archive",
      "mime_type": "message/rfc822",
      "file_extension": "mht"
    },
    {
      "application": "MIME HTML file",
      "mime_type": "message/rfc822",
      "file_extension": "mhtml"
    },
    {
      "application": "Windows Live Mail newsgroup file",
      "mime_type": "message/rfc822",
      "file_extension": "nws"
    },
    {
      "application": "Cascading Style Sheet",
      "mime_type": "text/css",
      "file_extension": "css"
    },
    {
      "application": "H.323 internet telephony file",
      "mime_type": "text/h323",
      "file_extension": "323"
    },
    {
      "application": "HTML file",
      "mime_type": "text/html",
      "file_extension": "htm"
    },
    {
      "application": "HTML file",
      "mime_type": "text/html",
      "file_extension": "html"
    },
    {
      "application": "Exchange streaming media file",
      "mime_type": "text/html",
      "file_extension": "stm"
    },
    {
      "application": "NetMeeting user location service file",
      "mime_type": "text/iuls",
      "file_extension": "uls"
    },
    {
      "application": "BASIC source code file",
      "mime_type": "text/plain",
      "file_extension": "bas"
    },
    {
      "application": "C/C++ source code file",
      "mime_type": "text/plain",
      "file_extension": "c"
    },
    {
      "application": "C/C++/Objective C header file",
      "mime_type": "text/plain",
      "file_extension": "h"
    },
    {
      "application": "text file",
      "mime_type": "text/plain",
      "file_extension": "txt"
    },
    {
      "application": "rich text file",
      "mime_type": "text/richtext",
      "file_extension": "rtx"
    },
    {
      "application": "Scitext continuous tone file",
      "mime_type": "text/scriptlet",
      "file_extension": "sct"
    },
    {
      "application": "tab separated values file",
      "mime_type": "text/tab-separated-values",
      "file_extension": "tsv"
    },
    {
      "application": "hypertext template file",
      "mime_type": "text/webviewhtml",
      "file_extension": "htt"
    },
    {
      "application": "HTML component file",
      "mime_type": "text/x-component",
      "file_extension": "htc"
    },
    {
      "application": "TeX font encoding file",
      "mime_type": "text/x-setext",
      "file_extension": "etx"
    },
    {
      "application": "vCard file",
      "mime_type": "text/x-vcard",
      "file_extension": "vcf"
    },
    {
      "application": "application",
      "mime_type": "mime_type",
      "file_extension": "file_extension"
    },
    {
      "application": "MHTML web archive",
      "mime_type": "message/rfc822",
      "file_extension": "mht"
    },
    {
      "application": "MIME HTML file",
      "mime_type": "message/rfc822",
      "file_extension": "mhtml"
    },
    {
      "application": "Windows Live Mail newsgroup file",
      "mime_type": "message/rfc822",
      "file_extension": "nws"
    },
    {
      "application": "MPEG-2 audio file",
      "mime_type": "video/mpeg",
      "file_extension": "mp2"
    },
    {
      "application": "MPEG-2 audio file",
      "mime_type": "video/mpeg",
      "file_extension": "mpa"
    },
    {
      "application": "MPEG movie file",
      "mime_type": "video/mpeg",
      "file_extension": "mpe"
    },
    {
      "application": "MPEG movie file",
      "mime_type": "video/mpeg",
      "file_extension": "mpeg"
    },
    {
      "application": "MPEG movie file",
      "mime_type": "video/mpeg",
      "file_extension": "mpg"
    },
    {
      "application": "MPEG-2 video stream",
      "mime_type": "video/mpeg",
      "file_extension": "mpv2"
    },
    {
      "application": "MPEG-4",
      "mime_type": "video/mp4",
      "file_extension": "mp4"
    },
    {
      "application": "Apple QuickTime movie",
      "mime_type": "video/quicktime",
      "file_extension": "mov"
    },
    {
      "application": "Apple QuickTime movie",
      "mime_type": "video/quicktime",
      "file_extension": "qt"
    },
    {
      "application": "Logos library system file",
      "mime_type": "video/x-la-asf",
      "file_extension": "lsf"
    },
    {
      "application": "streaming media shortcut",
      "mime_type": "video/x-la-asf",
      "file_extension": "lsx"
    },
    {
      "application": "advanced systems format file",
      "mime_type": "video/x-ms-asf",
      "file_extension": "asf"
    },
    {
      "application": "ActionScript remote document",
      "mime_type": "video/x-ms-asf",
      "file_extension": "asr"
    },
    {
      "application": "Microsoft ASF redirector file",
      "mime_type": "video/x-ms-asf",
      "file_extension": "asx"
    },
    {
      "application": "audio video interleave file",
      "mime_type": "video/x-msvideo",
      "file_extension": "avi"
    },
    {
      "application": "Apple QuickTime movie",
      "mime_type": "video/x-sgi-movie",
      "file_extension": "movie"
    },
    {
      "application": "Flare decompiled actionscript file",
      "mime_type": "x-world/x-vrml",
      "file_extension": "flr"
    },
    {
      "application": "VRML file",
      "mime_type": "x-world/x-vrml",
      "file_extension": "vrml"
    },
    {
      "application": "VRML world",
      "mime_type": "x-world/x-vrml",
      "file_extension": "wrl"
    },
    {
      "application": "compressed VRML world",
      "mime_type": "x-world/x-vrml",
      "file_extension": "wrz"
    },
    {
      "application": "3ds max XML animation file",
      "mime_type": "x-world/x-vrml",
      "file_extension": "xaf"
    },
    {
      "application": "Reality Lab 3D image file",
      "mime_type": "x-world/x-vrml",
      "file_extension": "xof"
    }
   ]


   transform(value: unknown, from: FileContext, to: FileContext): Observable<string> {
    if(!!value && to == 'file_extension'){
      return of(this.typeFile.find(el => el[from] == value).file_extension);
    }else if(!!value && to == 'mime_type'){
      return of(this.typeFile.find(el => el[from] == value).mime_type);
    }else if(!!value && to == 'application'){
      return of(this.typeFile.find(el => el[from] == value).application);
    }else if(!!value){
      return of(this.typeFile.find(el => el[from] == value).application);
    }
    return of('Unknown File')
  }

}
