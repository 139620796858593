import { Component, EventEmitter, Input, isDevMode, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  @Input()
  pageTitle = '';
  @Input()
  version = '';
  @Input()
  actions = [];

  role: String = '';

  @ViewChild('account') public account: MatMenu;

  @Output() onClickLogin: EventEmitter<any> = new EventEmitter();
  @Output() onClickInfo: EventEmitter<any> = new EventEmitter();
  @Output() onClickLogout: EventEmitter<any> = new EventEmitter();
  @Output() onClickLogo: EventEmitter<any> = new EventEmitter();
  @Output() toggleSidenav = new EventEmitter<void>();

  availableRoles: string[];

  constructor(protected router: Router, private _authService: AuthService, private httpClient: HttpClient) {
  }

  public username: string | null;
  public email: string | null;
  public usr: string | null;



  public errorHandler = (error: any) => {
    return EMPTY;
  }

  ngOnInit(): void {
    /*const account = this.authService.instance.getActiveAccount();
    const accessTokenRequest = {
      scopes: ["User.Read", "openId", "email", "profile", "offline_access"],
      account: account,
   }*/
    this.usr = null;
    this._authService.getUser().then(user => {
      if (!!user) {
        this.username = user.profile.name;
        if (user.profile.email == undefined || user.profile.email == null) this.email = user.profile.preferred_username;
        else this.email = user.profile.email;
        this.usr = (user.profile.name.split(' '))[0].slice(0, 1) + (user.profile.name.split(' '))[1].slice(0, 1);
      }
    })

    this._authService.getRole().subscribe(res => {
      this.role = res['defaultRole'];
    })

    this._authService.getRoles().subscribe(res => {
      this.availableRoles = res['roles'];
    })

    /*this._authService.getAction().subscribe(actions => {
      this.permittedActions = actions['actions'];
    }, err => {
      //this.router.navigate(['/'],);
    }))*/
  }

  isDevMode() {
    return isDevMode();
  }

  goToImported($event: any): void {
    this.router.navigate(['/imported']);
  }

  goToMonitor($event: any): void {
    this.router.navigate(['/monitor']);
  }

  goToHelp($event: any): void {
    this.router.navigate(['/help']);
  }

  goToMasterData($event: any): void {
    this.router.navigate(['/master-data']);
  }

  login() {
    this.onClickLogin.emit();
  }

  userInfo() {
    this.onClickInfo.emit();
  }

  logout() {
    this.onClickLogout.emit();
  }

  onLogoClick() {
    this.onClickLogo.emit();
  }

  isActionPresent(action: string) {
    if(this.actions.indexOf(action) > -1)
     return true;
    else
      return false
  }

  setRole(event: string) {
    this._authService.setRole(event).subscribe(res => {
      window.location.reload()

    })
  }
}
