import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as saveAs from 'file-saver';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { MonitorService } from 'src/app/services/monitor.service';
import { AlertType } from 'src/app/shared/components/alert/alert.component';
import { AuditDialogComponent } from 'src/app/shared/components/audit-dialog/audit-dialog.component';
import { Header } from 'src/app/shared/components/data-table/data-table.component';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { DnDComponent } from 'src/app/shared/components/dnd/dnd.component';
import { FILTER_OBJ } from 'src/app/shared/components/filter/filter.component';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { PageEventType } from 'src/app/shared/components/paginator/paginator.component';
import { splitFilter, groupBy, replacer, reviver } from 'src/app/shared/utils/generic';

@Component({
  selector: 'app-preview-su',
  templateUrl: './preview-su.component.html',
  styleUrls: ['./preview-su.component.scss']
})
export class PreviewSUComponent implements OnInit, OnDestroy {

  @ViewChild('alertMessageMonitor') alertMessageMonitor;
  @ViewChild('childDataTableMonitor') childDataTableMonitor;
  @ViewChild('childDataTableMonitor') childDataTableMonitorImport;

  monitorDataSourceSubject = new BehaviorSubject<any[]>([]);
  monitorDataSourceObservable = this.monitorDataSourceSubject.asObservable();
  monitorDataSource = [];
  monitorLength = 0;

  monitorImportDataSourceSubject = new BehaviorSubject<any[]>([]);
  monitorImportDataSourceObservable = this.monitorImportDataSourceSubject.asObservable();
  monitorImportDataSource = [];
  monitorImportLength = 0;

  isImported: boolean;
  isChanged: boolean = false;

  onSaveAllSubscription: Subscription;

  loading: boolean = false;
  pageEvent: PageEventType = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };

  filterObjMonitor: FILTER_OBJ[] = []

  sortedColumnsMonitor: Map<string, { order: number, type: 'asc' | 'desc' }>;
  filterValuesMonitor: Array<string>;

  destinationsOptions: any[] = [];

  userOptions: any[] = [];

  containerContentTypeOptions: any[] = [];

  screen: string = 'preview-su'

  importError: boolean = false;

  permittedActions: String[] = [];
  subscriptionArray: Subscription[] = [];

  headersSubject = new BehaviorSubject<Header[]>([]);
  monitorHeaders = this.headersSubject.asObservable();

  headersImportSubject = new BehaviorSubject<Header[]>([]);
  monitorImportHeaders = this.headersImportSubject.asObservable();

  showDelete: boolean = false;

  showOnlyDuplicate: boolean = true;


  constructor(private monitorService: MonitorService, private masterDataService: MasterDataService, public dialog: MatDialog, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.sortedColumnsMonitor = new Map<string, { order: number, type: 'asc' | 'desc' }>([['container', {
      order: 1,
      type: 'asc'
    }], ['portEtaActual', { order: 2, type: 'desc' }]]);
    this.filterValuesMonitor = new Array<string>();
    this.pageEvent = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
    this.isImported = false;
    this.isChanged = false;
  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => sub.unsubscribe());
  }

  ngOnInit(): void {

    lastValueFrom(this.authService.getTableSetDetail('PREVIEW_SU')).then(headers => {

      let monitorHeaders = [];
      headers["detailModels"].forEach(header => {
        //console.log(header)
        monitorHeaders.push({
          id: header.order,
          field: header.column,
          label: header.label,
          editable: header.editable == 'Y' ? true : false,
          editType: header.type,
          selectOptions: header.options,
          headerClass: header.headerClass,
          actions: header.actions
        });


        if (!!header['isFilterable']) {
          this.filterObjMonitor.push(
            {
              name: header['label'],
              columnProp: header['column'],
              filterType: header['filterType'],
              value: !!header['filterSelectOptionValues'] ? header['filterSelectOptionValues'].split(';').map(el => {
                return { value: el, label: el }
              }) : header['filterSelectOptionValues'],
              modelValue: ''
            })
        }
      })
      this.headersSubject.next(monitorHeaders);
    }).finally(() => {


      /*this.sortedColumnsMonitor = new Map<string, { order: number, type: 'asc' | 'desc' }>([['portEtaActual', {
        order: 1,
        type: 'desc'
      }], ['container', { order: 2, type: 'asc' }]]);*/

      this.subscriptionArray.push(
        this.authService.getAction().subscribe(actions => {

          this.permittedActions = actions['actions'];
          this.subscriptionArray.forEach(sub => sub.unsubscribe());
        })
      );


      this.filterValuesMonitor = new Array<string>();
      this.pageEvent = { pageIndex: 0, previousPageIndex: 0, loader: 50, size: 50 };
      this.isImported = false;
      this.isChanged = false;
      this.getAllDestinationsOptions();
      this.getAllUsers();
      this.getAllContainerContentTypeOptions().finally(() => {
        const backupFilter = JSON.parse(sessionStorage.getItem('backup.filter.preview-su'))

        if (!!backupFilter) {
          this.filterValuesMonitor = backupFilter;
          let backupArray = backupFilter.map(item => {
            return item.split("&");
          }).flat().map(fItem => {
            return splitFilter(fItem)
          })
          backupArray = groupBy(backupArray, 'key', 'value');

          this.filterObjMonitor.forEach((filter) => {
            if (!!filter.filterType && filter.filterType == 'multipleSelect') {
              if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
                filter.modelValue = filter.value.filter(e => !(backupArray['' + filter.columnProp].some(el => el['value'] == e.value))).map(e => e.value)
              }
            } else {
              if (!!backupArray['' + filter.columnProp] && backupArray['' + filter.columnProp].length > 0) {
                filter.value = (backupArray['' + filter.columnProp])[0].value;
                filter.modelValue = (backupArray['' + filter.columnProp])[0].value;
              }
            }
          })
        }
        const backupSorting = JSON.parse(sessionStorage.getItem('backup.sorting.preview-su'), reviver);
        if (!!backupSorting) {
          this.sortedColumnsMonitor = backupSorting;
        }
        this.getPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor);

      });

    })
  }

  getAllDestinationsOptions() {
    this.loading = true;
    this.masterDataService.getListDestinations()
      .subscribe(
        (res: any) => {
          if (!!res) {
            this.destinationsOptions = res.map((destination: any) => ({
              value: destination.id.value,
              label: `${destination.rdcName.value}`
            })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
          }

        },
        (_err: any) => {
          this.loading = false;
        },
        () => this.loading = false)

  }

  getAllContainerContentTypeOptions() {
    this.loading = true;
    return lastValueFrom(this.masterDataService.getContainerContentTypeList()).then(res => {
      if (!!res) {
        this.containerContentTypeOptions = res.map((container_content_type: any) => ({
          value: container_content_type.containerContentTypeId.value,
          label: `${container_content_type.containerContentTypeName.value}`
        })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
        this.filterObjMonitor = this.filterObjMonitor.map(filter => {
          if (!!filter['columnProp'] && filter['columnProp'] == 'containerContentType' && filter['filterType'] == 'multipleSelect') {
            filter['value'] = this.containerContentTypeOptions.map(el => {
              return { value: el.value, label: el.label }
            });
          }
          return filter;
        })


      }
    })
  }

  onPageChange(pageEvent: any): void {
    this.pageEvent = pageEvent;
    this.getPreview(pageEvent.pageIndex, pageEvent.loader, this.sortedColumnsMonitor, this.filterValuesMonitor);
  }


  onSortChange(pageSorting: { sorting: Map<string, { order: number, type: 'asc' | 'desc' }>; pageEvent: PageEventType }): void {
    this.sortedColumnsMonitor = pageSorting.sorting;
    sessionStorage.setItem('backup.sorting.preview-su', JSON.stringify(pageSorting.sorting, replacer));
    this.refreshPreview(0, this.monitorDataSource.length, pageSorting.sorting, this.filterValuesMonitor);
  }

  onApplyFilter(filters: Array<string>): void {
    this.filterValuesMonitor = filters;
    sessionStorage.setItem('backup.filter.preview-su', JSON.stringify(filters));
    this.refreshPreview(0, this.pageEvent.loader, this.sortedColumnsMonitor, filters);

  }


  onDestinationEditStart(obj: { column: Header, element: any }) {
    if (obj.column.field === "finalDestination") {
      obj.column.selectOptions = this.destinationsOptions;
    }
    if (obj.column.field === "destinationId") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.destinationsOptions;
    }
    if (obj.column.field === "containerContentType") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.containerContentTypeOptions;
    }
    if (obj.column.field === "managedBy") {
      //this.oldRecord = _.cloneDeep(obj.element);
      obj.column.selectOptions = this.userOptions;
    }
  }

  onEditCell(data: { record: any, oldRecord: any, cel: string, editType: string }): void {
    //console.log(data)
    if (!!data.record && !!data.oldRecord && data.record[data.cel].value === data.oldRecord[data.cel].value)
      return;

    if (data.record[data.cel].value == '')
      delete data.record[data.cel].value
    if (!this.isChanged) {
      //const loadingEvent = this.dialog.open(LoadingComponent);
      this.spinner.show();
      const { meta, ...newRecord } = data.record;
      this.destinationsOptions.forEach(elem => {
        if (elem.label === newRecord.destinationId.value) {
          //newRecord.rdcName.value = elem.value
          newRecord['destinationId'] = {
            value: elem.value,
            errorType: '',
            errorList: [],
            errorAction: ''
          }
        }
      })

      this.containerContentTypeOptions.forEach(elem => {
        if (elem.label === newRecord.containerContentType.value) {
          //newRecord.rdcName.value = elem.value
          newRecord['containerContentType'] = {
            value: elem.value,
            errorType: '',
            errorList: [],
            errorAction: ''
          }
        }
      })

      delete newRecord.statusRow;

      this.monitorService.renderRow(newRecord).subscribe((res) => {
        //this.isChanged = true;
        this.monitorDataSource = this.monitorDataSource.map(row => {
          if (row.id.value === res.id.value) {
            this.destinationsOptions.forEach(elem => {
              if (elem.value === res.destinationId.value) {
                res.destinationId.value = elem.label;
              }
            })

            this.containerContentTypeOptions.forEach(option => {
              if (option.value === res.containerContentType.value) {
                res.containerContentType.value = option.label
              }
            })
            return res;
          } else {
            return row;
          }
        })
        this.monitorDataSourceSubject.next(this.monitorDataSource);
        //loadingEvent.close();
        this.spinner.hide();
        //this.refreshPreview(0, this.monitorDataSource.length, this.sortedColumnsMonitor, this.filterValuesMonitor);
      }, err => {
        //console.log(err);
        //loadingEvent.close();
        this.spinner.hide();
        if (err.status == 403)
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
        else
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error", "An error occurred during edit, please check your input.", 10);
      })

    }
  }

  getPreview(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>) {
    this.loading = true;
    this.isChanged = false;
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })


    filters.forEach(filter => {
      if (!filter.includes('&')) {
        if (!(!!params.get('search')))
          params = params.set('search', filter.replace(',', '§'));
        else
          params = params.append('search', filter.replace(',', '§'));
      } else {
        filter.split('&').forEach(value => {
          if (!(!!params.get('search')))
            params = params.set('search', value.replace(',', '§'));
          else
            params = params.append('search', value.replace(',', '§'));
        })
      }

    });

    if (!this.showDelete) {
      if (!(!!params.get('search')))
        params = params.set('search', 'status!DELETE');
      else
        params = params.append('search', 'status!DELETE');
    }

    if (this.showOnlyDuplicate) {
      params = params.set('duplicate', '1');
    } else {
      params = params.set('duplicate', '0');
    }

    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    const monitorSubscribe = this.monitorService.getDuplicatedPreview(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.monitorDataSource = this.hasSubArray(this.monitorDataSource, res.content).map(e => {
              this.destinationsOptions.forEach(elem => {
                if (elem.value === e.destinationId.value) {
                  e.destinationId.value = elem.label
                }
              })

              this.containerContentTypeOptions.forEach(option => {
                if (option.value === e.containerContentType.value) {
                  e.containerContentType.value = option.label
                }
              })
              return e;
            });
            this.monitorDataSourceSubject.next(this.monitorDataSource);
          }

          this.monitorLength = res.totalElements;
          //this.pageEvent.length =  res.totalElements;
          this.loading = false;
          //loadingEvent.close();
          this.spinner.hide();
          monitorSubscribe.unsubscribe();
        },
        (err: any) => {
          this.loading = false;
          this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Monitor loading error", "An error occurred during monitor loading. Please contact the administrator if the error persists.", 10)
          //loadingEvent.close();
          this.spinner.hide();
          monitorSubscribe.unsubscribe();
        },
        () => {
          this.loading = false;
          //loadingEvent.close();
          this.spinner.hide();
          monitorSubscribe.unsubscribe();
        })
  }

  refreshPreview(page: number, size: number, sorting: Map<string, { order: number, type: 'asc' | 'desc' }>, filters: Array<string>, isAfterImport?: boolean) {
    this.loading = true;
    this.isChanged = false;
    let params = new HttpParams().set('page', page).set('size', size);
    new Map([...sorting.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })

    filters.forEach(filter => {
      if (!filter.includes('&')) {
        if (!(!!params.get('search')))
          params = params.set('search', filter.replace(',', '§'));
        else
          params = params.append('search', filter.replace(',', '§'));
      } else {
        filter.split('&').forEach(value => {
          if (!(!!params.get('search')))
            params = params.set('search', value.replace(',', '§'));
          else
            params = params.append('search', value.replace(',', '§'));
        })
      }

    });

    if (!this.showDelete) {
      if (!(!!params.get('search')))
        params = params.set('search', 'status!DELETE');
      else
        params = params.append('search', 'status!DELETE');
    }

    if (this.showOnlyDuplicate) {
      params = params.set('duplicate', '1');
    } else {
      params = params.set('duplicate', '0');
    }


    this.monitorDataSource = [];
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    if (!!isAfterImport) {
      this.authService.getHeader("oemcc_preview_view").subscribe(headers => {
        let monitorHeaders = [];
        headers["detailModels"].forEach(header => {
          //console.log(header)
          monitorHeaders.push({
            id: header.order,
            field: header.column,
            label: header.label,
            editable: header.editable == 'Y' ? true : false,
            editType: header.type,
            selectOptions: header.options,
            headerClass: header.headerClass,
            actions: header.actions
          });
        })
        this.headersSubject.next(monitorHeaders);
      })

    }


    const monitorSubscribe = this.monitorService.getDuplicatedPreview(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.monitorDataSource = this.hasSubArray(this.monitorDataSource, res.content).map(e => {
              this.destinationsOptions.forEach(elem => {
                if (elem.value === e.destinationId.value) {
                  e.destinationId.value = elem.label
                }
              })
              this.containerContentTypeOptions.forEach(option => {
                if (option.value === e.containerContentType.value) {
                  e.containerContentType.value = option.label
                }
              })
              return e;
            });
            this.monitorDataSourceSubject.next(this.monitorDataSource);
          }
          this.monitorLength = res.totalElements;
          //this.pageEvent.length = res.totalElements;
          this.loading = false;
          //loadingEvent.close();
          this.spinner.hide();
          monitorSubscribe.unsubscribe();
        },
        (err: any) => {
          this.loading = false;
          this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Monitor loading error", "An error occurred during monitor table loading. Please contact the administrator if the error persists.", 10)
          //loadingEvent.close();
          this.spinner.hide();
          monitorSubscribe.unsubscribe();
        },
        () => {
          this.loading = false;
          //loadingEvent.close();
          this.spinner.hide();
          monitorSubscribe.unsubscribe();
        })
  }


  hasSubArray(master: any[], sub: any[]) {
    /*if (sub.every((i => v => i = master.indexOf(v, i) + 1)(0))) {
      return master;
    }
    return master.concat(sub);*/
    sub.forEach(elem => {
      const index = master.findIndex(e => e.id.value === elem.id.value);
      if (index !== -1) {
        master[index] = elem;
      } else {
        master.push(elem);
      }
    })
    return master;

  }

  onExportClick(selectedRowMonitor: SelectionModel<any>) {
    this.loading = true;
    //const selectedRowMonitor: SelectionModel<any> = this.childDataTableMonitor.selection;
    const fileName = "Excel_" + Date.now() + ".xlsx";
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    let selected = new Array();
    selectedRowMonitor.selected.forEach((el) => {
      this.destinationsOptions.forEach(elem => {
        if (elem.label === el.destinationId.value) {
          //newRecord.rdcName.value = elem.value
          el['destinationId'] = {
            value: elem.value,
            errorType: '',
            errorList: [],
            errorAction: ''
          }
        }
      })
      selected.push(Object.assign({}, el));
    })

    this.monitorService.exportExcel(selected).subscribe(blob => {
      this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Excel exported", "An excel file for the selected records has been exported.", 5);
      saveAs(blob, fileName);
      //loadingEvent.close();
      this.spinner.hide();
      this.loading = false;
    }, err => {
      if (err.status == 403)
        this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10);
      else
        this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Export error", "An error occurred during export.", 10);
      //loadingEvent.close();
      this.spinner.hide();
    })

    this.refreshPreview(0, this.monitorDataSource.length, this.sortedColumnsMonitor, this.filterValuesMonitor);
  }

  onSaveClick() {

    this.isChanged = false;
    this.monitorImportDataSource = this.monitorImportDataSource.map(elem => {
      delete elem.index;
      this.containerContentTypeOptions.forEach(option => {
        console.log(_.cloneDeep(option.label), _.cloneDeep(elem.containerContentType.value))
        if (option.label === elem.containerContentType.value) {
          //newRecord.rdcName.value = elem.value
          elem['containerContentType'] = {
            value: option.value,
            errorType: '',
            errorList: [],
            errorAction: ''
          }
        }
      })
      return elem
    })
    let imported = new Array();
    this.monitorImportDataSource.forEach((el) => {
      imported.push(Object.assign({}, el));
    })
    const remarks = this.monitorImportDataSource.map(el => {
      if (!!el.meta && !!el.meta.remarks)
        return el.meta.remarks;
    });
    this.importError = false;
    if (!!remarks) {
      for (const remark of remarks) {
        if (!!remark && remark.map(el => el.status).filter(e => (e == 'ERROR')).length > 0) {
          this.importError = true;
          this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Incorrect data error", "Incorrect data in the imported file. Please fix the errors.");
          this.refreshPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor, true);
          break;
        } else if (!!remark && remark.filter(e => (e.status == 'WARNING' && e.action == 'VALIDATE')).length > 0) {
          this.importError = true;
          const dialogRef = this.dialog.open(DialogComponent, {
            // width: '250px',
            data: {
              title: "Confirm save excel in monitor",
              content: `There are some record with warning. Are you sure to proceed?`,
              //content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
              cancelButtonLabel: "No",
              cancelButtonColor: "basic",
              confirmButtonLabel: "Yes",
              confirmButtonColor: "warn"
            }
          });

          dialogRef.afterClosed().subscribe((res) => {
            if (!!res) {
              //const loadingEvent = this.dialog.open(LoadingComponent);
              this.spinner.show();
              imported.forEach(i => {
                this.destinationsOptions.forEach(elem => {
                  if (elem.label === i.destinationId.value) {
                    //newRecord.rdcName.value = elem.value
                    i['destinationId'] = {
                      value: elem.value,
                      errorType: '',
                      errorList: [],
                      errorAction: ''
                    }
                  }
                })

              })

              const serviceSub = this.monitorService.saveMonitorRecord(imported).subscribe(res => {
                //console.log(res)
                this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Import successfully completed", "Records have been saved. (" + res.new + " new & " + res.updated + " updated)", 5);
                serviceSub.unsubscribe();
                //loadingEvent.close();
                this.spinner.hide();
                this.refreshPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor, true);
              }, err => {
                //loadingEvent.close();
                this.spinner.hide();
                if (err.status == 403)
                  this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10)
                else
                  this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Saving error", "An error occurred while saving the record/s.", 10)


                this.refreshPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor, true);
              })
            } else {
              this.importError = true;
              this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Incorrect data error", "Incorrect data in the imported file. Please fix the errors.", 5);
              this.refreshPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor, true);
            }
          });
          break;
        }
      }
    }

    if (!(!!this.importError)) {
      //const loadingEvent = this.dialog.open(LoadingComponent);
      this.spinner.show();
      imported.forEach(i => {
        this.destinationsOptions.forEach(elem => {
          if (elem.label === i.destinationId.value) {
            //newRecord.rdcName.value = elem.value
            i['destinationId'] = {
              value: elem.value,
              errorType: '',
              errorList: [],
              errorAction: ''
            }
          }
        })
      })

      const serviceSub = this.monitorService.saveMonitorRecord(imported).subscribe(res => {
        //console.log(res)
        this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Import successfully completed", "Records have been saved. (" + res.new + " new & " + res.updated + " updated)", 5);
        serviceSub.unsubscribe();
        //loadingEvent.close();
        this.spinner.hide();
        this.refreshPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor, true);
      }, err => {
        serviceSub.unsubscribe();
        //loadingEvent.close();
        this.spinner.hide();
        if (err.status == 403)
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10)
        else
          this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Saving error", "An error occurred while saving the record/s.", 10)


        this.refreshPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor, true);
      })
    }
  }

  onCancelClick() {
    this.alertMessageMonitor.closeAllAlerts();
    this.alertMessageMonitor.viewMessage(AlertType.INFO, "Import has been canceled.", "", 5);

    this.refreshPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor, true);
  }

  onImportClick() {
    const dialogEvent = this.dialog.open(DnDComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload'
      }
    });

    dialogEvent.afterClosed().subscribe(result => {
      if (!!result && result.send) {
        this.alertMessageMonitor.closeAllAlerts();


        this.loading = true;
        //const loadingEvent = this.dialog.open(LoadingComponent);
        this.spinner.show();
        this.monitorService.importExcel(result.files[0]).subscribe(res => {

          this.authService.getHeaderImport("oemcc_preview_view").subscribe(headers => {
            let monitorHeaders = [];
            headers["detailModels"].forEach(header => {
              //console.log(header)
              monitorHeaders.push({
                id: header.order,
                field: header.column,
                label: header.label,
                editable: false,
                editType: header.type,
                selectOptions: header.options,
                headerClass: header.headerClass,
                actions: header.actions
              });
            })
            this.headersSubject.next(monitorHeaders);
          })
          //console.log(res)
          const remarks = res.map(el => {
            if (!!el.meta && !!el.meta.remarks)
              return el.meta.remarks;
          });
          this.importError = false;
          if (!!remarks) {
            for (const remark of remarks) {
              if (!!remark && remark.map(el => el.status).filter(e => (e == 'ERROR')).length > 0) {
                this.importError = true;
                this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Incorrect data error", "Incorrect data in the imported file. Please fix the errors.");
                break;
              }
            }
          }

          this.isChanged = true;
          if (this.importError)
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Excel imported with some errors", "" + res.length + " records imported. Please fix the errors.", 5);
          else
            this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Excel imported", "" + res.length + " records imported", 2);

          this.monitorImportDataSource = res.map((element, index) => {
            this.destinationsOptions.forEach(elem => {
              if (elem.value === element.destinationId.value) {
                element.destinationId.value = elem.label
              }
            })
            this.containerContentTypeOptions.forEach(option => {
              if (option.value === element.containerContentType.value) {
                element.containerContentType.value = option.label
              }
            })
            return { ...element, index: index }
          });
          this.monitorDataSourceSubject.next(this.monitorImportDataSource);
          this.loading = false;
          //loadingEvent.close();
          this.spinner.hide();
        }, err => {
          this.loading = false;
          //loadingEvent.close();
          this.spinner.hide();
          if (!!err && !!err.error && !!err.error.errors) {
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error on row: " + err.error.errors.lineNumber, "The value in '" + err.error.errors.field + "' is '" + err.error.errors.rejected + "' but we expect '" + err.error.errors.expected + "'", 30)
          } else if (err.status == 403) {
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10)
          } else if (err.status == 406) {
            this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Error 406 Not Acceptable", "The imported file is not a valid excel. Please export an excel from the monitor page and try again.", 10)
          } else {
            this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Excel import error", "An error occurred during file import.", 10)
          }
        })
      }
    })
  }

  onActionMonitor(evt: any) {
    if (this.isChanged) {
      if (!!evt) {
        if (!!evt.action) {
          if (evt.action === 'delete' && !!evt.element) {

            const dialogRef = this.dialog.open(DialogComponent, {
              // width: '250px',
              data: {
                title: "Confirm deleting",
                content: `Are you sure you want to delete the entire row?`,
                //content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
                cancelButtonLabel: "No",
                cancelButtonColor: "basic",
                confirmButtonLabel: "Yes",
                confirmButtonColor: "warn"
              }
            });

            dialogRef.afterClosed().subscribe((res) => {
              if (!!res) {
                //const loadingEvent = this.dialog.open(LoadingComponent);
                this.spinner.show();
                this.monitorImportDataSource = this.monitorImportDataSource.filter(elem => elem.index !== evt.element.index);
                this.monitorImportDataSourceSubject.next(this.monitorImportDataSource);
                //loadingEvent.close();
                this.spinner.hide();
                /*this.monitorService.deleteRecordMonitor(evt.element.id).subscribe(res => {
                  this.dataSource = this.dataSource.filter(record => record.id !== evt.element.id);
                  //loadingEvent.close();
this.spinner.hide();
                  this.dataSourceSubject.next(this.dataSource);
                  this.alertMessagePreview.viewMessage(AlertType.SUCCESS, "Delete record", "Record successfully deleted", 5)

                }, err => {
                  this.alertMessagePreview.viewMessage(AlertType.WARNING, "Delete record", "There were some errors while deleting the record", 10)
                  //loadingEvent.close();
this.spinner.hide();
                })*/
              }
            });
          }
        }
      }
    }
  }

  onOpenRecordsClick(ids: any) {
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    this.monitorService.openRecords(ids).subscribe(res => {
      //loadingEvent.close();
      this.spinner.hide();
      this.refreshPreview(0, this.monitorDataSource.length, this.sortedColumnsMonitor, this.filterValuesMonitor);
    }, (err: any) => {
      this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Records opening error", "An error occurred while opening selected records.")
      //loadingEvent.close();
      this.spinner.hide();
    })
  }

  onCloseRecordsClick(ids: any) {
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    this.monitorService.closeRecords(ids).subscribe(res => {
      //loadingEvent.close();
      this.spinner.hide();
      this.refreshPreview(0, this.monitorDataSource.length, this.sortedColumnsMonitor, this.filterValuesMonitor);

    }, (err: any) => {
      if (err.status == 403)
        this.alertMessageMonitor.viewMessage(AlertType.WARNING, "Error 403 Forbidden", "You don't have permission for this action.", 10)
      else
        this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Records closing error", "An error occurred while closing selected records.")

      //loadingEvent.close();
      this.spinner.hide();
    })
  }

  isActionPresent(action: string) {
    if (this.permittedActions.indexOf(action) > -1)
      return true;
    else
      return false
  }


  onExportCsvClick() {
    let params = new HttpParams().set('page', this.pageEvent.pageIndex).set('size', this.pageEvent.loader);
    new Map([...this.sortedColumnsMonitor.entries()].sort((a, b) => a[1].order - b[1].order)).forEach((value, key, map) => {
      if (!(!!params.get('sort')))
        params = params.set('sort', key + (value.type == 'desc' ? ',desc' : ''))
      else
        params = params.append('sort', key + (value.type == 'desc' ? ',desc' : ''))
    })
    this.loading = true;
    const fileName = "Csv_" + Date.now() + ".csv";
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    this.filterValuesMonitor.forEach(filter => {
      if (!(!!params.get('search')))
        params = params.set('search', filter);
      else
        params = params.append('search', filter);
    });
    this.monitorService.exportMonitorCsv(params).subscribe(blob => {
      this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "CSV exported", "A CSV file for selected records has been exported.", 5);
      saveAs(blob, fileName);
      ////loadingEvent.close();
      this.spinner.hide();
      this.spinner.hide();
      this.loading = false;
    }, err => {
      ////loadingEvent.close();
      this.spinner.hide();
      this.spinner.hide();
    });
  }

  onNotifyRecordsClick(res: any) {
    if (res)
      this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Notification sent", "You have successfully sent the notification.", 5);
  }

  onDetailChange(res: any) {
    if (!!res) {
      this.monitorDataSource = this.monitorDataSource.map(row => {
        if (row.id.value === res.id.value) {
          this.destinationsOptions.forEach(elem => {
            if (elem.value === res.destinationId.value) {
              res.destinationId.value = elem.label;
            }
          })
          this.containerContentTypeOptions.forEach(option => {
            if (option.value === res.containerContentType.value) {
              res.containerContentType.value = option.label
            }
          })
          return res;
        } else {
          return row;
        }
      })
      this.monitorDataSourceSubject.next(this.monitorDataSource);
    }
  }

  getAllUsers() {
    this.loading = true;
    this.authService.getUserList()
      .subscribe(
        (res: any) => {
          if (!!res) {
            this.userOptions = res.map((user: any) => ({
              value: user.userId,
              label: `${user.userId}`
            })).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
          }

        },
        (_err: any) => {
          this.loading = false;
        },
        () => this.loading = false)

  }

  onDeleteRecordClick(event: any) {
    this.spinner.show();
    lastValueFrom(this.monitorService.deletePreviewRecords(event)).then(res => this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Records deleting success", "Selected records have been delete", 3))
      .catch(err => this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Records deleting error", "An error occurred while deleting selected records."))
      .finally(() => {
        this.spinner.hide();
        this.refreshPreview(0, this.monitorDataSource.length, this.sortedColumnsMonitor, this.filterValuesMonitor)
      })
    //this.monitorService.deleteRecords(event).subscribe(res => console.log(res));
  }

  searchForDeleting(event: boolean) {
    this.showDelete = event;
    this.refreshPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor);
  }

  searchForDuplicate(event: boolean) {
    this.showOnlyDuplicate = event;
    this.refreshPreview(0, 50, this.sortedColumnsMonitor, this.filterValuesMonitor);
  }

  onReopenRecordsClick(ids: any) {
    //const loadingEvent = this.dialog.open(LoadingComponent);
    this.spinner.show();
    lastValueFrom(this.monitorService.openPreviewRecords(ids)).then(res => this.alertMessageMonitor.viewMessage(AlertType.SUCCESS, "Records opening success", "Selected records have been open", 3))
      .catch(err => this.alertMessageMonitor.viewMessage(AlertType.DANGER, "Records opening error", "An error occurred while opening selected records."))
      .finally(() => {
        this.spinner.hide();
        this.refreshPreview(0, this.monitorDataSource.length, this.sortedColumnsMonitor, this.filterValuesMonitor);
      })
  }

}
