import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY_PROVIDER } from '@angular/cdk/overlay/overlay-directives';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @ViewChildren("filterFields") filterValues: QueryList<any>;

  @Input() filterObj = [

  ];

  @Output()
  applyFilterEv: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  @Output()
  resetFilterEv: EventEmitter<any> = new EventEmitter<any>();

  filterForm: FormGroup;

  columnName: any;
  columnComparison: string = '';

  isFilter: boolean = true;

  filterDateHashMap: Map<string, string> = new Map();

  selectedRange: Map<string, string> = new Map();
  dateRange: Map<string, boolean> = new Map();

  selectedValue: string;

  selectedValueMP: any;

  ngOnInit(): void {
  }

  applyFilter() {
    const filtersArray = this.filterValues.toArray().filter(el => (!!el._value && el._value != 'ALL'  && !el._id.includes("-dd")) || (!!el.nativeElement && !!el.nativeElement.value)).map(e => {

      if(!!e.nativeElement) {
        this.columnName = e.nativeElement.name;
        if (this.selectedRange[this.columnName] == 's') {
          this.columnComparison = ':';
          this.filterDateHashMap.set(this.columnName, this.columnComparison);
        }
        else if (this.selectedRange[this.columnName] == 'a') {
          this.columnComparison = '>';
          this.filterDateHashMap.set(this.columnName, this.columnComparison);
        }
        else if (this.selectedRange[this.columnName] == 'b') {
          this.columnComparison = '<';
          this.filterDateHashMap.set(this.columnName, this.columnComparison);
        } else if (this.selectedRange[this.columnName] == 'i') {
          if (e.nativeElement.id === 'min') {
            this.columnComparison = '>';
            this.filterDateHashMap.set(this.columnName + '-min', this.columnComparison);
          } else if (e.nativeElement.id === 'max') {
            this.columnComparison = '<';
            this.filterDateHashMap.set(this.columnName + '-max', this.columnComparison);
          }
        }

        switch (e.nativeElement.type) {
          case 'date':
            if (this.selectedRange[this.columnName] == 'i' && e.nativeElement.id === 'min') {
              if (this.dateRange.get(e.nativeElement.name + '-' + e.nativeElement.id)) {
                this.columnComparison = '>';
                return e.nativeElement.name + (this.filterDateHashMap.get(e.nativeElement.name + '-' + e.nativeElement.id)) + this.dateFormatter(e.nativeElement.value);
              }
            } else if (this.selectedRange[this.columnName] == 'i' && e.nativeElement.id === 'max') {
              if (this.dateRange.get(e.nativeElement.name + '-' + e.nativeElement.id)) {
                this.columnComparison = '<';
                return e.nativeElement.name + (this.filterDateHashMap.get(e.nativeElement.name + '-' + e.nativeElement.id)) + this.dateFormatter(e.nativeElement.value);
              }
            } else {
              return e.nativeElement.name + (this.filterDateHashMap.get(e.nativeElement.name)) + this.dateFormatter(e.nativeElement.value);
            }
            return null;
          case 'number':
            return e.nativeElement.name + "=" + e.nativeElement.value;
          default:
            if(e.nativeElement.name == 'container') {
              const containerFilter = ((e.nativeElement.value).split(/[-_/;,]+/)).map(containerValue => containerValue.trim()).join(';')
              return !!containerFilter ? e.nativeElement.name + '^('+containerFilter+')' : containerFilter
            }else
              return e.nativeElement.name + "~" + e.nativeElement.value;
          //return e.nativeElement.name + "~" + e.nativeElement.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
      } else {

        if(!!this.filterObj.find(el => (el.columnProp == e.ngControl.name && el.filterType == 'multipleSelect'))){

          if(!! e.ngControl.model && e.ngControl.model.length > 0){
            return e.ngControl.model.map(value => {

              if((this.filterObj.find(el => (el.columnProp == e.ngControl.name && el.filterType == 'multipleSelect')).value).some(arrElem => arrElem.value == value)){
                return (this.filterObj.find(el => (el.columnProp == e.ngControl.name && el.filterType == 'multipleSelect')).value).map(elemArr => elemArr.value).map(v => {

                  if(!e.ngControl.model.includes(v) && e.ngControl.model.length > 0)
                    return e.ngControl.name + '!'  + v
                  else
                    return null;
                }).filter(el => el !== null ).join('&');
              }else{
                return null;
              }
            }).filter(el => !!el && el.length > 0).join('&')
          }else{
            return null;
          }

        }else{

          return e.ngControl.name + '~'  + e._value
        }
        //return (''+e._value).split(',').map(el => e.ngControl.name + '~'  + el).join('&');
      }

    })
    this.applyFilterEv.emit(filtersArray.filter(el => !!el));
    //this.setFilter(false)
  }

  dateFormatter(stringDate: any) {
    if (!!stringDate && typeof stringDate === 'string') {
      stringDate = stringDate.substring(5, 7) + '-' + stringDate.substring(8, 10) + '-' + stringDate.substring(0, 4);
    }
    return stringDate;
  }

  dateChange(evt: any) {
    evt.target.value.length === 10 ? this.dateRange.set(evt.target.name + '-' + evt.target.id, true) : this.dateRange.set(evt.target.name + '-' + evt.target.id, false);
  }

  setFilter(filter: boolean) {
    this.isFilter = filter;
  }


  clearFilter() {

    this.filterObj = this.filterObj.map(filter => {
      filter.modelValue = ''
      return filter;
    })
    this.dateRange.forEach((value, key) => {
      this.dateRange.set(key, false)
    })

    //this.applyFilterEv.emit(filtersArray);
    //this.setFilter(false)
  }

  resetFilter() {

    this.resetFilterEv.emit(true);
  }

  /*inputTypeChange(name: string, type: string) {
    if(!!(<HTMLInputElement>document.getElementsByName(name)[0])) {
      (<HTMLInputElement>document.getElementsByName(name)[0]).type = type;
    }
  }*/
}

export interface FILTER_OBJ {
  name: String | string,
  columnProp: String | string,
  filterType?: String | string,
  value: String | string | any[] | any,
  modelValue: String | string | any[] | any
}
