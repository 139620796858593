import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImportedComponent } from '../pages/imported/imported.component';
import { ImportedContainer } from '../shared/models/ImportedContainer';

@Injectable({
  providedIn: 'root'
})
export class GTNexusService {
  constructor(private http:HttpClient) { }

  getDestinations(page: number, size: number){
    return this.http.get(environment.apiUrl+'gtnexus_destinations');
  }

  getContainers(page: number, size: number){
    return this.http.get(environment.apiUrl+'view_containers');
  }

  editContainers(containers: ImportedContainer[]){
    return this.http.put(environment.apiUrl+'view_containers', containers);
  }

}
