import { HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterDataService } from 'src/app/services/master-data.service';

@Component({
  selector: 'app-final-destination',
  templateUrl: './final-destination.component.html',
  styleUrls: ['./final-destination.component.scss']
})
export class FinalDestinationComponent {

  step = 'select';

  stepNew = 'select';

  rawDestinationsOptions: any[] = [];
  rawDestinationSelected:string;
  destinationsOptions: any[] = [];
  destinationSelected: string;

  @Input() newFinalDestination:string;

  loading = false;

  constructor(private masterDataService: MasterDataService, @Inject(MAT_DIALOG_DATA) public data: { [key: string]: any }) {
    this.rawDestinationsOptions = data['rawDestinationsOptions'];
    this.destinationsOptions = data['destinationsOptions'];
    this.newFinalDestination = data['newFinalDestination'];
  }

  ngOnAfterInit(): void {
    let params = new HttpParams().set('page', 0).set('size', 10000);
    this.masterDataService.getDestinations(params)
      .subscribe(
        (res: any) => {
          if (!!res && !!res.content) {
            this.rawDestinationsOptions = res.content.map((destination: any) => ({ value: destination.rdcName, label: `${destination.rdcName}` }));
            this.destinationsOptions = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
            //this.listOfDestination = res.content.map((destination: any) => ({ value: destination.id, label: `${destination.rdcName}` }));
          }

        },
        (_err: any) => {
          this.loading = false;
        },
        () => this.loading = false);

    this.rawDestinationSelected = this.newFinalDestination;
  }

  setStep(stepName: string){
    this.step = stepName;
  }

  close(isSaving: boolean){
    if(this.step==='select'){
      return {save: 'select', response: this.destinationSelected};
    }else{
      if(this.stepNew === 'select'){
        return {save: 'selectNew', response: {finalDestination: this.rawDestinationSelected, destination: this.destinationSelected}};
      }else{
        return false
      }
    }
  }



}
